import { api } from './api';
import { callApi } from '../helpers/index';

export const callApiGetLoggedInUserData = async data => {
  return callApi().then(token => {
    if (data && data.userId) {
      return api.service('users').get(data.userId);
    } else {
      return null;
    }
  });
};

export const callApiGetUserData = async data => {
  return callApi().then(token => {
    if (data && data.userId) {
      return api.service('users').get(data.userId);
    } else {
      return null;
    }
  });
};

export const callApiUpdateUserProfileData = async (userId, data) => {
  return callApi().then(async token => {
    return api.service('api/user').patch(userId, data);
  });
};

export const callApiUpdateStripeCustomerId = async (userId, data) => {
  return callApi().then(async token => {
    return api.service('api/user').patch(userId, data);
  });
};

export const callApiGetUserUsingBusinessId = businessId => {
  return callApi().then(token => {
    return api.service('users').find({
      query: {
        'businessDetails.businessId': businessId,
        $limit: 1,
        $sort: { createdAt: 1 }
      }
    });
  });
};

export const callApiUpdateUserWelcomePopup = async (userId, data) => {
  return callApi().then(token => {
    return api.service('users').patch(userId, data);
  });
};

export const callApiSendFeedback = async payload => {
  return callApi().then(async token => {
    return api.service('api/user').create(payload);
  });
};

export const callApiGetSentEmailsListForUser = async userId => {
  return callApi().then(async token => {
    return api.service('api/user').get('users-email-list', {
      query: {
        userId: userId
      }
    });
  });
};

export const callApiOpenSaqApp = async payload => {
  return callApi().then(async token => {
    //console.log("payload", payload);
    return api.service('api/user').create(payload);
  });
};
