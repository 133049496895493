import React from "react";

const RouteContext = React.createContext({
  goToPageVulnerabilities: () => {},
  goToPageFrontlineVulnerabilities: () => {},
  closeVulnerabilities: () => {},
  showBackDrop: false,
});

export default RouteContext;
