import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import config from "../../helpers/config";
import AppContext from "../../components/context/appContext";

import { createBusinessData } from "../../modules/actions";

class CreateAccountTab extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      userId: null,
      accountType: "basic",
      primaryDomain: "",
      businessName: "",
      supportPhone: "",
      supportEmail: "",
      billingEmail: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      zip: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      language: "",
      sendEmail: false,
      businessNameError: "",
      supportPhoneError: "",
      supportEmailError: "",
      billingEmailError: "",
      firstNameError: "",
      lastNameError: "",
      phoneError: "",
      emailError: ""
    };

    this.onChangeHandelUserInput = this.onChangeHandelUserInput.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { userData } = this.context;

    if (prevState.userId !== userData._id) {
      this.setState({
        userId: userData._id
      });
    }
  }

  onChangeHandelUserInput(evt) {
    let name = evt.target.name;
    let value = evt.target.value;
    this.setState({
      [name]: value
    });
  }

  onClickCreateAccount = async () => {
    const data = {
      userId: this.state.userId,
      key: config.createAccountKey,
      accountType: this.state.accountType,
      primaryDomain: this.state.primaryDomain,
      businessName: this.state.businessName,
      supportPhone: this.state.supportPhone,
      supportEmail: this.state.supportEmail,
      billingEmail: this.state.billingEmail,
      address: this.state.address,
      address2: this.state.address2,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      zip: this.state.zip,
      certificate: [
        {
          slug: "website-security",
          status: true
        },
        {
          slug: "no-malware",
          status: true
        },
        {
          slug: "active-ssl",
          status: true
        }
      ],
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: this.state.phone,
      email: this.state.email,
      language: this.state.language,
      sendEmail: this.state.sendEmail,
      isBackEnd: true
    };

    const res = await this.props.createBusinessData(data);

    if (res && res.success === true) {
      this.resetState();
    }
  };

  resetState() {
    this.setState({
      accountType: "basic",
      primaryDomain: "",
      businessName: "",
      supportPhone: "",
      supportEmail: "",
      billingEmail: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      zip: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      language: "",
      sendEmail: false,
      businessNameError: "",
      supportPhoneError: "",
      supportEmailError: "",
      billingEmailError: "",
      firstNameError: "",
      lastNameError: "",
      phoneError: "",
      emailError: ""
    });
  }

  render() {
    const {
      accountType,
      primaryDomain,
      businessName,
      supportPhone,
      supportEmail,
      billingEmail,
      address,
      address2,
      city,
      state,
      country,
      zip,
      firstName,
      lastName,
      phone,
      email,
      language,
      sendEmail,
      businessNameError,
      supportPhoneError,
      supportEmailError,
      billingEmailError,
      firstNameError,
      lastNameError,
      phoneError,
      emailError
    } = this.state;

    return (
      <div id="create-account" className="tab-pane fade">
        <h4>Create Account</h4>
        {/* {message ? (
          <div className="message" id="aap">
            <h4>Create Account Success</h4>
          </div>
        ) : (
          ""
        )} */}

        <div className="col-lg-12 no-pad">
          <div className="col-lg-12 col-md-12 col-sm-12 text-left no-pad">
            <div className="Rtable-cell">
              <span className="Rparent-label-mobile">ACCOUNT TYPE</span>
              <br />
              <br />
              <input
                type="radio"
                name="accountType"
                id="free"
                className="account-type-radio"
                value="free"
                checked={accountType === "free" ? true : false}
                onClick={evt => {
                  this.setState({
                    accountType: evt.target.value
                  });
                }}
                data-testid="inpCrtAccFrmFreeAccType"
              />
              <label htmlFor="free">Free</label>
              {/* <input
                type="radio"
                name="accountType"
                id="basic"
                className="account-type-radio"
                value="basic"
                checked={accountType === "basic" ? true : false}
                onClick={(evt) => {
                  this.setState({
                    accountType: evt.target.value,
                  });
                }}
                data-testid="inpCrtAccFrmBasicAccType"
              />
              <label htmlFor="basic">Ultimate Website Protection</label> */}
              <input
                type="radio"
                name="accountType"
                id="security"
                className="account-type-radio"
                value="security"
                checked={accountType === "security" ? true : false}
                onClick={evt => {
                  this.setState({
                    accountType: evt.target.value
                  });
                }}
                data-testid="inpCrtAccFrmSecAccType"
              />
              <label htmlFor="security">Total Website Protection</label>

              {/* <input
                type="radio"
                name="accountType"
                id="90-day-trial"
                className="account-type-radio"
                value="90-day-trial"
                checked={accountType === "90-day-trial" ? true : false}
                onClick={(evt) => {
                  this.setState({
                    accountType: evt.target.value,
                  });
                }}
                data-testid="inpCrtAccFrm90DayTrialAccType"
              />
              <label htmlFor="90-day-trial">90 Day Trial</label> */}

              <input
                type="radio"
                name="accountType"
                id="60-day-trial"
                className="account-type-radio"
                value="60-day-trial"
                checked={accountType === "60-day-trial" ? true : false}
                onClick={evt => {
                  this.setState({
                    accountType: evt.target.value
                  });
                }}
                data-testid="inpCrtAccFrm60DayTrialAccType"
              />
              <label htmlFor="60-day-trial">60 Day Trial</label>

              <input
                type="radio"
                name="accountType"
                id="pci-malware"
                className="account-type-radio"
                value="pci-malware"
                checked={accountType === "pci-malware" ? true : false}
                onClick={evt => {
                  this.setState({
                    accountType: evt.target.value
                  });
                }}
                data-testid="inpCrtAccFrm90DayTrialAccType"
              />
              <label htmlFor="pci-malware">Pci Malware</label>

              <input
                type="radio"
                name="accountType"
                id="monthly-scan"
                className="account-type-radio"
                value="monthly-scan"
                checked={accountType === "monthly-scan" ? true : false}
                onClick={evt => {
                  this.setState({
                    accountType: evt.target.value
                  });
                }}
                data-testid="inpCrtAccFrmMonthlyScanAccType"
              />
              <label htmlFor="monthly-scan">Monthly Scan</label>

              <input
                type="radio"
                name="accountType"
                id="vulnerability-scanning"
                className="account-type-radio"
                value="vulnerability-scanning"
                checked={accountType === "vulnerability-scanning" ? true : false}
                onClick={evt => {
                  this.setState({
                    accountType: evt.target.value
                  });
                }}
                data-testid="inpCrtAccFrmVulnerabilityScanningAccType"
              />
              <label htmlFor="vulnerability-scanning">Vulnerability Scanning</label>

              <input
                type="radio"
                name="accountType"
                id="security-with-dark-web"
                className="account-type-radio"
                value="security-with-dark-web"
                checked={accountType === "security-with-dark-web" ? true : false}
                onClick={evt => {
                  this.setState({
                    accountType: evt.target.value
                  });
                }}
                data-testid="inpCrtAccFrmSecurityWithDarkWebAccType"
              />
              <label htmlFor="security-with-dark-web">PCI/Malware Scanning With Dark Web</label>

              <br />
              <br />
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-md-12 col-sm-12 text-left  no-pad" />
        </div>
        <br />
        <h5 className="">BUSINESS INFO</h5>
        <br />
        <div className="Rtable Rtable--6cols Rtable--collapse">
          <div className="Rtable-cell p-sm">
            <span className="Rparent-label-mobile">Primary Domain URL</span>
            <br />
            <input
              type="text"
              className="form-control p-sm"
              placeholder="192.33.334.4"
              name="primaryDomain"
              value={primaryDomain}
              onChange={this.onChangeHandelUserInput}
              data-testid="inpCrtAccFrmPriDmn"
            />
          </div>
          <div className="Rtable-cell p-sm">
            <span
              className={
                businessNameError ? "Rparent-label-mobile inputError" : "Rparent-label-mobile"
              }>
              Business Name
            </span>
            <br />
            <input
              type="text"
              className="form-control"
              placeholder="Business Name"
              name="businessName"
              value={businessName}
              onChange={this.onChangeHandelUserInput.bind(this)}
              data-testid="inpCrtAccFrmBusiName"
            />
          </div>

          <div className="Rtable-cell p-sm">
            <span
              className={
                supportPhoneError ? "Rparent-label-mobile inputError" : "Rparent-label-mobile"
              }>
              Support Phone
            </span>
            <br />
            <input
              type="text"
              className="form-control"
              placeholder="Support Phone"
              value={supportPhone}
              name="supportPhone"
              onChange={this.onChangeHandelUserInput}
              data-testid="inpCrtAccFrmSupPhn"
            />
          </div>
          <div className="Rtable-cell p-sm">
            <span
              className={
                supportEmailError ? "Rparent-label-mobile inputError" : "Rparent-label-mobile"
              }>
              Support Email
            </span>
            <br />
            <input
              type="text"
              className="form-control"
              placeholder="Support Email"
              name="supportEmail"
              value={supportEmail}
              onChange={this.onChangeHandelUserInput}
              data-testid="inpCrtAccFrmSupEmail"
            />
          </div>
          <div className="Rtable-cell p-sm">
            <span
              className={
                billingEmailError ? "Rparent-label-mobile inputError" : "Rparent-label-mobile"
              }>
              Billing Email
            </span>
            <br />
            <input
              type="text"
              className="form-control"
              placeholder="Billing Email"
              name="billingEmail"
              value={billingEmail}
              onChange={this.onChangeHandelUserInput}
              data-testid="inpCrtAccFrmBilEmail"
            />
          </div>
        </div>
        <div className="Rtable Rtable--6cols Rtable--collapse">
          <div className="Rtable-cell p-sm">
            <span className="Rparent-label-mobile">Business Address</span>
            <br />
            <input
              type="text"
              className="form-control p-sm"
              placeholder="Business Address"
              name="address"
              value={address}
              onChange={this.onChangeHandelUserInput}
              data-testid="inpCrtAccFrmBusiAddr1"
            />
          </div>
          <div className="Rtable-cell p-sm">
            <span className="Rparent-label-mobile">Business Address 2</span>
            <br />
            <input
              type="text"
              className="form-control"
              placeholder="Business Address 2"
              name="address2"
              value={address2}
              onChange={this.onChangeHandelUserInput}
              data-testid="inpCrtAccFrmBusiAddr2"
            />
          </div>

          <div className="Rtable-cell p-sm">
            <span className="Rparent-label-mobile">City</span>
            <br />
            <input
              type="text"
              className="form-control"
              placeholder="City"
              name="city"
              value={city}
              onChange={this.onChangeHandelUserInput}
            />
          </div>

          <div className="Rtable-cell p-sm">
            <span className="Rparent-label-mobile">State</span>
            <br />
            <input
              type="text"
              className="form-control"
              placeholder="State"
              name="state"
              value={state}
              onChange={this.onChangeHandelUserInput}
              data-testid="inpCrtAccFrmState"
            />
          </div>
          <div className="Rtable-cell p-sm">
            <span className="Rparent-label-mobile">Country</span>
            <br />
            <input
              type="text"
              className="form-control"
              placeholder="Country"
              name="country"
              value={country}
              onChange={this.onChangeHandelUserInput}
              data-testid="inpCrtAccFrmCntry"
            />
          </div>
          <div className="Rtable-cell p-sm">
            <span className="Rparent-label-mobile">Zip</span>
            <br />
            <input
              type="text"
              className="form-control"
              placeholder="Zip"
              name="zip"
              value={zip}
              onChange={this.onChangeHandelUserInput}
              data-testid="inpCrtAccFrmZip"
            />
          </div>
        </div>
        <br />
        <h5 className="">ACCOUNT ADMIN INFO</h5>
        <br />
        <div className="Rtable Rtable--6cols Rtable--collapse">
          <div className="Rtable-cell p-sm">
            <span
              className={
                firstNameError ? "Rparent-label-mobile inputError" : "Rparent-label-mobile"
              }>
              First Name
            </span>
            <br />
            <input
              type="text"
              className="form-control p-sm"
              placeholder="First Name"
              name="firstName"
              value={firstName}
              onChange={this.onChangeHandelUserInput.bind(this)}
              data-testid="inpCrtAccFrmFrstName"
            />
          </div>
          <div className="Rtable-cell p-sm">
            <span
              className={
                lastNameError ? "Rparent-label-mobile inputError" : "Rparent-label-mobile"
              }>
              Last Name
            </span>
            <br />
            <input
              type="text"
              className="form-control"
              placeholder=" Last Name"
              name="lastName"
              value={lastName}
              onChange={this.onChangeHandelUserInput}
              data-testid="inpCrtAccFrmLastName"
            />
          </div>

          <div className="Rtable-cell p-sm">
            <span
              className={phoneError ? "Rparent-label-mobile inputError" : "Rparent-label-mobile"}>
              Phone
            </span>
            <br />
            <input
              type="text"
              className="form-control"
              placeholder="Phone Number"
              name="phone"
              value={phone}
              onChange={this.onChangeHandelUserInput.bind(this)}
              data-testid="inpCrtAccFrmPhn"
            />
          </div>
          <div className="Rtable-cell p-sm">
            <span
              className={emailError ? "Rparent-label-mobile inputError" : "Rparent-label-mobile"}>
              Email
            </span>
            <br />
            <input
              type="text"
              className="form-control"
              placeholder="example@gmail.com"
              name="email"
              value={email}
              onChange={this.onChangeHandelUserInput}
              data-testid="inpCrtAccFrmEmail"
            />
          </div>
          <div className="Rtable-cell p-sm">
            <span className="Rparent-label-mobile">Language</span>
            <br />
            <select
              className="form-control"
              name="language"
              onChange={e => this.setState({ language: e.target.value })}
              value={language}>
              <option disabled="disabled">-- Select --</option>
              <option value="english">English</option>
            </select>
          </div>
        </div>
        <br />
        <div className="Rtable Rtable--6cols Rtable--collapse">
          <div
            className="Rtable-cell p-sm"
            onClick={() => {
              document.getElementById("inpSendEmailOption").click();
            }}
            style={{ cursor: "pointer" }}>
            <input
              id="inpSendEmailOption"
              type="checkbox"
              checked={sendEmail}
              style={{ cursor: "pointer" }}
              onChange={() => {
                this.setState({
                  sendEmail: !sendEmail
                });
              }}
            />{" "}
            &nbsp;&nbsp;{" "}
            <span className="Rparent-label-mobile" style={{ marginLeft: "20px" }}>
              Send Email
            </span>
          </div>
        </div>

        <hr />

        <div className="col-lg-12 col-md-12 col-sm-12 no-pad">
          <button
            className="btn-success btn-md btn-140-width pull-right"
            onClick={() => this.onClickCreateAccount()}
            data-testid="btnCrtAccFrmSbmt">
            Create Account
          </button>
        </div>
      </div>
    );
  }
}

CreateAccountTab.contextType = AppContext;

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ createBusinessData }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAccountTab);
