import React from "react";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Slide from "../../../../components/animation/slide";
import SlideContext from "../../../../components/context/slidePanelContexts";
import AppContext from "../../../../components/context/appContext";
import TableHeader from "../../../../components/dashboard/partials/tableContent";
import Spinner from "../../../../components/ui/spinner/spinnerRect";
import PciSlidePanelInvalidDomain from "./pciSlidePanelInvalidDomain";
import StartScanButton from "../startScanButton";

class PciSlidePanel extends React.Component {
  shouldOpen = value => {
    if (value.pci.length === 1) {
      return value.pci[this.props.index].isPciActive;
    }

    if (value.pci.length > 1 && value.pci[this.props.index] !== undefined) {
      return (
        value.pci[this.props.index].isPciActive &&
        this.props.businessId === value.pci[this.props.index].businessId
      );
    }

    return false;
  };

  renderTableHeader = (appValue, value) => (
    <TableHeader
      toPCIpage={() =>
        this.props.goToPagePCIScanning(
          appValue.userBusinessData[this.props.index].scanVendor.owner,
          this.context,
          value.pci[this.props.index].scanCount,
          this.props.index
        )
      }>
      {this.props.children}
    </TableHeader>
  );

  renderStartScanButton = value => (
    <StartScanButton
      businessId={this.props.businessId}
      index={this.props.index}
      startScanDomainHandler={value.pci[this.props.index].startPCIscan}
      isScanning={value.pci[this.props.index].isPciScanning}
    />
  );

  renderSpinner = () => (
    <div id="start-scan-button">
      <Spinner />
    </div>
  );

  renderPciSlidePanelInvalidDomain = appValue => (
    <PciSlidePanelInvalidDomain
      primaryDomain={appValue.userBusinessData[this.props.index].primaryDomain}
      businessId={appValue.userBusinessData[this.props.index]._id}
    />
  );

  renderScanMessage = () => (
    <div className="row" id="start-scan-button">
      <p style={{ textAlign: "center", fontSize: "1.5em" }}>
        Please wait while we create a new scan for this site.
      </p>
    </div>
  );

  renderContent = (appValue, value) => {
    if (appValue.userBusinessData.length === 0) {
      return null;
    }

    const pciValue = value.pci[this.props.index];
    const userBusinessData = appValue.userBusinessData[this.props.index];
    // test userBusinessData

    if (pciValue && userBusinessData.scanVendor.hasOwnProperty("clone")) {
      return userBusinessData.pciStatus !== "pending"
        ? pciValue.scanCount > 0
          ? this.renderTableHeader(appValue, value)
          : this.renderSpinner()
        : this.props.isLoading
          ? this.renderSpinner()
          : this.renderStartScanButton(value);
    }

    if (pciValue) {
      return pciValue.isPciScanning
        ? this.renderScanMessage()
        : userBusinessData.pciProgressStatus === "invalid"
          ? this.renderPciSlidePanelInvalidDomain(appValue)
          : this.renderStartScanButton(value);
    }

    return null;
  };

  render() {
    return (
      <AppContext.Consumer>
        {appValue => (
          <SlideContext.drawerContext.Consumer>
            {value => (
              <Slide open={this.shouldOpen(value)}>
                <div
                  id="pciscan-details"
                  style={{
                    display: "block",
                    position: "relative",
                    background: "#ffffff"
                  }}>
                  {this.renderContent(appValue, value)}
                </div>
              </Slide>
            )}
          </SlideContext.drawerContext.Consumer>
        )}
      </AppContext.Consumer>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goToPagePCIScanning: (owner, context, historyCount, index) =>
        push({
          pathname: "/pci-scanning/" + owner,
          state: {
            business: context.userBusinessData,
            user: context.userData,
            scanCount: historyCount,
            toggledIndex: index
          }
        })
    },
    dispatch
  );

PciSlidePanel.contextType = AppContext;

export default connect(
  null,
  mapDispatchToProps
)(PciSlidePanel);
