import React, { Component } from "react";
import _ from "lodash";
import ContentWrapper from "../ui/contentWrapper";
import AppContext from "../context/appContext";
class Vulnerabilities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterEnabled: false,
      vulnerabilities: [],
      ports: [],
      type: [],
      hosts: [],
      severity: [],
      selectedReportValue: "all",
      selectedOptionValue: "443/tcp",
      showSelectOptions: []
    };
  }

  goToViewVulnerabilityDetail = vulnerability => {
    const { state } = this.props.history.location;
    this.props.history.push({
      pathname: "detailed",
      state: {
        detail: vulnerability,
        businessDetails: state.businessDetails
      }
    });
  };

  async componentDidMount() {
    const { state } = this.props.history.location;
    const VulnerabilityData = state.data.data;
    const vulnerabilities = [...this.state.vulnerabilities];
    const ports = [];
    const type = [];
    const host = [];
    const severity = [];

    if (VulnerabilityData !== undefined && VulnerabilityData.length > 0) {
      for (let x = 0; x < VulnerabilityData.length; x++) {
        const vulnerability = VulnerabilityData[x];
        const vulData = {
          id: vulnerability.vulnerabilityId,
          name: vulnerability.name,
          host: vulnerability.host,
          dns: vulnerability.dns,
          nvt: vulnerability.nvt,
          port: vulnerability.port,
          severity: vulnerability.severity,
          type: vulnerability.type,
          risk: vulnerability.risk,
          description: vulnerability.description,
          detail: vulnerability.detail
        };

        if (!_.includes(ports, vulnerability.port)) {
          ports.push(vulnerability.port);
        }

        if (!_.includes(type, vulnerability.type)) {
          type.push(vulnerability.type);
        }

        if (!_.includes(host, vulnerability.host)) {
          host.push(vulnerability.host);
        }

        if (!_.includes(severity, vulnerability.severity)) {
          severity.push(vulnerability.severity);
        }
        vulnerabilities.push(vulData);
      }
    }

    this.setState({
      vulnerabilities,
      ports: ports,
      type: type,
      hosts: host,
      severity: severity,
      showSelectOptions: type
    });
  }

  vulnerabilitiesContent = () => {
    let vulnerabilities = [];

    if (this.state.filterEnabled) {
      let filterBy = { type: this.state.selectedOptionValue };

      switch (this.state.selectedReportValue) {
        case "port":
          filterBy = { port: this.state.selectedOptionValue };
          break;
        case "host":
          filterBy = { host: this.state.selectedOptionValue };
          break;
        case "severity":
          filterBy = { severity: this.state.selectedOptionValue };
          break;
        default:
          filterBy = { type: this.state.selectedOptionValue };
          break;
      }

      if (this.state.selectedOptionValue === "all") {
        vulnerabilities = this.state.vulnerabilities;
      } else {
        vulnerabilities = _.filter(this.state.vulnerabilities, filterBy);
      }
    } else {
      vulnerabilities = this.state.vulnerabilities;
    }

    if (vulnerabilities.length > 0) {
      return vulnerabilities.map((vulnerability, i) => {
        return (
          <tr key={i}>
            <td>{vulnerability.name}</td>
            <td>{vulnerability.dns}</td>
            <td>{vulnerability.host}</td>
            <td>{vulnerability.severity}</td>
            <td>{vulnerability.port}</td>
            <td>{vulnerability.type}</td>
            <td>{vulnerability.description}</td>
            <td>
              <button
                style={{ cursor: "pointer" }}
                className="btn btn-danger btn-xs"
                onClick={() => this.goToViewVulnerabilityDetail(vulnerability)}>
                More info
              </button>
            </td>
          </tr>
        );
      });
    }
  };

  reportTypeHandler = e => {
    if (e.target.value === "port") {
      this.setState({
        showSelectOptions: this.state.ports,
        selectedOptionValue: this.state.ports.length > 0 ? this.state.ports[0] : ""
      });
    } else if (e.target.value === "host") {
      this.setState({
        showSelectOptions: this.state.hosts,
        selectedOptionValue: this.state.hosts.length > 0 ? this.state.hosts[0] : ""
      });
    } else if (e.target.value === "severity") {
      this.setState({
        showSelectOptions: this.state.severity,
        selectedOptionValue: this.state.severity.length > 0 ? this.state.severity[0] : ""
      });
    } else if (e.target.value === "all") {
      this.setState({
        showSelectOptions: this.state.severity,
        selectedOptionValue: "all"
      });
    } else {
      this.setState({
        showSelectOptions: this.state.type,
        selectedOptionValue: this.state.type.length > 0 ? this.state.type[0] : ""
      });
    }

    this.setState({ selectedReportValue: e.target.value });
  };

  getSelectedTypeHandler = e => {
    this.setState({ filterEnabled: true, selectedOptionValue: e.target.value });
  };

  render() {
    window.scrollTo(0, 0);
    const { state } = this.props.history.location;
    const { selectedReportValue, selectedOptionValue, showSelectOptions } = this.state;

    return (
      <>
        <ContentWrapper header="Vulnerability Details" title={state.domain}>
          <div className="row">
            <div className="col-md-9">
              <p>Scan Date: {state.scanDate}</p>
            </div>
            <div className="col-md-3" style={{ float: "right", paddingRight: 0 }}>
              <div className="row">
                <div className="col-md-6" style={{ paddingRight: 0 }}>
                  <div className="form-group">
                    <select
                      onChange={e => this.reportTypeHandler(e)}
                      value={selectedReportValue}
                      className="form-control">
                      <option value="all">All</option>
                      <option value="port">Port / Protocol</option>
                      <option value="type">Vulnerability Type</option>
                      <option value="host">Host</option>
                      <option value="severity">Severity</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6" style={{ paddingRight: 0 }}>
                  <div className="form-group">
                    <select
                      onChange={e => this.getSelectedTypeHandler(e)}
                      value={selectedOptionValue}
                      className="form-control"
                      disabled={selectedReportValue === "all" ? true : false}>
                      <option value="all">All</option>
                      {showSelectOptions.map((option, i) => {
                        return (
                          <option key={option + i} value={option}>
                            {option}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />
          <div className="table-responsive" style={{ overflowX: "auto" }}>
            <table className="table table-striped">
              <thead className="thead-dark">
                <th style={{ padding: "20px" }}>Vulnerabilitiy Name</th>
                <th style={{ padding: "20px" }}>DNS</th>
                <th style={{ padding: "20px" }}>Host</th>
                <th style={{ padding: "20px" }}>Serverity</th>
                <th style={{ padding: "20px" }}>Port</th>
                <th style={{ padding: "20px" }}>Type of Vulnerabilities</th>
                <th style={{ padding: "20px", width: "333px" }}>Description</th>
                <th />
              </thead>
              <tbody>{this.vulnerabilitiesContent()}</tbody>
            </table>
          </div>
        </ContentWrapper>
      </>
    );
  }
}

Vulnerabilities.contextType = AppContext;
export default Vulnerabilities;
