import React from "react";
import ControlButtons from "../controlButtons";
import moment from "moment";
import _ from "lodash";

function FalsePositiveScans({
  index,
  isLoadingFalsePositiveScans,
  falsePositiveScanActive,
  falsePositiveScans,
  getFalsePositiveScans,
  isFalsePositiveNoRecord,
  selectedIndex,
  updateFalsePositiveStatus,
}) {
  return (
    <>
      <div className="reportButton">
        <button
          disabled={isLoadingFalsePositiveScans}
          className="btn-success btn-sm"
          onClick={() => getFalsePositiveScans()}
        >
          {isLoadingFalsePositiveScans
            ? isFalsePositiveNoRecord
              ? "No Record Found"
              : "Loading..."
            : "FALSE POSITIVES"}
        </button>
        <div className="clearfix" />
      </div>
      <p>Pending False Positives</p>
      <hr />
      {falsePositiveScanActive &&
      !isLoadingFalsePositiveScans &&
      falsePositiveScans.length > 0 ? (
        <>
          <table className="tg table table-bordered table-hover">
            <thead>
              <tr>
                <th>
                  Device (ip/url) {""}
                  Message
                </th>
                <th>Status</th>
                <th>Date Submitted</th>
                <th>Action</th>
                <th>Email Client</th>
              </tr>
            </thead>
            <tbody>
              {falsePositiveScans.map((scan, index) => (
                <tr key={index}>
                  <td>{scan.message}</td>
                  <td style={{ verticalAlign: "top" }}>{scan.status}</td>
                  <td style={{ verticalAlign: "top" }}>
                    {moment(scan.date).format("MM-DD-YYYY HH:MM")}
                  </td>
                  <td style={{ verticalAlign: "top" }}>
                    <select
                      onChange={(event) =>
                        updateFalsePositiveStatus(
                          selectedIndex,
                          index,
                          event,
                          scan._id
                        )
                      }
                    >
                      <option
                        value="pending"
                        selected={scan.status === "pending"}
                      >
                        Pending
                      </option>
                      <option
                        value="verified"
                        selected={scan.status === "verified"}
                      >
                        {scan.status === "verified" ? "Verified" : "Verify"}
                      </option>
                      <option
                        value="rejected"
                        selected={scan.status === "rejected"}
                      >
                        {scan.status === "rejected" ? "Rejected" : "Reject"}
                      </option>
                    </select>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <button
                      style={{
                        float: "left",
                        marginRight: "10px",
                      }}
                      className="btn-success"
                      data-toggle="modal"
                      data-target="#falsePositivesPci"
                      onClick={() =>
                        console.log("setSelectedBusinessId(scan._id)")
                      }
                    >
                      <i className="fa fa-envelope" />
                    </button>
                    <div style={{ float: "left" }}>
                      <ControlButtons businessId={scan.businessId} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}
    </>
  );
}

export default FalsePositiveScans;
