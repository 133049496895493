import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  ElementsConsumer
} from '@stripe/react-stripe-js';

class Billing extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: {}, errorList: {} };
  }

  renderCardNumber(name, label, className, type = 'text') {
    const { data, stateErrors } = this.state;
    let error = stateErrors ? stateErrors[name] || '' : '';

    return (
      <>
        <div className={className}>
          <label htmlFor="cardNumber">Card Number</label>
          <CardNumberElement
            id="cardNumber"
            onBlur={this.logEvent('blur')}
            onChange={this.handlePayment_InputChange(name, label)}
            onFocus={this.logEvent('focus')}
            onReady={this.logEvent('ready')}
            options={this.StripeElementsStyles}
          />
        </div>
        {error && <p className={''}>{error}</p>}
      </>
    );
  }

  handlePayment_InputChange = (name, label) => elementData => {
    //store values

    let stateErrors = this.state.stateErrors || {};

    if (!elementData.complete && !elementData.error) {
      stateErrors[name] = `Your ${label} is incomplete or invalid.`;
    } else if (elementData.complete && !elementData.error) {
      delete stateErrors[name];
    }

    this.setState({
      ...this.state,
      stateErrors
    });
  };
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Billing);
