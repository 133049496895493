import { api } from './api';
import { callApi } from '../helpers/index';

function callGetMalwares(_id) {
  return callApi().then(token => {
    return api.service('api/malware-history').get(_id);
  });
}

function callGetMalwareHistory(id) {
  const params = {
    query: {
      purpose: 'get_malware_history',
      id: id
    }
  };
  return callApi().then(token => {
    return api.service('api/malware-history').find(params);
  });
}

function callMalwareSearchByBusinessId(businessId) {
  const queryBusinessId = {
    query: {
      purpose: 'get_malware_histories',
      businessId: businessId,
      limit: 10
    }
  };
  return callApi().then(token => {
    return api.service('api/malware-history').find(queryBusinessId);
  });
}

function callMalwareUpdateStatus(businessId, status) {
  const queryBusinessId = {
    query: {
      purpose: 'update_latest_malware_history',
      businessId: businessId,
      status: status
    }
  };
  return callApi().then(token => {
    return api.service('api/malware-history').find(queryBusinessId);
  });
}

function callMalwareSearchByBusinessIdAndNotundefAndRunning(businessId) {
  const queryBusinessId = {
    query: {
      businessId: businessId,
      $nor: [{ scanStatus: 'running' }],
      $sort: { scanStart: -1 }
    }
  };
  return callApi().then(token => {
    return api.service('malware-history').find(queryBusinessId);
  });
}

function callMalwareSearchScansWithin24Hours() {
  const query = {
    query: {
      scanStart: {
        $lt: new Date(),
        $gte: new Date(new Date().setDate(new Date().getDate() - 1))
      }
    }
  };
  return callApi().then(token => {
    return api.service('malware-history').find(query);
  });
}

function callMalwareSearchByBusinessIdAndLatestDate(businessId) {
  const queryBusinessId = {
    query: {
      businessId: businessId,
      $limit: 1,
      $sort: { scanStart: -1 }
    }
  };
  return api.service('malware-history').find(queryBusinessId);
}

function callCanceledBusinesses() {
  const today = new Date();

  today.setDate(today.getDate() - 30);

  const queryBusiness = {
    query: {
      status: 'inactive',
      lastMalwareScan: { $gte: today }
    }
  };
  return callApi().then(token => {
    return api.service('business').find(queryBusiness);
  });
}

function callFreeScanBusinesses() {
  const today = new Date();

  today.setDate(today.getDate() - 30);

  const queryBusiness = {
    query: {
      accountType: 'free',
      lastMalwareScan: { $gte: today }
    }
  };
  return callApi().then(token => {
    return api.service('business').find(queryBusiness);
  });
}

function callFailedScanBusinesses() {
  const today = new Date();
  today.setDate(today.getDate() - 30);

  const queryBusiness = {
    query: {
      purpose: 'malware_report_failed_scans'
    }
  };
  return callApi().then(token => {
    return api.service('api/reporting').find(queryBusiness);
  });
}

function callMalwareVulnerabilities() {
  return callApi().then(async token => {
    const query = {
      query: {
        getVulnerabilities: true
      }
    };
    const vulModel = await api.service('vulnerabilities').get(1, query);
    return vulModel;
  });
}

function callRunningMalwareSites() {
  const today = new Date();
  today.setDate(today.getDate() - 30);

  const queryBusiness = {
    query: {
      purpose: 'malware_report_running_report'
    }
  };
  return callApi().then(token => {
    return api.service('api/reporting').find(queryBusiness);
  });
}

function callCanceledRunningMalwareSites() {
  const today = new Date();
  today.setDate(today.getDate() - 30);

  const queryBusiness = {
    query: {
      purpose: 'malware_report_running_canceled_report'
    }
  };
  return callApi().then(token => {
    return api.service('api/reporting').find(queryBusiness);
  });
}

function callRunningStuckSites() {
  const today = new Date();
  today.setDate(today.getDate() - 30);

  const queryBusiness = {
    query: {
      action: 'malware_stuck_domains'
    }
  };
  return callApi().then(token => {
    return api.service('general').find(queryBusiness);
  });
}

function callGetCheckScanReportByBusinessId(businessId) {
  const queryBusinessId = {
    query: {
      businessId: businessId
    }
  };

  return callApi().then(token => {
    return api.service('check-scan-report').find(queryBusinessId);
  });
}

function updateCheckDateInCheckScanReport(_id, time, customDate) {
  const today = new Date();

  let finalDate = '';

  switch (time) {
    case '1Week':
      finalDate = today.setDate(today.getDate() + 7);
      break;
    case '2Week':
      finalDate = today.setDate(today.getDate() + 14);
      break;
    case '3Week':
      finalDate = today.setDate(today.getDate() + 21);
      break;
    case '1Month':
      finalDate = today.setDate(today.getDate() + 30);
      break;
    case 'custom':
      finalDate = customDate;
      break;

    default:
      break;
  }

  let patchCheckDate = {
    checkDate: finalDate
  };

  return callApi().then(token => {
    return api.service('check-scan-report').patch(_id, patchCheckDate);
  });
}

function callActiveBusinesses() {
  const today = new Date();

  const queryBusiness = {
    query: {
      status: 'active',
      malwareStatusReport: 'finished',
      lastMalwareScan: { $lte: today }
    }
  };
  return callApi().then(token => {
    return api.service('business').find(queryBusiness);
  });
}

function callMissingMalwareScans() {
  const queryBusiness = {
    query: {
      purpose: 'malware_report_missing_report'
    }
  };
  return callApi().then(token => {
    return api.service('api/reporting').find(queryBusiness);
  });
}

function callBusinessInfo(_id) {
  return callApi().then(token => {
    return api.service('business').get(_id);
  });
}

function callOnMalwareScan(sendToMalware) {
  return callApi().then(token => {
    return api
      .service('general')
      .create(sendToMalware)
      .then(response => {
        return response.json();
      })
      .then(data => {
        return data;
      })
      .catch(err => {
        return err;
      });
  });
}

function callOnMalwareCreateEmailHistory(sendToMalware) {
  return callApi().then(token => {
    return api
      .service('general')
      .create(sendToMalware)
      .then(response => {
        return response.json();
      })
      .then(data => {
        return data;
      })
      .catch(err => {
        return err;
      });
  });
}
function callMalwarePatchBusiness(_id, data) {
  return callApi().then(token => {
    return api.service('business').patch(_id, data);
  });
}

function callUpdateUser(_id, data) {
  return callApi().then(token => {
    return api.service('users').patch(_id, data);
  });
}

function callFindUser(_id) {
  return callApi().then(token => {
    return api.service('users').get(_id);
  });
}

function callMalwarePatchDomainStatus(_id, data) {
  return callApi().then(token => {
    return api.service('malware-history').patch(_id, data);
  });
}

function callGetDomains() {
  return callApi().then(token => {
    return api.service('domains').get();
  });
}

function callGetS3Data(url) {
  const params = {
    query: {
      bucket: 'tg3malware',
      filename: url
    }
  };
  return api.service('awsrequest').get(null, params);
}

export {
  callGetMalwareHistory,
  callMalwareVulnerabilities,
  callGetMalwares,
  callMalwareSearchByBusinessId,
  callBusinessInfo,
  callOnMalwareScan,
  callGetS3Data,
  callGetDomains,
  callMalwarePatchBusiness,
  callMalwareUpdateStatus,
  callMalwarePatchDomainStatus,
  callMalwareSearchByBusinessIdAndLatestDate,
  callOnMalwareCreateEmailHistory,
  callMalwareSearchByBusinessIdAndNotundefAndRunning,
  callCanceledBusinesses,
  callFailedScanBusinesses,
  callRunningMalwareSites,
  callRunningStuckSites,
  callMalwareSearchScansWithin24Hours,
  callMissingMalwareScans,
  callActiveBusinesses,
  callUpdateUser,
  callFindUser,
  callFreeScanBusinesses,
  updateCheckDateInCheckScanReport,
  callGetCheckScanReportByBusinessId,
  callCanceledRunningMalwareSites
};
