import React from "react";
import { Carousel } from "react-bootstrap";
import { images } from "../../../../helpers/images";

export default function ModalFreeScanRestriction(props) {
  //   const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="modal fade"
      id="restrictedFreeAccount"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ marginTop: "-150px" }}
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered welcomePopup"
        role="document"
      >
        <div className="modal-content walkthrough-content">
          <Carousel
            className="welcomePopup"
            slide={false}
            indicators={false}
            direction={null}
            interval={null}
            wrap={false}
          >
            <Carousel.Item>
              <div style={{ textAlign: "center" }}>
                <img
                  className="d-block img-top"
                  src={images.tgWalkthrough0}
                  alt="First slide"
                />

                <h2 className="mb-5">
                  <span
                    style={{
                      display: "block",
                      clear: "both",
                      marginBottom: "10px",
                    }}
                  >
                    Apologies, but the ability to download PCI reports is not
                    included in this package. While we'd love to offer you this
                    feature, please note that the free account does not have the
                    capability to generate and download PCI reports.
                  </span>
                  <span
                    style={{
                      display: "block",
                      clear: "both",
                      marginBottom: "10px",
                    }}
                  >
                    We would love to provide you with PCI report download.
                  </span>
                </h2>
              </div>
              <div className="walkbottom-container d-block w-100 img-bottom">
                <h2 className="mb-5">
                  Call <b>1-877-848-2731</b> to learn more.
                </h2>

                {/* <h2 className="mb-5">1-877-848-2731</h2> */}
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>
  );
}
