import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  callOnGetPciScanData,
  callOnGenerateCloneReport
} from '../../../../services/scanHistoryService';

import ScanStatusButton from '../scanStatusButton';
import ModalDownloadOption from '../modals/modalDownloadOption';
import ModalRescan from '../modals/modalRescan';
import { Modal } from 'react-bootstrap';
import AppContext from '../../../../components/context/appContext';
import RouteContext from '../../../../components/context/routeContext';
import Spinner from '../../../../components/ui/spinner/spinnerRectScan';
import fileDownload from 'js-file-download';
import {
  callOnGetBusinessById,
  callDownloadPCIreport,
  callOnHostsResultsByScanId
} from '../../../../services/index';
import momentz from 'moment-timezone';
import { callDownloadClonereport, callApiReScan } from '../../../../services/cloneService';
import ModalFreeScanRestriction from '../modals/modalFreeScanRestriction';
import ToastMessage from '../../../toast/toastMessage';

class DarkWebSlidePanelData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scanData: [],
      scanNumber: [],
      reportType: 'attestation',
      reportFormat: 'pdf',
      isDownloading: false,
      isReportDownloading: false,
      isProcessing: false,
      isGenerated: false,
      currentButtonIndexClicked: null,
      scanning: false,
      rescansubmitted: false,
      showPCIscanToast: false,
      showModalRescan: false,
      scanTimeDetails: {
        scanTime: null,
        scanTimeZone: null
      },
      hostScanResults: [],
      activePage: 1
    };
  }

  async componentWillReceiveProps() {
    const noLimit = this.props.noLimit;
    const scanHistory = await callOnGetPciScanData(this.props.businessId, 'darkweb', noLimit);

    this.getCurrentScanHistory(this.props.activePage, scanHistory);
  }

  getCurrentScanHistory = (pageNumber = 1, scanHistory) => {
    if (this.props.activePage) {
      const pageResults = scanHistory.data;
      console.log('pageResults', pageResults);
      const pageLimit = 10;
      const currentPage = pageNumber;

      const offset = (currentPage - 1) * pageLimit;
      let scanHistories = null;

      if (pageResults.length > 10) {
        scanHistories = pageResults.slice(offset, offset + pageLimit);
      } else {
        scanHistories = pageResults;
      }

      this.setState({
        activePage: this.props.activePage,
        scanData: scanHistories
      });
    }
  };

  async getScanHistory() {
    const noLimit = this.props.noLimit;
    const scanHistory = await callOnGetPciScanData(this.props.businessId, 'darkweb', noLimit);

    if (this.props.activePage) {
      this.getCurrentScanHistory(this.props.activePage, scanHistory);
    } else {
      this.setState({
        scanData: scanHistory.data
      });
    }
  }

  componentDidMount() {
    this.getScanHistory();
  }

  downloadClonereport = async (index, report, type = null) => {
    try {
      this.setState({
        isDownloading: true,
        currentButtonIndexClicked: index
      });

      const toggledBusinessIndex = this.props.index;
      const businessData = this.context.userBusinessData[toggledBusinessIndex];

      const username = businessData.scanVendor.clone.username;
      const accessToken = businessData.scanVendor.clone.accessToken;
      const reportId = report.last_report.id;
      const reportData = {
        businessData: businessData.businessName,
        username: username,
        accessToken: accessToken,
        reportId: reportId,
        type: type === null ? 'attestation' : type,
        vendor: 'clone'
      };

      const ReportResponse = await callDownloadClonereport(reportData);
      const data = ReportResponse.data; // Replace with your actual PDF data
      const dateFormat = momentz.tz(Date.now(), 'America/Denver').format('YYYY_MM_DD');

      this.setState({ isGenerated: true });

      const blob = new Blob([data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download =
        businessData.primaryDomain + '_clone_report_' + type + '_' + dateFormat + '.pdf'; // Replace with your desired file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      this.setState({ isDownloading: false });
    } catch (e) {
      console.log(e);
    }
  };

  cloneDownloadButton = (scan, type) => {
    let downloadButton = '';
    let reportTypeToDownload = '';

    if (scan.reportDetails !== null) {
      if (scan.hasOwnProperty('reportDetails')) {
        if (scan.reportDetails.hasOwnProperty('reportType')) {
          reportTypeToDownload = scan.reportDetails.reportType;

          if (reportTypeToDownload !== null) {
            downloadButton = (
              <button
                className="btn btn-success"
                onClick={() =>
                  this.getDownloadReport(
                    null,
                    {
                      scanId: scan._id,
                      scanhistory: scan,
                      cloneScanId: scan.scanId,
                      cloneScanReportId: scan.hasOwnProperty('reportDetails')
                        ? scan.reportDetails.last_report.id
                        : null,
                      pciScanId: scan.scanIdStamped,
                      vendor: scan.vendor,
                      downloadbtn: true,
                      reportType: scan.reportDetails.reportType
                    },
                    true
                  )
                }>
                <i className="fa fa-download" /> Download{' '}
                <span style={{ textTransform: 'capitalize' }}>{reportTypeToDownload}</span> Report
              </button>
            );
          }
          if (scan.reportDetails.hasOwnProperty('detailedReports')) {
            if (scan.reportDetails.detailedReports.status === 'processing') {
              downloadButton = (
                <button className="btn btn-success disabled">Processing Detailed Report</button>
              );
            }
          }

          if (scan.reportDetails.hasOwnProperty('attestationReports')) {
            if (scan.reportDetails.attestationReports.status === 'processing') {
              downloadButton = (
                <button className="btn btn-success disabled">Processing Attestation Report</button>
              );
            }
          }
        }
      }
    }

    return downloadButton;
  };

  getDownloadReport = async (index, data, download = false) => {
    const toggledBusinessIndex = this.props.index;
    const businessData = this.context.userBusinessData[toggledBusinessIndex];
    const toggledBusinessId = businessData._id;

    this.setState({ isDownloading: true });
    if (download) {
      this.setState({ isReportDownloading: true });
    }

    if (data.vendor === 'clone') {
      const payload = {
        businessData: businessData,
        scanId: data.scanId,
        username: businessData.scanVendor.clone.username,
        accessToken: businessData.scanVendor.clone.accessToken,
        reportId: data.cloneScanReportId,
        type: data.reportType !== undefined ? data.reportType : this.state.reportType,
        vendor: 'clone'
      };

      const ReportResponse = await callDownloadClonereport(payload);
      const responseData = ReportResponse.data; // Replace with your actual PDF data
      this.setState({ isGenerated: true });
      const dateFormat = momentz.tz(Date.now(), 'America/Denver').format('YYYY_MM_DD');

      if (responseData !== null) {
        if (responseData.hasOwnProperty('status')) {
          this.setState({ isProcessing: true });

          //write timeout 5 seconds.
          await setTimeout(() => {
            this.setState({ isProcessing: false });
          }, 5000);
        } else {
          const blob = new Blob([responseData], {
            type: 'application/pdf'
          });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download =
            businessData.primaryDomain +
            '_clone_report_' +
            payload.type +
            '_' +
            dateFormat +
            '.pdf'; // Replace with your desired file name
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          this.setState({ isDownloading: false });

          // await callOnGenerateCloneReport(payload);
          // reload browser window.

          // window.location.reload();
        }
      } else {
        this.setState({ isProcessing: true });

        //write timeout 5 seconds.
        await setTimeout(() => {
          this.setState({ isProcessing: false });
        }, 5000);
      }
    } else {
      const userBusiness = await callOnGetBusinessById(toggledBusinessId);
      const scanVendor = userBusiness.scanVendor;
      const networkID = scanVendor.owner;
      const api = scanVendor.apiKey;
      const businessName = businessData.primaryDomain;

      const ReportResponse = await callDownloadPCIreport({
        networkID: networkID,
        api: api,
        businessName: businessName,
        format: this.state.reportFormat,
        type: this.state.reportType,
        scanNumber: this.state.scanNumber,
        scanDate: this.state.scanDate,
        vendor: 'beyond'
      });

      await fileDownload(ReportResponse.data, ReportResponse.filename);
    }

    setTimeout(() => {
      this.setState({ isDownloading: false, isReportDownloading: false });
    }, 5000);

    // window.location.reload();
    window.location.href = '/';
  };

  generateReport = async index => {
    this.setState({ currentButtonIndexClicked: index });
  };

  getHostsScanResult = async (owner, scanNumber) => {
    this.setState({ hostScanResults: [] });
    const results = await callOnHostsResultsByScanId(owner, scanNumber);
    if (results.data.data.length > 0) {
      this.setState({ hostScanResults: results.data.data });
    }
  };

  reportTypeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  selectedReport = (index, scanNumber, scanDate) => {
    this.setState({ scanDate: scanDate, scanNumber: scanNumber });
  };

  startPCIscan = async (cloneScanId = null, scanHistoryId = null) => {
    const business = this.context.userBusinessData[this.props.index];
    const submitData = {
      _id: scanHistoryId,
      businessId: business._id,
      scanFrequency: business.scanFrequency,
      username: business.scanVendor.clone.username,
      accessToken: business.scanVendor.clone.accessToken,
      owner: business.scanVendor.clone.username,
      purpose: 'rescan',
      scanId: cloneScanId
    };
    const beyondsecurity = await callApiReScan(submitData);
    if (beyondsecurity.success) {
      window.location.reload();
    }
    this.setState({ showModalRescan: false });
  };

  showModal = () => {
    this.context.showModalRescan();
  };

  closeModalRescan = () => {
    this.context.closeModalRescan();
  };

  scanHistoryContent() {
    const { scanData, reportFormat, reportType, hostScanResults } = this.state;

    const { index } = this.props;
    const business = this.context.userBusinessData[index];
    let denialOfServiceCountStyle = {
      display: 'block',
      width: '34px',
      background: 'rgba(255, 255, 255, 0.1)',
      color: 'black',
      border: '1px solid #d8d4d4'
    };
    let highCountStyle = {
      display: 'block',
      width: '34px',
      background: 'rgba(255, 255, 255, 0.1)',
      color: 'black',
      border: '1px solid #d8d4d4'
    };

    let mediumCountStyle = {
      display: 'block',
      width: '34px',
      background: 'rgba(255, 255, 255, 0.1)',
      color: 'black',
      border: '1px solid #d8d4d4'
    };

    let lowCountStyle = {
      display: 'block',
      width: '34px',
      background: 'rgba(255, 255, 255, 0.1)',
      color: 'black',
      border: '1px solid #d8d4d4'
    };

    return scanData.map((scan, i) => {
      const vendor = scan.vendor;
      const high = +scan.high;
      const medium = +scan.medium;
      const low = +scan.low;
      const denialOfService = +scan.denialOfService;
      const status = scan.status;
      const scanNumber = scan.scanNumber;

      let scanDate = null;
      console.log('scan', scanDate);
      if (scan.scanDate !== null) {
        if (scan.hasOwnProperty('reportDetails')) {
          scanDate = momentz(scan.scanDate !== null ? scan.scanDate : scan.createdAt)
            .tz(
              business.pciScanTimeZone !== undefined &&
              business.pciScanTimeZone !== null &&
              business.pciScanTimeZone !== ''
                ? business.pciScanTimeZone
                : 'America/Chicago'
            )
            .format('MM/DD/YYYY hh:mm a');
        } else {
          scanDate = momentz(scan.scanDate !== null ? scan.scanDate : scan.createdAt)
            .tz(
              business.pciScanTimeZone !== undefined &&
              business.pciScanTimeZone !== null &&
              business.pciScanTimeZone !== ''
                ? business.pciScanTimeZone
                : 'America/Chicago'
            )
            .format('MM/DD/YYYY hh:mm a');
        }
      } else {
        scanDate = 'N/A';
      }

      const owner = scan.owner;
      const pciScanId = scan.scanIdStamped;

      const cloneScanId = scan.hasOwnProperty('scanId') ? scan.scanId : null;
      const scanId = scan._id;

      let reportRequestButtonDisabled = false;

      const frequency = scan.flag === 'manual' ? 'Rescanned' : scan.scanFrequency;
      let rescanButton = null;
      let statusButton = null;

      const riskFactors = [high > 0 ? 8 : 0, medium > 0 ? 4 : 0, low > 0 ? 1 : 0];

      let reportGeneratedButton = '';

      reportGeneratedButton = this.cloneDownloadButton(scan);

      const modalShowDownloadReportOpt = (
        <ModalDownloadOption
          reports={[]}
          targetID={'dash-download-modal-' + i}
          defaultValueFormat={reportFormat}
          defaultValueType={reportType}
          reportTypeHandler={this.reportTypeHandler}
          hasDarkWeb={
            business.hasOwnProperty('hasDarkWebEnabled') ? business.hasDarkWebEnabled : false
          }
          downloadReport={() =>
            this.getDownloadReport(i, {
              scanId: scan._id,
              scanhistory: scan,
              cloneScanId: scan.scanId,
              cloneScanReportId: scan.hasOwnProperty('reportDetails')
                ? scan.reportDetails.last_report.id
                : null,
              pciScanId: pciScanId,
              vendor: vendor
            })
          }
          vendor={vendor}
          scanNumber={scanNumber}
          showRemediation={status === 'fail'}
          hostScanResults={hostScanResults}
          scanGeneratedDate={
            scan.hasOwnProperty('scanReportGenerated') ? scan.scanReportGenerated : null
          }
        />
      );

      statusButton = (
        <ScanStatusButton
          status={scan.status}
          selectedReport={() => this.selectedReport(index, scanNumber, scanDate)}
          index={i}
        />
      );

      if (status !== 'pending' && status !== 'running' && status !== 'finalizing') {
        if (business.accountType === 'free') {
          if (i === 1) {
            rescanButton = (
              <a
                rel="noopener noreferrer"
                href="https://trustguard.com/home"
                target="_blank"
                className="btn btn-success btn-sm">
                <i className="fas fa-search" />&nbsp; Rescan
              </a>
            );
          }
        } else {
          if (i === 0) {
            rescanButton = (
              <>
                <RouteContext.Consumer>
                  {value => {
                    return (
                      <ModalRescan
                        index={i + 1}
                        showModal={this.showModal}
                        closeModalRescan={this.closeModalRescan}
                        toggledIndex={index}
                        scanData={{ _id: business._id }}
                        rescan={this.startPCIscan}
                        goToPageVulnerabilities={() =>
                          vendor === 'frontline'
                            ? value.goToPageFrontlineVulnerabilities(
                                scan._id,
                                scan.scanIdStamped,
                                business
                              )
                            : value.goToPageVulnerabilities(
                                scan._id,
                                owner,
                                scan.scanNumber,
                                riskFactors,
                                scanDate,
                                business
                              )
                        }
                        owner={owner}
                        resultId={scanId}
                        cloneScanId={cloneScanId}
                        scandate={scanDate}
                        high={high}
                        medium={medium}
                        low={low}
                        denialOfService={denialOfService}
                      />
                    );
                  }}
                </RouteContext.Consumer>
              </>
            );
          }
        }
      } else {
        if (i === 1) {
          if (status === 'running' || status === 'pending' || status === 'finalizing')
            rescanButton = (
              <button
                id="rescanButton"
                type="button"
                className="btn btn-success btn-sm"
                disabled={true}>
                <i className="fas fas-sync" />&nbsp; Rescan
              </button>
            );
        }
      }

      let downloadReportButton = (
        <span style={{ textTransform: 'capitalize' }}>{statusButton}</span>
      );

      if (status !== 'pending' && status !== 'running' && status !== 'finalizing') {
        if (vendor !== 'clone') {
          downloadReportButton = (
            <button
              onClick={() => this.getHostsScanResult(owner, scanNumber)}
              className="tg-dashboard-link"
              data-toggle="modal"
              data-target={'#dash-download-modal-darkweb-' + i}
              style={{
                cursor: 'pointer',
                textTransform: 'capitalize'
              }}>
              {statusButton}
            </button>
          );
        } else {
          if (business.accountType !== 'free') {
            downloadReportButton = (
              <button
                onClick={() => this.getHostsScanResult(owner, scanNumber)}
                className="tg-dashboard-link"
                data-toggle="modal"
                data-target={'#dash-download-modal-darkweb-' + i}
                style={{
                  cursor: !reportRequestButtonDisabled ? 'pointer' : 'auto',
                  textTransform: 'capitalize'
                }}
                disabled={reportRequestButtonDisabled}>
                {statusButton}
              </button>
            );
          } else {
            downloadReportButton = (
              <button
                className="tg-dashboard-link"
                data-toggle="modal"
                data-target={'#restrictedFreeAccount'}
                style={{
                  cursor: 'pointer',
                  textTransform: 'capitalize'
                }}>
                {statusButton}
              </button>
            );
          }
        }
      }

      const total = high + medium + low;

      if (denialOfService > 0) {
        denialOfServiceCountStyle = {
          display: 'block',
          width: '34px',
          cursor: 'pointer'
        };
      }
      if (high > 0) {
        highCountStyle = {
          display: 'block',
          width: '34px',
          cursor: 'pointer'
        };
      }

      if (medium > 0) {
        mediumCountStyle = {
          display: 'block',
          width: '34px',
          cursor: 'pointer'
        };
      }

      if (low > 0) {
        lowCountStyle = {
          display: 'block',
          width: '34px',
          cursor: 'pointer'
        };
      }

      return (
        <RouteContext.Consumer>
          {value => (
            <tr className="pciTable 123">
              <td />

              <td>{scanDate}</td>
              <td style={{ textTransform: 'capitalize' }}>
                {frequency !== null ? (status !== 'invalid' ? frequency : 'Invalid URL') : ''}
              </td>
              <td className="text-center px-0">
                <span
                  style={{
                    textAlign: 'center',
                    width: '42px',
                    display: 'inline-block',
                    paddingLeft: '4px'
                  }}>
                  {high > 0 ? (
                    <span
                      className="bg bg-sm bg-serious"
                      style={highCountStyle}
                      onClick={() =>
                        value.goToPageVulnerabilities(
                          scan._id,
                          owner,
                          scan.scanNumber,
                          riskFactors,
                          scanDate,
                          business
                        )
                      }>
                      {' '}
                      {high}{' '}
                    </span>
                  ) : (
                    <span className="bg bg-sm bg-serious" style={highCountStyle}>
                      {' '}
                      {high}{' '}
                    </span>
                  )}
                </span>
                <span
                  style={{
                    textAlign: 'center',
                    width: '43px',
                    display: 'inline-block',
                    paddingLeft: '6px'
                  }}>
                  {medium > 0 ? (
                    <span
                      className="bg bg-sm bg-medium"
                      style={mediumCountStyle}
                      onClick={() =>
                        value.goToPageVulnerabilities(
                          scan._id,
                          owner,
                          scan.scanNumber,
                          riskFactors,
                          scanDate,
                          business
                        )
                      }>
                      {' '}
                      {medium}{' '}
                    </span>
                  ) : (
                    <span className="bg bg-sm bg-medium" style={mediumCountStyle}>
                      {' '}
                      {medium}{' '}
                    </span>
                  )}
                </span>
                <span
                  style={{
                    textAlign: 'center',
                    width: '43px',
                    display: 'inline-block',
                    paddingLeft: '6px'
                  }}>
                  {low > 0 ? (
                    <span
                      className="bg bg-sm bg-green"
                      style={lowCountStyle}
                      onClick={() =>
                        value.goToPageVulnerabilities(
                          scan._id,
                          owner,
                          scan.scanNumber,
                          riskFactors,
                          scanDate,
                          business
                        )
                      }>
                      {' '}
                      {low}{' '}
                    </span>
                  ) : (
                    <span className="bg bg-sm bg-green" style={lowCountStyle}>
                      {' '}
                      {low}{' '}
                    </span>
                  )}
                </span>
                <span
                  style={{
                    textAlign: 'center',
                    width: '43px',
                    display: 'inline-block',
                    paddingLeft: '6px'
                  }}>
                  {denialOfService > 0 ? (
                    <span
                      className="bg bg-sm bg-green"
                      style={denialOfServiceCountStyle}
                      onClick={() =>
                        value.goToPageVulnerabilities(
                          scan._id,
                          owner,
                          scan.scanNumber,
                          riskFactors,
                          scanDate,
                          business
                        )
                      }>
                      {' '}
                      {denialOfService}{' '}
                    </span>
                  ) : (
                    <span className="bg bg-sm bg-green" style={denialOfServiceCountStyle}>
                      {' '}
                      {denialOfService}{' '}
                    </span>
                  )}
                </span>
                <span
                  style={{
                    textAlign: 'center',
                    width: '43px',
                    display: 'inline-block',
                    paddingLeft: '6px'
                  }}>
                  {total > 0 ? (
                    <span
                      className="bg bg-sm bg-green"
                      style={lowCountStyle}
                      onClick={() =>
                        vendor === 'frontline'
                          ? value.goToPageFrontlineVulnerabilities(scan._id, business)
                          : value.goToPageVulnerabilities(
                              scan._id,
                              owner,
                              scan.scanNumber,
                              riskFactors,
                              scanDate,
                              business
                            )
                      }>
                      {' '}
                      {total}{' '}
                    </span>
                  ) : (
                    <span className="bg bg-sm bg-green" style={lowCountStyle}>
                      {' '}
                      {total}{' '}
                    </span>
                  )}
                </span>
              </td>
              <td className="pr-0">
                {downloadReportButton}
                {modalShowDownloadReportOpt}
              </td>

              <td className="text-success scan-btn">
                {rescanButton} {reportGeneratedButton}
              </td>
            </tr>
          )}
        </RouteContext.Consumer>
      );
    });
  }
  render() {
    const { isDownloading, isGenerated, isProcessing, isReportDownloading } = this.state;
    let alert = null;
    if (isGenerated) {
      alert = (
        <ToastMessage
          type="success"
          notifyMessage="Report generated successfully. Please wait 3 minutes to download your report"
        />
      );
    }
    return (
      <>
        {alert}
        <Modal
          show={isDownloading}
          backdropClassName="modalBackdrop"
          size="lg"
          dialogClassName="modalBoxDialog"
          style={{ marginTop: '300px' }}>
          <Modal.Body>
            <p className="textNotify">
              {isReportDownloading
                ? 'Report is currently downloading, please wait...'
                : 'We are generating your report this could take a few minutes.'}
              <br />
              {isProcessing ? 'Please come back later to download your report.' : null}
            </p>
            <Spinner />
          </Modal.Body>
        </Modal>

        {/* START MODAL FOR FREE SCAN RESTRICTION */}
        <ModalFreeScanRestriction />
        {/* END MODAL FOR FREE SCAN RESTRICTION */}

        {this.scanHistoryContent()}
      </>
    );
  }
}

const mapStateToProps = state => ({});
DarkWebSlidePanelData.contextType = AppContext;
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DarkWebSlidePanelData);
