import React from "react";
import SwitchButton from "../../../../../components/ui/switch/SwitchButton";
const darkWebSwitch = ({ scanOptions, changeStatus }) => {
  return (
    <div className="row">
      <div className="col-sm-10 pl-0">
        <button className="tg-dashboard-link">
          <i
            className={
              scanOptions.scanReport.darkWeb
                ? "text-success fas fa-circle mr-2"
                : "text-muted fas fa-circle mr-2"
            }
          />{" "}
          DarkWeb Scan
        </button>
      </div>
      <div className="col-sm-2 pr-0 text-right" style={{ paddingLeft: "0px" }}>
        <SwitchButton
          changeStatus={() => changeStatus("darkweb")}
          status={scanOptions.scanReport.darkweb}
        />
        <br />
      </div>
    </div>
  );
};

export default darkWebSwitch;
