import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./panels.css";

import SlideContext from "../../context/slidePanelContexts";
import PciScanResult from "./pciScanResult";
import DarkWebScanResult from "./darkWebResult";
import MalwareScanResult from "./malwareScanResult";
import SslScanResult from "./sslScanResult";
import InsuranceResult from "./insuranceResult";
import SealsResult from "./sealsResult";
import LimitedScanModal from "./limitedScanModal";
import { callApiGetBusinessData, callApiGetBusinessSubdomainData } from "../../../services";
import PciMalwareSslScanModal from "./pciMalwareSslScanModal";
import PciMalwareInsuranceModal from "./pciMalwareInsuranceModal";

class ScanResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      business: {},
      subdomains: [],
      subdomainLinks: "",
      insuranceEnabledBusinesses: [
        JSON.stringify("5d6bf0195f92ba0f48ba3f9a"),

        JSON.stringify("5e559744cffc8a78d38b301b"),

        JSON.stringify("5f62873cf0bba6752ce3f0f6"),

        JSON.stringify("62b337fe76e9b97159abf88a")
      ]
    };
  }

  async componentDidMount() {
    const businessData = await callApiGetBusinessData(this.props.businessId);
    this.setState({
      business: businessData
    });

    this.getBusinesSubDomains();
  }

  async getBusinesSubDomains() {
    const subdomainData = await callApiGetBusinessSubdomainData(this.props.businessId);

    let subdomainLinks = subdomainData.data.map((domain, domainIndex) => {
      if (domainIndex < 4 && domain.host !== this.state.business.primaryDomain) {
        return (
          <li
            key={domainIndex}
            style={{
              fontSize: "0.7em",
              color: "#7f7f7f",
              padding: "0px 0 0px 20px"
            }}>
            {domain.host}{" "}
          </li>
        );
      }
    });

    this.setState({
      subdomains: subdomainData.data,
      subdomainLinks: subdomainLinks
    });
  }

  openScanOptionsPanel(context, e, businessId, index) {
    e.preventDefault();
    context.scanOptions[index].openScanOptionsPanel(businessId, index);
  }

  render() {
    const { businessId, index, value } = this.props;

    const { business, subdomains, subdomainLinks, insuranceEnabledBusinesses } = this.state;

    let scanSetOptions = (
      <>
        <SlideContext.drawerContext.Provider value={value}>
          <SlideContext.drawerContext.Consumer>
            {context => (
              <div
                className="col-1 text-center"
                data-toggle={business.accountType === "free" ? "modal" : ""}
                data-target={business.accountType === "free" ? "#limitedScanModal" : ""}>
                <br />
                <button
                  id="dash-acct-toggle"
                  className="btn-setting-link settings-toggle tg-dashboard-link"
                  onClick={e => {
                    business.accountType === "free"
                      ? void 0
                      : this.openScanOptionsPanel(context, e, businessId, index);
                  }}
                  data-testid="dashAcctToggle">
                  <span id="acct-setup" className="fa fa-ellipsis-v" />
                  <i
                    style={{
                      display:
                        context.scanOptions[index] !== undefined
                          ? context.scanOptions[index].isOptionActive
                            ? "block"
                            : "none"
                          : null
                    }}
                    className="set-arrow-ident fas fa-sort-up"
                  />
                </button>
              </div>
            )}
          </SlideContext.drawerContext.Consumer>
        </SlideContext.drawerContext.Provider>
      </>
    );

    const hasDarkWebTabClass =
      business.hasOwnProperty("hasDarkWebEnabled") && business.hasDarkWebEnabled
        ? "col-2"
        : "col-4";
    return (
      <SlideContext.drawerContext.Provider value={value}>
        <SlideContext.drawerContext.Consumer>
          {context => (
            <div className="row" style={{ marginBottom: "-5px" }}>
              <div className="col-11">
                <div className="row">
                  <div
                    className={
                      (insuranceEnabledBusinesses.indexOf(JSON.stringify(businessId)) != -1 &&
                        "col-2") ||
                      hasDarkWebTabClass
                    }>
                    <ul
                      style={{
                        fontSize: "1em",
                        listStyle: "none",
                        padding: "0px"
                      }}>
                      <li>
                        <i className="fas fa-globe-americas" />&nbsp; {business.primaryDomain}
                      </li>
                      {subdomainLinks}
                      {subdomains.length >= 4 ? (
                        <li>
                          <button
                            className="tg-dashboard-link"
                            onClick={e => {
                              business.accountType === "free"
                                ? void 0
                                : this.openScanOptionsPanel(context, e, businessId, index);
                            }}
                            style={{
                              fontSize: "0.8em",
                              textAlign: "left",
                              padding: "5px 0 0px 25px",
                              display: "block",
                              cursor: "pointer"
                            }}>
                            View more +
                          </button>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                  <div className="col-2 text-center">
                    <SlideContext.drawerContext.Provider value={value}>
                      <PciScanResult businessId={businessId} index={index} />
                    </SlideContext.drawerContext.Provider>
                  </div>
                  <div className="col-2 text-center" id="divToggleSlideMalwareButton">
                    <SlideContext.drawerContext.Provider value={value}>
                      <MalwareScanResult businessId={businessId} index={index} />
                    </SlideContext.drawerContext.Provider>
                  </div>
                  <div className="col-2 text-center" id="divToggleSlideSSLButton">
                    <SlideContext.drawerContext.Provider value={value}>
                      <SslScanResult businessId={businessId} index={index} />
                    </SlideContext.drawerContext.Provider>
                  </div>
                  {business.hasOwnProperty("hasDarkWebEnabled") && business.hasDarkWebEnabled ? (
                    <div className="col-2 text-center">
                      <SlideContext.drawerContext.Provider value={value}>
                        <DarkWebScanResult businessId={businessId} index={index} />
                      </SlideContext.drawerContext.Provider>
                    </div>
                  ) : null}

                  {(insuranceEnabledBusinesses.indexOf(JSON.stringify(businessId)) !== -1 && (
                    <div className="col-2 text-center">
                      <InsuranceResult businessId={businessId} index={index} />
                    </div>
                  )) ||
                    ""}
                  <div className="col-2 text-center">
                    <SealsResult businessId={businessId} index={index} />
                  </div>
                </div>
              </div>
              {scanSetOptions}

              <LimitedScanModal />
              <PciMalwareSslScanModal />
              <PciMalwareInsuranceModal />
            </div>
          )}
        </SlideContext.drawerContext.Consumer>
      </SlideContext.drawerContext.Provider>
    );
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScanResults);
