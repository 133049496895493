import React, { Component } from "react";
import {
  callOnGetMissingScanReport,
  callOnGetResultMissingScans,
  callOnGetFailedScanReport,
  callOnGetRunningScanReport,
  callOnGetCanceledScanReport,
  callOnGetFalsePositiveScanReport,
  callOnGetChangePort,
  callOnUpdatePort,
  callApiBusinessUpdateFalsePositive,
  callOnDeactivateScan,
  callOnGetSSLScanDates,
  callOnRefreshScanDates,
  callOnGetScanScheduleReport,
  callOnGetFreeScanReport,
} from "../../services/index";

import { setStorageItem } from "../../helpers/storage";
import MissingScans from "./pci/missingScans";
import FailedScans from "./pci/failedScans";
import FreeScans from "./pci/freeScans";
import RunningScans from "./pci/runningScans";
import ScanSchedules from "./pci/scanSchedules";
import CanceledRunningScans from "./pci/canceledRunningScans";
import SSLDates from "./pci/sslDates";
import FalsePositiveScans from "./pci/falsePositiveScans";
import PortChangeScans from "./pci/portChangeScans";
const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");
const _ = require("lodash");
class PciReports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      missingScanActive: false,
      freeScanActive: false,
      runningScanActive: false,
      canceledScanActive: false,
      failedScanActive: false,
      falsePositiveScanActive: false,
      scanScheduleActive: false,
      portScanActive: false,
      failedScans: [],
      freeScans: [],
      canceledScans: [],
      falsePositiveScans: [],
      missingScans: [],
      scanSchedules: [],
      runningScans: [],
      sslDates: [],
      ports: [],
      isLoading: false,
      isLoadingFailedScans: false,
      isLoadingFreeScans: false,
      isLoadingScanSchedule: false,
      isRefreshingSeals: false,
      isLoadingSSLDates: false,
      isLoadingFalsePositiveScans: false,
      isLoadingRunningScans: false,
      isLoadingCanceledScans: false,
      isRetrievingScans: false,
      isFalsePositiveNoRecord: false,
      isLoadingPortScans: false,
      isUpdatingPortScans: false,
      isPortScansNoRecord: false,
      selectedIndex: null,
    };
  }

  getMissingScans = async () => {
    this.setState({
      missingScanActive: !this.state.missingScanActive,
      isLoading: true,
    });
    if (this.state.missingScans.length === 0) {
      const result = await callOnGetMissingScanReport();
      this.setState({ missingScans: result.data });
    }
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 500);
  };

  getFailedScans = async () => {
    this.setState({
      failedScanActive: !this.state.failedScanActive,
      isLoadingFailedScans: true,
    });
    if (this.state.failedScans.length === 0) {
      const result = await callOnGetFailedScanReport();
      this.setState({ failedScans: result.data });
    }
    setTimeout(() => {
      this.setState({ isLoadingFailedScans: false });
    }, 500);
  };

  getFreeScans = async () => {
    this.setState({
      freeScanActive: !this.state.freeScanActive,
      isLoadingFreeScans: true,
    });
    if (this.state.freeScans.length === 0) {
      const result = await callOnGetFreeScanReport();
      this.setState({ freeScans: result.data });
    }

    setTimeout(() => {
      this.setState({ isLoadingFreeScans: false });
    }, 500);
  };

  getRunningScans = async () => {
    this.setState({
      runningScanActive: !this.state.runningScanActive,
      isLoadingRunningScans: true,
    });
    if (this.state.runningScans.length === 0) {
      const result = await callOnGetRunningScanReport();
      this.setState({ runningScans: result.data });
    }
    setTimeout(() => {
      this.setState({ isLoadingRunningScans: false });
    }, 500);
  };

  getScanSchedule = async () => {
    this.setState({
      scanScheduleActive: !this.state.scanScheduleActive,
      isLoadingScanSchedule: true,
    });
    if (this.state.scanSchedules.length === 0) {
      const result = await callOnGetScanScheduleReport();
      this.setState({ scanSchedules: result.data });
      console.log("updating scan schedule!");
    }
    setTimeout(() => {
      this.setState({ isLoadingScanSchedule: false });
    }, 500);
  };

  getCanceledScans = async () => {
    this.setState({
      canceledScanActive: !this.state.canceledScanActive,
      isLoadingCanceledScans: true,
    });
    if (this.state.canceledScans.length === 0) {
      const result = await callOnGetCanceledScanReport();
      this.setState({ canceledScans: result.data });
    }
    setTimeout(() => {
      this.setState({ isLoadingScanSchedule: false });
    }, 500);
  };

  deactivateScan = async (networkId, primaryDomain) => {
    const deactivateScans = this.state.canceledScans.filter((business) => business.primaryDomain !== primaryDomain);
    this.setState({ canceledScans: deactivateScans });
    await callOnDeactivateScan(networkId, primaryDomain);
  };

  getSSLDates = async () => {
    this.setState({
      sslDatesActive: !this.state.sslDatesActive,
      isLoadingSSLDates: true,
    });
    if (this.state.sslDates.length === 0) {
      const result = await callOnGetSSLScanDates();
      this.setState({ sslDates: result.data });
    }
    setTimeout(() => {
      this.setState({ isLoadingSSLDates: false });
    }, 500);
  };

  getRefreshSealDates = async () => {
    this.setState({ isRefreshingSeals: true });
    const result = await callOnRefreshScanDates();
    this.setState({ sslDates: result.data, isRefreshingSeals: false });
  };

  getFalsePositiveScans = async () => {
    this.setState({
      falsePositiveScanActive: !this.state.falsePositiveScanActive,
      isLoadingFalsePositiveScans: true,
    });
    if (this.state.falsePositiveScans.length === 0) {
      const result = await callOnGetFalsePositiveScanReport();

      const fpResults = [];
      const fpData = [];
      if (result.data.length > 0) {
        for (let x = 0; x < result.data.length > 0; x++) {
          fpResults.push(_.filter(result.data[x].falsePositives, (fp) => fp.status === "pending"));
        }

        const falsePositivesData = [];
        for (let j = 0; j < fpResults.length; j++) {
          for (let i = 0; i < fpResults[j].length; i++) {
            falsePositivesData.push(fpResults[j][i]);
          }
        }

        this.setState({
          falsePositiveScans: falsePositivesData,
        });
      } else {
        this.setState({
          isFalsePositiveNoRecord: true,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        isLoadingFalsePositiveScans: false,
        isFalsePositiveNoRecord: false,
      });
    }, 1500);
  };

  getPortScans = async () => {
    this.setState({
      portScanActive: !this.state.portScanActive,
      isLoadingPortScans: !this.state.isLoadingPortScans,
    });

    if (this.state.ports.length === 0) {
      const result = await callOnGetChangePort();
      this.setState({ ports: result.data });
    }
    setTimeout(() => {
      this.setState({ isLoadingPortScans: false });
    }, 500);
  };
  retrieveMissingScans = async (businessId, domainId, daysMissed, index) => {
    const param = {
      businessID: businessId,
      domainID: domainId,
      daysMissed: daysMissed,
    };
    this.setState({ isRetrievingScans: true, selectedIndex: index });
    const missingScans = await callOnGetResultMissingScans(param);
    this.setState({ isRetrievingScans: false });
    if (missingScans.success) {
      setStorageItem("businessId", cryptr.encrypt(businessId));
      window.open("/");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  };

  goToDashboard = (businessId) => {
    setStorageItem("businessId", cryptr.encrypt(businessId));
    window.open("/");
  };

  updatePort = async (businessName, businessId, owner, contactId, organizationId, domain, apiKey) => {
    this.setState({ isUpdatingPortScans: true });

    await callOnUpdatePort({
      businessId: businessId,
      contactId: contactId,
      owner: owner,
      organizationId: organizationId,
      businessName: businessName,
      domain: domain,
      apiKey: apiKey,
    });
    const ports = this.state.ports.filter((port) => port.businessId !== businessId);
    this.setState({ ports: ports, isUpdatingPortScans: false });
  };

  updateStatus = async (indexMain, index, event, id, falsePositiveId) => {
    event.persist();
    const fpData = [...this.state.falsePositiveScans];
    fpData[index].status = event.target.value;

    this.setState({ fpData });
    await callApiBusinessUpdateFalsePositive({
      falsePositiveScans: this.state.falsePositiveScans,
      id: id,
      value: event.target.value,
      index: index,
      falsePositiveId: falsePositiveId,
    });
  };

  render() {
    const {
      missingScanActive,
      runningScanActive,
      freeScanActive,
      falsePositiveScanActive,
      portScanActive,
      sslDatesActive,
      missingScans,
      canceledScans,
      runningScans,
      freeScans,
      failedScans,
      scanSchedules,
      sslDates,
      ports,
      falsePositiveScans,
      isLoading,
      isLoadingFreeScans,
      isLoadingFailedScans,
      isLoadingCanceledScans,
      isLoadingRunningScans,
      isLoadingSSLDates,
      isLoadingFalsePositiveScans,
      isRetrievingScans,
      isLoadingScanSchedule,
      selectedIndex,
      scanScheduleActive,
      failedScanActive,
      canceledScanActive,
      isFalsePositiveNoRecord,
      isLoadingPortScans,
      isUpdatingPortScans,
      isPortScansNoRecord,
      isRefreshingSeals,
    } = this.state;
    return (
      <div className="row">
        <div className="col-12">
          <div className=" tab-content">
            <MissingScans
              goToDashboard={this.goToDashboard}
              isLoading={isLoading}
              selectedIndex={selectedIndex}
              getMissingScans={this.getMissingScans}
              retrieveMissingScans={this.retrieveMissingScans}
              isRetrievingScans={isRetrievingScans}
              missingScans={missingScans}
              missingScanActive={missingScanActive}
            />
            {/* <SSLDates
              goToDashboard={this.goToDashboard}
              isLoadingSSLDates={isLoadingSSLDates}
              selectedIndex={selectedIndex}
              isRefreshingSeals={isRefreshingSeals}
              getSSLDates={this.getSSLDates}
              sslDates={sslDates}
              sslDatesActive={sslDatesActive}
              getRefreshSealDates={this.getRefreshSealDates}
            /> */}
            <FailedScans
              goToDashboard={this.goToDashboard}
              isLoadingFailedScans={isLoadingFailedScans}
              selectedIndex={selectedIndex}
              getFailedScans={this.getFailedScans}
              failedScans={failedScans}
              failedScanActive={failedScanActive}
            />
            <ScanSchedules
              goToDashboard={this.goToDashboard}
              isLoadingScanSchedule={isLoadingScanSchedule}
              selectedIndex={selectedIndex}
              getScanSchedule={this.getScanSchedule}
              scanSchedules={scanSchedules}
              scanScheduleActive={scanScheduleActive}
            />

            <RunningScans
              goToDashboard={this.goToDashboard}
              isLoadingRunningScans={isLoadingRunningScans}
              selectedIndex={selectedIndex}
              getRunningScans={this.getRunningScans}
              runningScans={runningScans}
              runningScanActive={runningScanActive}
              deactivateScan={this.deactivateScan}
            />
            <FreeScans
              goToDashboard={this.goToDashboard}
              isLoadingFreeScans={isLoadingFreeScans}
              selectedIndex={selectedIndex}
              getFreeScans={this.getFreeScans}
              freeScans={freeScans}
              freeScanActive={freeScanActive}
              deactivateScan={this.deactivateScan}
            />
            {/* <CanceledRunningScans
              goToDashboard={this.goToDashboard}
              isLoadingCanceledScans={isLoadingCanceledScans}
              selectedIndex={selectedIndex}
              getCanceledScans={this.getCanceledScans}
              canceledScans={canceledScans}
              canceledScanActive={canceledScanActive}
              deactivateScan={this.deactivateScan}
            /> */}
            <FalsePositiveScans
              isLoadingFalsePositiveScans={isLoadingFalsePositiveScans}
              selectedIndex={selectedIndex}
              getFalsePositiveScans={this.getFalsePositiveScans}
              falsePositiveScans={falsePositiveScans}
              falsePositiveScanActive={falsePositiveScanActive}
              isFalsePositiveNoRecord={isFalsePositiveNoRecord}
              updateFalsePositiveStatus={this.updateStatus}
            />
            {/* <PortChangeScans
              isLoadingPortScans={isLoadingPortScans}
              selectedIndex={selectedIndex}
              getPortScans={this.getPortScans}
              ports={ports}
              portScanActive={portScanActive}
              isUpdatingPortScans={isUpdatingPortScans}
              updatePort={this.updatePort}
            /> */}
          </div>
        </div>
      </div>
    );
  }
}

export default PciReports;
