import React from "react";
import Modal from "../../../ui/modal/modal";
import Aux from "../../../hoc/containerAux";
import { Button } from "react-bootstrap";

const modalRescan = (props) => {
  // const data = {
  //   _id: props.scanData._id,
  // };

  const riskFactors = [
    props.high > 0 ? 8 : 0,
    props.medium > 0 ? 4 : 0,
    props.low > 0 ? 1 : 0,
  ];

  let falsePositiveButton = (
    <Button
      bsStyle="warning"
      bsSize="small"
      onClick={() =>
        props.goToPageVulnerabilities(
          props.owner,
          props.resultId,
          props.scandate,
          riskFactors
        )
      }
      style={{ marginRight: "10px" }}
    >
      {" "}
      <i className="fas fa-eye" />&nbsp; View Details
    </Button>
  );
  if (props.submitFalsePostive === false) {
    falsePositiveButton = null;
  }

  return (
    <Aux>
      {/* {falsePositiveButton} */}
      {props.status !== "running" && props.status !== "pending" ? (
        <span>
          <button
            onClick={props.showModal}
            id="rescanButton"
            data-testid="dashReScanModal"
            data-backdrop="false"
            data-toggle="modal"
            data-target={`#dash-rescan-modal-` + props.businessId}
            className="btn btn-sm btn-success"
          >
            <i className="fas fa-search" aria-hidden="true" /> Rescan
          </button>
          <Modal
            targetID={`dash-rescan-modal-` + props.businessId}
            title="Rescan"
            showbutton={false}
            close={props.closeModalRescan}
          >
            <div className="pt-2 pb-4">
              <h5>
                You are about to perform an immediate scan. The scan will run
                without regard to the schedule, continue?
              </h5>
            </div>
            <br />
            <div className="modal-footer text-right px-0 pt-4">
              <button
                onClick={props.closeModalRescan}
                type="button"
                className="btn-tglight2 btn-140-width btn-md mr-2"
                data-dismiss="modal"
                style={{ marginRight: "7px" }}
              >
                No
              </button>
              <button
                type="button"
                className="btn-success btn-140-width btn-md "
                onClick={() => props.rescan(props.cloneScanId, props.resultId)}
                data-dismiss="modal"
                data-testid="startPciScanButton"
              >
                Yes
              </button>
            </div>
          </Modal>
        </span>
      ) : null}
    </Aux>
  );
};

export default modalRescan;
