import React from "react";
import { Modal, Button } from "react-bootstrap";
import config from "../../../helpers/config";

const frequencyModal = (props) => {
  const textLoading = "Updating...";
  let buttonText = "Update";
  let isDisabledButton = false;

  if (props.onSave || props.invalidTimeZone) {
    buttonText = textLoading;
    if (props.invalidTimeZone) {
      buttonText = "Update";
    }
    isDisabledButton = true;
  }
  return (
    <Modal show={props.show} onHide={props.close}>
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
        <button
          type="button"
          onClick={props.close}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="pt-2 pb-4">
          <p>{props.message}</p>
          <div className="row">
            {props.type === "pci" ? (
              <>
                <div className="col-sm">
                  <div className="form-group">
                    <label htmlFor="timeZoneSelect">Time Zone</label>
                    <select
                      className="form-control"
                      id="timeZoneSelect"
                      onChange={props.onHandlerTimeZone}
                      value={props.timezone}
                    >
                      <option value="0">Please Select Timezone</option>
                      {config.timezones.map((v, i) => {
                        return (
                          <option key={i} value={v.timezone}>
                            {v.timezone}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-sm" style={{ marginLeft: "6.5rem" }}>
                  <div className="form-group">
                    <label htmlFor="timeZoneSelect">Scan Time</label>
                    <div className="timeSelectOpt">
                      <select
                        className="form-control col-sm-12"
                        onChange={props.onHandlerTimeHour}
                        id="timeZoneSelect"
                        value={props.time}
                      >
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                        <option value="09">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12 </option>
                        <option value="13">13 </option>
                        <option value="14">14 </option>
                        <option value="15">15 </option>
                        <option value="16">16 </option>
                        <option value="17">17 </option>
                        <option value="18">18 </option>
                        <option value="19">19 </option>
                        <option value="20">20 </option>
                        <option value="21">21 </option>
                        <option value="22">22 </option>
                        <option value="23">23 </option>
                        <option value="24">24</option>
                      </select>
                      <select
                        className="form-control col-sm-12"
                        onChange={props.onHandlerTimeMinutes}
                        id="timeMinutesSelect"
                        value={props.minutes}
                      >
                        <option>15</option>
                        <option>30</option>
                      </select>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </Modal.Body>
      <div style={{ padding: "0 1rem" }}>
        <Modal.Footer>
          <Button
            style={{ float: "right", marginLeft: "10px" }}
            bsStyle="success"
            onClick={props.save}
            disabled={isDisabledButton}
          >
            {buttonText}
          </Button>
          <Button style={{ float: "right" }} onClick={props.close}>
            Close
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
export default frequencyModal;
