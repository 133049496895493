import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppContext from "../../components/context/appContext";
import { updateBusinessData } from "../../modules/actions/index";
import {
  callApiGetBusinessData,
  callApiGetBusinessUsersList,
  callApiUpdateCheckScanReportsData,
  callApiGetCheckScanReportsDataUsingBusinessId,
  callApiGetUserData,
  callApiGetAdminEmails,
  callCreateBeyondScan
} from "../../services/index";

import { createBusinessNotes } from "../../modules/actions";
import { data } from "jquery";

class RescanConformationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      business: {},
      businessId: "",
      businessUsersList: [],
      note: "",
      userId: "",
      scanTimeDetails: {
        scanTime: null,
        scanTimeZone: null
      }
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    const { userData, currentBusinessId } = this.context;

    if (currentBusinessId) {
      if (prevState.businessId !== currentBusinessId) {
        const businessData = await callApiGetBusinessData(currentBusinessId);

        if (businessData) {
          this.setState({
            business: businessData
          });
        }

        this.setState({
          businessId: currentBusinessId
        });
      }

      const businessUsersList = await callApiGetBusinessUsersList({
        businessId: currentBusinessId
      });

      if (prevState.userId !== userData._id) {
        this.setState({
          userId: userData._id
        });
      }

      if (prevState.businessUsersList.length !== businessUsersList.data.length) {
        this.setState({
          businessUsersList: businessUsersList.data
        });
      }
    }
  }

  onClickRescan = async event => {
    console.log("rescanning pci/malware");
    const flag = "manual";
    const user = this.state.businessUsersList[0];
    const business = this.state.business;
    if (user) {
      let userEmail = user.email;
      let userId = user._id;
      if (user.userDetails.isAdmin) {
        userEmail = user.userDetails.email;
        userId = user.userDetails._id;
      }

      const submitData = {
        _id: business._id,
        userId: userId !== undefined ? userId : null,
        loginEmail: userEmail,
        supportEmail: business.supportEmail,
        businessName: business.businessName,
        primaryDomain: business.primaryDomain,
        scanFrequency: business.scanFrequency,
        supportPhone: business.supportPhone,
        state: business.state,
        zip: business.zip,
        address: business.address,
        address2: business.address2,
        city: business.state,
        country: business.country,
        flag: flag,
        purpose: "create-scan",
        pciScanTime: this.state.scanTimeDetails.scanTime,
        pciScanTimeZone: this.state.scanTimeDetails.scanTimeZone
      };

      const beyondsecurity = await callCreateBeyondScan(submitData);
      if (beyondsecurity.success) {
        window.location.reload();
      }
    }
  };

  closeModal() {
    this.setState({
      note: "",
      reReview: "",
      customDate: null
    });
  }

  render() {
    const { currentBusinessId } = this.context;
    const { business, businessUsersList } = this.state;

    return (
      <div id="rescanConformationModal" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title f800">Rescan</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closeModal"
                onClick={() => {
                  this.closeModal();
                }}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              You are about to perform an immediate scan. The scan will run without regard to the
              schedule, continue?
            </div>
            <div className="modal-footer text-right">
              <button
                id="btnCancelDelayFunctionModal"
                type="button"
                className="btn-tglight2 btn-140-width btn-md"
                data-dismiss="modal"
                onClick={() => {
                  this.closeModal();
                }}>
                No
              </button>
              <button
                type="button"
                className="btn btn-success btn-140-width btn-md"
                data-dismiss="modal"
                onClick={() => {
                  this.onClickRescan();
                }}>
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RescanConformationModal.contextType = AppContext;

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ updateBusinessData, createBusinessNotes }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RescanConformationModal);
