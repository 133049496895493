import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../header';

import Billing from './billing';
import { getStorageItem } from '../../helpers/storage';
import { getUserDecryptedData } from '../../helpers/general';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AddCardModal from '../modals/addCardModal';

import {
  callGetUserById,
  callApiGetUserBusinessData,
  callOnPaymentApi,
  callApiUpdateStripeCustomerId
} from '../../services';

class UserBilling extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      isAdmin: false,
      stripePromise: loadStripe('pk_test_pfjQn73GoOmgP35YgvnONhJT'),
      options: {
        // passing the SetupIntent's client secret
        clientSecret: '',
        customerSessionClientSecret: '',
        // Fully customizable with appearance API.
        appearance: {
          theme: 'stripe'
        }
      },
      stripeCustomerData: null,
      stripeCustomerPaymentMethods: null,
      isCardAdded: false
    };
  }

  async componentDidMount() {
    let userLsData = getUserDecryptedData();
    let stripeCustomerData, stripeCustomerPaymentMethods;
    const userData = await callGetUserById(userLsData.userId);

    if (window.location.search) {
      this.setState({
        isCardAdded: true
      });

      stripeCustomerPaymentMethods = await callOnPaymentApi({
        purpose: 'retrieve-customer-payment-methods',
        customerId: userData.stripeCustomerId
      });

      const { data } =
        stripeCustomerPaymentMethods.success === true && stripeCustomerPaymentMethods.data;

      // console.log(stripeCustomerPaymentMethods);

      if (data.length > 1) {
        console.log('Multiple cards exists', stripeCustomerPaymentMethods);
        await callOnPaymentApi({
          purpose: 'detach-customer-payment-methods',
          paymentMethodId: data[data.length - 1].id,
          customerId: userData.stripeCustomerId
        });
      }
    }

    if (userData && userData.hasOwnProperty('stripeCustomerId') && userData.stripeCustomerId) {
      stripeCustomerPaymentMethods = await callOnPaymentApi({
        purpose: 'retrieve-customer-payment-methods',
        customerId: userData.stripeCustomerId
      });

      stripeCustomerData = await callOnPaymentApi({
        purpose: 'setup-intent',
        customerId: userData.stripeCustomerId
      });

      // console.log('11 stripeCustomerData', stripeCustomerData.data.data);
    } else {
      const business = await callApiGetUserBusinessData({ userId: userLsData.userId });
      if (business && business.success && business.data) {
        const { data } = business;
        const { businessName, primaryDomain, address, city, state, zip, country } =
          data && data.length > 0 && data[0];

        stripeCustomerData = await callOnPaymentApi({
          purpose: 'setup-future-payment',
          userId: userData._id,
          first_name: userData.firstName,
          last_name: userData.lastName,
          email: userData.email,
          phone: userData.phone,
          city: city,
          country: country,
          company_address: address,
          line2: '',
          zip_code: zip,
          state: state,
          company_name: businessName,
          domain: primaryDomain
        });

        // console.log('22 stripeCustomerData', stripeCustomerData);
      }
    }

    if (userLsData) {
      this.setState({
        isAdmin: userLsData.isAdmin,
        stripeCustomerPaymentMethods: stripeCustomerPaymentMethods.data.data,
        stripeCustomerData: stripeCustomerData.data,
        options: {
          clientSecret:
            stripeCustomerData.data &&
            stripeCustomerData.data.clientSecret &&
            stripeCustomerData.data.clientSecret,
          customerSessionClientSecret:
            stripeCustomerData.data &&
            stripeCustomerData.data.customerSessionClientSecret &&
            stripeCustomerData.data.customerSessionClientSecret
        }
      });
    }
  }

  render() {
    const screenHeight = window.innerHeight;
    const {
      isAdmin,
      isCardAdded,
      stripePromise,
      options,
      stripeCustomerData,
      stripeCustomerPaymentMethods
    } = this.state;

    /* console.log(
      '123 options',
      stripeCustomerPaymentMethods &&
        stripeCustomerPaymentMethods.length > 0 &&
        stripeCustomerPaymentMethods[0].card &&
        stripeCustomerPaymentMethods[0].card.last4
    ); */

    return (
      <>
        <Header titlePage="Billing" bgColor="#F5F6F8" />
        <div id="mcontent-wrapper" style={!0 ? { height: screenHeight } : {}}>
          <div id="control-panel" className="">
            <ToastContainer />
            <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
              <div className="inner-ptab tab-content">
                <div id="seal" className="tab-pane fade in active">
                  {isCardAdded === true && (
                    <div className="alert alert-success">Card added successfully</div>
                  )}

                  <div className="Rtable Rtable--6cols Rtable--collapse">
                    <table id="dash-overview-table" className="table table-striped">
                      <tbody className="scan-status-list">
                        {((stripeCustomerPaymentMethods === null ||
                          stripeCustomerPaymentMethods.lenght === 0) && (
                          <tr>
                            <td width="60%">
                              <small
                                style={{
                                  fontSize: '15px',
                                  display: 'block',
                                  clear: 'both'
                                }}>
                                No Card Currently on File
                              </small>
                              <span
                                style={{
                                  marginTop: '5px',
                                  display: 'block',
                                  clear: 'both'
                                }}>
                                <button
                                  className="btn-sm btn-tglight2 mr-2 btn"
                                  data-toggle="modal"
                                  data-target="#billingAddCardModal">
                                  Add Card Now
                                </button>
                              </span>
                            </td>
                          </tr>
                        )) || (
                          <tr>
                            <td width="60%">
                              <small
                                style={{
                                  fontSize: '15px',
                                  display: 'block',
                                  clear: 'both'
                                }}>
                                Current card on file ending in{' '}
                                {stripeCustomerPaymentMethods &&
                                  stripeCustomerPaymentMethods.length > 0 &&
                                  stripeCustomerPaymentMethods[0].card &&
                                  stripeCustomerPaymentMethods[0].card.last4}
                              </small>
                              <span
                                style={{
                                  marginTop: '5px',
                                  display: 'block',
                                  clear: 'both'
                                }}>
                                <button
                                  className="btn-sm btn-tglight2 mr-2 btn"
                                  data-toggle="modal"
                                  data-target="#billingAddCardModal">
                                  Update Card Now
                                </button>
                              </span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {options &&
          options.clientSecret !== '' &&
          stripePromise &&
          stripeCustomerData && (
            <Elements stripe={stripePromise} options={options}>
              <AddCardModal />
            </Elements>
          )}:{' '}
      </>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserBilling);
