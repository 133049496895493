import React, { Component } from "react";
import ContentWrapper from "../ui/contentWrapper";
import FalsePositive from "../../views/home/partials/falsePositive";
import { Button } from "react-bootstrap";
import { convertTimeTo } from "../../helpers/timeZone";
import { callOnFalsePostiveUserRecords } from "../../services/index";

class VulnerabilityDetailed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      falsePositives: []
    };
  }

  async componentDidMount() {
    const { state } = this.props.history.location;
    const business = state.businessDetails;
    const falsePositives = await callOnFalsePostiveUserRecords(business._id);
    this.setState({ falsePositives: falsePositives.data[0].falsePositives });
  }
  render() {
    const { state } = this.props.history.location;
    const { falsePositives } = this.state;

    const falsePositiveModal = (
      <FalsePositive
        vulnerabilityPage={true}
        vulnerabilityName={state.detail.name}
        vulnerabilityDNS={state.detail.dns}
        vulnerabilityHost={state.detail.host}
        vulnerabilityID={state.detail.id}
        businessDetails={state.businessDetails}
        vulnerabilityPort={state.detail.port}
        vulnerabilityRisk={state.detail.risk}
        vulnerabilitySeverity={state.detail.severity}
        vulnerabilityNVT={state.detail.nvt}
      />
    );

    return (
      <ContentWrapper header="Detailed Vulnerability">
        {falsePositiveModal}
        <h3>
          Vulnerability Name: <span style={{ color: "#2fac66" }}> {state.detail.title}</span>
        </h3>
        <h3>
          Host: <span style={{ color: "#2fac66" }}>{state.detail.host}</span>
        </h3>
        <h3>
          Port: <span style={{ color: "#2fac66" }}>{state.detail.port}</span>
        </h3>
        {state.detail.risk !== null && state.detail.risk !== "" ? (
          <h3> Risk: {state.detail.risk}</h3>
        ) : null}
        <br />
        <div class="row">
          <div class="col-sm-6">
            <div className="well other-info">
              <h4 className="tg">
                <i className="fas fas-lightbulb-o" /> Solution
              </h4>

              <p>
                {state.detail.solution} {state.detail.description}
              </p>
              <hr />
              <h4 className="tg">Additional Information</h4>
              <ul className="reference-list">
                <li ng-if="details.severity" className="ng-binding ng-scope">
                  Severity: {state.detail.severity}
                </li>
              </ul>
            </div>
          </div>

          <div class="col-sm-6 well false-positive">
            <h4>False Positives</h4>
            {falsePositives.length > 0 ? (
              <table class="table table-striped">
                <tr>
                  <th>#</th>
                  <th>Message</th>
                  <th>Status</th>
                  <th>Date</th>
                </tr>
                <tbody>
                  {falsePositives.map((result, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{result.message}</td>
                      <td>{result.status}</td>
                      <td>{convertTimeTo(result.date, "UTC", "MM/DD/YYYY")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <strong>No False Positives Available</strong>
            )}
          </div>
        </div>

        {state.detail.severity !== "Low" ? (
          <div id="falsePositiveSection">
            <center>
              <Button
                bsStyle="danger"
                bsSize="large"
                data-toggle="modal"
                data-target="#dash-false-positive"
                style={{ marginRight: "10px" }}>
                {" "}
                <i className="fas fa-file-upload" />&nbsp; Waive Vulnerability
              </Button>
            </center>
          </div>
        ) : null}
      </ContentWrapper>
    );
  }
}

export default VulnerabilityDetailed;
