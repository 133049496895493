import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  api,
  callApiLogin,
  callApiResetPassword,
  callApiUpdatePassword,
} from "../../services/index";
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_ACCOUNT_LOCKED,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
} from "../types/index";
import { setStorageItem, removeAllStorage } from "../../helpers/storage";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

export const onClickLogin = (data) => {
  return async (dispatch) => {
    return callApiLogin(data).then(async (res) => {
      // console.log("res", res);
      switch (res.message) {
        case "LOGIN_FAILURE":
          dispatch({
            type: LOGIN_FAILURE,
            payload: res,
          });

          toast.error(res.errors, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });
          break;

        case "LOGIN_ACCOUNT_LOCKED":
          dispatch({
            type: LOGIN_ACCOUNT_LOCKED,
            payload: res,
          });

          toast.error(res.errors, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });
          break;

        case "LOGIN_SUCCESS":
          removeAllStorage();
          const clientAccessToken = await api.authenticate({
            strategy: "local",
            email: data.email,
            password: data.password,
          });

          if (clientAccessToken) {
            const encUserData = cryptr.encrypt(JSON.stringify(res.data));
            setStorageItem("accessToken", clientAccessToken.accessToken);
            setStorageItem("userData", encUserData);
          }

          dispatch({
            type: LOGIN_SUCCESS,
            payload: res,
          });
          break;

        case "USER_NOT_FOUND":
          // window.open("https://members.trust-guard.com", "_blank");
          dispatch({
            type: LOGIN_FAILURE,
            payload: res,
          });

          toast.error("Please double check your credentials", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });
          break;

        default:
          break;
      }
    });
  };
};

export const onClickResetPassword = (data) => {
  return async (dispatch) => {
    return callApiResetPassword(data).then((res) => {
      if (res.success) {
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
          payload: res,
        });

        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2500,
        });
      } else {
        dispatch({
          type: RESET_PASSWORD_FAILURE,
        });

        if (res.code === 200) {
          toast.error(res.errors, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });
        } else {
          toast.success(res.errors, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });
        }
      }
    });
  };
};

export const onClickUpdatePassword = (data) => {
  return async (dispatch) => {
    return callApiUpdatePassword(data).then((res) => {
      if (res.success) {
        dispatch({
          type: UPDATE_PASSWORD_SUCCESS,
          payload: res,
        });

        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2500,
        });
      } else {
        dispatch({
          type: UPDATE_PASSWORD_FAILURE,
        });

        if (res.code === 200) {
          toast.error(res.errors, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });
        } else {
          toast.success(res.errors, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });
        }
      }
    });
  };
};
