import { api } from "./api";
import { callApi } from "../helpers/index";
import { validateDomain } from "../helpers/general";

export const callApiCreateAccountScan = async (data) => {
  return callApi().then((token) => {
    if (data) {
      return api.service("api/frontline").create(data);
    } else {
      return null;
    }
  });
};

export const callApiReScan = async (data) => {
  return callApi().then((token) => {
    if (data) {
      return api.service("api/frontline").create(data);
    } else {
      return null;
    }
  });
};

export const callActivateDarkWebScan = async (data) => {
  return callApi().then((token) => {
    if (data) {
      return api.service("api/beyondsecurity").create(data);
    } else {
      return null;
    }
  });
};

export const callApiSubmitFalsePositive = async (data) => {
  return callApi().then((token) => {
    if (data) {
      return api.service("api/frontline").create(data);
    } else {
      return null;
    }
  });
};

export const callDownloadClonereport = (data) => {
  return callApi().then((token) => {
    console.log("downloading....");
    return api.service("download-report").create(data);
  });
};

export const callFindBusinessDomains = (id) => {
  return callApi().then((token) => {
    return api.service("domains").find({
      query: {
        businessId: id,
      },
    });
  });
};
