import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import moment from 'moment';
import {
  callOnIgnoreVulnerabilityContains,
  callOnUpdateBusinessMalwareRule
} from '../../../../../services/index';
import _ from 'lodash';
class MalwareVulnerabilitiesData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vulnerabilities: [],
      clickedIndex: null,
      showDump: false,
      actionStatus: null,
      containFieldValue: '',
      showContainPatternField: false,
      submitted: false,
      showConfirm: false
    };
  }

  componentDidMount() {
    this.setState({ vulnerabilities: this.props.malwareInfoData });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.actionStatus !== this.state.actionStatus) {
      if (this.state.actionStatus === 'IgnoredIfFoundPattern') {
        this.setState({ showContainPatternField: true });
      }
    }
  }

  sendingMalwareForReview = () => {
    this.setState({ submitted: true, showConfirm: false });
    setTimeout(() => {
      this.setState({ submitted: false });
    }, 20000);
  };

  goToScannedFiles = () => {};

  onSubmitContainPatternHandler = async (
    vulnerabilityId,
    containFieldValue,
    malwareHistoryId,
    businessId,
    domainId,
    dumpMD5,
    fileName,
    reportUrl
  ) => {
    await callOnIgnoreVulnerabilityContains(
      vulnerabilityId,
      containFieldValue,
      malwareHistoryId,
      businessId,
      domainId,
      dumpMD5,
      fileName,
      reportUrl
    );
    window.location.reload();
  };

  onShowSubmitReviewConfirmation = () => {
    this.setState({
      showConfirm: true
    });
  };

  onCancelSubmitReview = () => {
    this.setState({
      showConfirm: false
    });
  };

  render() {
    const {
      businessId,
      primaryDomain,
      domainId,
      malwareHistoryId,
      reportUrl,
      status,
      index,
      malwareInfoData,
      sentForReview,
      updateMalwareStatus,
      totalIgnoredFiles,
      malwareRules,
      submitMalwareForReview
    } = this.props;
    const {
      clickedIndex,
      actionStatus,
      vulnerabilities,
      showDump,
      showContainPatternField,
      containFieldValue,
      submitted,
      showConfirm
    } = this.state;

    const Button = () => (
      <Route
        render={({ history }) => (
          <button
            type="button"
            onClick={() => {
              history.push('/new-location');
            }}>
            Click Me!
          </button>
        )}
      />
    );
    return (
      <>
        <div index={index} className="vulnerabilityInfoData">
          <div className="container-fluid">
            {submitted ? (
              <div class="alert alert-success" role="alert">
                Malware Vulnerability Sent For Review
              </div>
            ) : null}

            {this.props.malwareInfoData.length > 0
              ? _.filter(this.props.malwareInfoData, m => m.status !== 'clean').map(
                  (malware, i) => {
                    return (
                      <>
                        <div className="row">
                          <div className="col-sm-12">
                            {showConfirm ? (
                              <div class="alert alert-info" role="alert">
                                <strong>
                                  Are you sure you want to submit this vulnerability for review?
                                </strong>{' '}
                                &nbsp;&nbsp;{' '}
                                <button
                                  type="button"
                                  class="btn btn-success"
                                  onClick={() => {
                                    submitMalwareForReview(
                                      'Sent for review',
                                      malwareHistoryId,
                                      businessId,
                                      malware.reportUrl
                                    );
                                    this.sendingMalwareForReview();
                                  }}>
                                  Submit for Review
                                </button>{' '}
                                <button
                                  type="button"
                                  class="btn btn-danger"
                                  onClick={() => this.onCancelSubmitReview()}>
                                  Cancel
                                </button>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <strong>{malware.host}</strong>
                            <br />
                            <br />
                            <br />
                            {malware.vulnerabilityInfo.length > 0 ? (
                              <button
                                disabled={showConfirm || submitted || sentForReview}
                                onClick={() => {
                                  this.onShowSubmitReviewConfirmation();
                                }}
                                class="btn btn-sm btn-outline-danger btn-140-width"
                                style={{
                                  borderRadius: '0.2rem',
                                  padding: '0.25rem'
                                }}>
                                {submitted || sentForReview ? 'Sent for Review' : 'Send for Review'}
                              </button>
                            ) : (
                              <>
                                <p>
                                  <i
                                    class="text-success fas fa-exclamation-circle"
                                    aria-hidden="true"
                                  />{' '}
                                  No Vulnerabilities Found
                                </p>
                              </>
                            )}

                            <br />
                            <br />
                            {malware.vulnerabilityInfo.length > 0 ? (
                              totalIgnoredFiles >= 1 ? (
                                <p>
                                  <Route
                                    render={({ history }) => (
                                      <a
                                        href="#"
                                        style={{
                                          textAlign: 'left',
                                          display: 'block',
                                          marginTop: '1.3em'
                                        }}
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() =>
                                          history.push({
                                            pathname: '/malware-scanned-files',
                                            state: {
                                              vulnerabilities: malware.vulnerabilityInfo,
                                              domainHost: malware.host,
                                              businessId: businessId,
                                              reportUrl: reportUrl,
                                              malwareHistoryId: malwareHistoryId,
                                              malwareRules: malwareRules
                                            }
                                          })
                                        }>
                                        {totalIgnoredFiles} File{totalIgnoredFiles >= 2
                                          ? 's'
                                          : null}{' '}
                                        Ignored
                                      </a>
                                    )}
                                  />
                                </p>
                              ) : null
                            ) : null}
                          </div>
                          <div className="col-sm-6">
                            <Route
                              render={({ history }) => (
                                <a
                                  href="#"
                                  style={{
                                    textAlign: 'right',
                                    display: 'block',
                                    marginTop: '1.3em'
                                  }}
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  data-domainId={malware.domainId}
                                  onClick={() =>
                                    history.push({
                                      pathname: '/malware-scanned-files',
                                      state: {
                                        vulnerabilities: malware.vulnerabilityInfo,
                                        domainHost: malware.host,
                                        businessId: businessId,
                                        domainId: malware.domainId,
                                        reportUrl: reportUrl,
                                        malwareHistoryId: malwareHistoryId,
                                        malwareRules: malwareRules
                                      }
                                    })
                                  }>
                                  View All Scanned Files
                                </a>
                              )}
                            />
                          </div>
                        </div>
                        {/* START VULNERABILITY */}
                        {malware.vulnerabilityInfo.length > 0
                          ? _.filter(malware.vulnerabilityInfo, m => m.threat !== 'Clean').map(
                              (info, j) => (
                                <>
                                  <div class="row">
                                    <div class="col-xs-10 col-md-10">
                                      <ul class="vulnerabilityList" index={j}>
                                        <li>
                                          <strong>Scan Completed:</strong>{' '}
                                          {moment(malware.scanFinish).format('MM/DD/YYYY h:m a')}
                                        </li>
                                        <li>
                                          <strong>Filename:</strong> {info.fileName}
                                        </li>
                                        <li>
                                          <strong>File Type:</strong> {info.fileType}
                                        </li>
                                        <li>
                                          <strong>Details:</strong> {info.details}
                                        </li>
                                        <li>
                                          <strong>Reason:</strong> {info.reason}
                                        </li>
                                        <li>
                                          <strong>Threat Detected:</strong> {info.threat}
                                        </li>
                                        <li>
                                          <strong>Status:</strong> {info.action}
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="col-xs-2 col-md-2">
                                      <select
                                        className="btn-default btn-md form-control"
                                        onChange={e => {
                                          if (e.target.value === 'Ignored') {
                                            updateMalwareStatus(
                                              e,
                                              reportUrl,
                                              info.threat,
                                              info.fileName,
                                              status,
                                              malwareHistoryId,
                                              domainId,
                                              info.dumpMD5,
                                              businessId
                                            );
                                          }

                                          this.setState({
                                            actionStatus: e.target.value,
                                            clickedIndex: j
                                          });
                                        }}
                                        value={j === clickedIndex ? actionStatus : info.action}>
                                        <option
                                          className="bg-white"
                                          defaultValue
                                          value="Action needed">
                                          Action
                                        </option>
                                        {info.action === 'Ignored' ? (
                                          <option value="Ignored">Ignored</option>
                                        ) : (
                                          <option value="Ignored">Ignore</option>
                                        )}
                                        {info.action !== 'Ignored' ? (
                                          <option value="IgnoredIfFoundPattern">
                                            Ignore if contains
                                          </option>
                                        ) : null}
                                      </select>
                                    </div>
                                  </div>
                                  <br />
                                  <div class="row">
                                    <a
                                      href="#"
                                      class="code-dump"
                                      onClick={e => {
                                        e.preventDefault();
                                        this.setState({
                                          showDump: !showDump,
                                          clickedIndex: j
                                        });
                                      }}>
                                      Code Dump
                                    </a>
                                    <br />
                                    <br />
                                    <div
                                      id="malware-dump"
                                      style={{
                                        display: showDump && j == clickedIndex ? 'block' : 'none'
                                      }}>
                                      {info.dump}
                                    </div>
                                  </div>
                                  <div class="show-ignore-pattern">
                                    {j === clickedIndex && showContainPatternField ? (
                                      <>
                                        <br />
                                        <br />
                                        <div className="well">
                                          <div className="form-inline">
                                            <div className="form-group">
                                              <label>Text that Contains: &nbsp;&nbsp;</label>
                                              <input
                                                onChange={e =>
                                                  this.setState({
                                                    containFieldValue: e.target.value
                                                  })
                                                }
                                                type="text"
                                                className="form-control"
                                                id="regexcontains"
                                                placeholder="Put text that match a pattern eg: &#60code&#62"
                                              />
                                            </div>
                                            <button
                                              style={{ marginTop: '-10px' }}
                                              className="btn btn-success"
                                              onClick={() =>
                                                this.onSubmitContainPatternHandler(
                                                  info._id,
                                                  containFieldValue,
                                                  malwareHistoryId,
                                                  businessId,
                                                  domainId,
                                                  info.dumpMD5,
                                                  info.fileName,
                                                  malware.reportUrl
                                                )
                                              }>
                                              Ignore
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    ) : null}
                                  </div>
                                </>
                              )
                            )
                          : null}
                        {/* END VULNERABILITY */}
                      </>
                    );
                  }
                )
              : null}
          </div>
        </div>
      </>
    );
  }
}

export default MalwareVulnerabilitiesData;
