import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { setStorageItem } from "../../helpers/storage";
import AppContext from "../../components/context/appContext";
import { callApiGetBusinessData } from "../../services";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class ControlButtons extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      business: {}
    };
  }

  async componentDidMount() {
    const businessData = await callApiGetBusinessData(this.props.businessId);

    this.setState({
      business: businessData
    });
  }

  setupCurrentBusinessId = businessId => {
    setStorageItem("businessId", cryptr.encrypt(businessId));
    this.context.updateCurrentBusinessId(businessId);
  };

  deleteAccount = businessId => {
    setStorageItem("businessId", cryptr.encrypt(businessId));
    this.context.updateCurrentBusinessId(businessId);
  };

  render() {
    const { businessId } = this.props;
    const { business } = this.state;

    return (
      <div>
        <div className="dropdown">
          <button
            className="btn btn-success dropdown-toggle"
            type="button"
            id="dropdownMenu1"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            Controls
            <span style={{ position: "relative", left: "3px" }} className="caret" />
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
            <li className="pointer">
              <button
                className="tg-anchor-button"
                data-toggle="modal"
                data-target={"#rescanConformationModal"}
                data-backdrop="static"
                data-keyboard="false"
                onClick={() => {
                  this.setupCurrentBusinessId(businessId);
                }}>
                Rescan Site
              </button>
            </li>
            <li className="pointer">
              <button
                className="tg-anchor-button"
                data-toggle="modal"
                data-target={"#delayfunctionInAdvanced"}
                data-backdrop="static"
                data-keyboard="false"
                onClick={() => {
                  this.setupCurrentBusinessId(businessId);
                }}>
                Delay Functions
              </button>
            </li>
            <li className="pointer">
              <button
                className="tg-anchor-button"
                data-toggle="modal"
                data-target={"#customerInfo"}
                onClick={() => {
                  this.setupCurrentBusinessId(businessId);
                }}>
                Customer Info/Notes
              </button>
            </li>
            <li className="pointer">
              <button className="tg-anchor-button">Account Info/Billing</button>
            </li>
            <li className="pointer">
              <button
                className="tg-anchor-button"
                data-toggle="modal"
                data-target="#retriveMissingScansModal"
                onClick={() => {
                  this.setupCurrentBusinessId(businessId);
                }}>
                Retrieve Missing Scans
              </button>
            </li>
            <li className="pointer">
              <button
                className="tg-anchor-button"
                data-toggle="modal"
                data-target="#modifyScanDetailsModal"
                onClick={() => {
                  this.setupCurrentBusinessId(businessId);
                }}>
                Modify Scan Details
              </button>
            </li>
            <hr className="my-2" />
            <li className="pointer">
              <button
                className="tg-anchor-button"
                data-toggle="modal"
                data-target="#accountSecurityModal"
                onClick={() => {
                  this.setupCurrentBusinessId(businessId);
                }}>
                Users Account
              </button>
            </li>
            <li
              className="pointer"
              data-toggle="modal"
              data-target={"#activateOrCancelBusinessModal"}
              onClick={() => {
                this.setupCurrentBusinessId(businessId);
              }}>
              <button className="tg-anchor-button">
                {business && business.status == "active" ? "Cancel" : "Activate"} Account
              </button>
            </li>

            <li className="pointer">
              <button
                className="tg-anchor-button"
                onClick={() => {
                  setStorageItem("businessId", cryptr.encrypt(businessId));
                  window.open("/");
                }}>
                View Users Account
              </button>
            </li>

            <li className="pointer">
              <button
                className="tg-anchor-button"
                data-toggle="modal"
                data-target={"#primaryDomainUpdateModal"}
                onClick={() => {
                  this.setupCurrentBusinessId(businessId);
                }}>
                Change Primary Domain
              </button>
            </li>

            <li className="pointer">
              <button
                data-toggle="modal"
                data-target={"#adminRefreshSealModal"}
                className="tg-anchor-button"
                onClick={() => {
                  this.setupCurrentBusinessId(businessId);
                }}>
                Refresh Seals
              </button>
            </li>
            {business && !business.hasDarkWebEnabled ? (
              <li className="pointer">
                <button
                  className="tg-anchor-button"
                  data-toggle="modal"
                  data-target={"#activateDarkWebModal"}
                  onClick={() => {
                    this.setupCurrentBusinessId(businessId);
                  }}>
                  Activate DarkWeb
                </button>
              </li>
            ) : (
              <li className="pointer">
                <button
                  className="tg-anchor-button"
                  data-toggle="modal"
                  data-target={"#deactivateDarkWebModal"}
                  onClick={() => {
                    this.setupCurrentBusinessId(businessId);
                  }}>
                  Deactivate DarkWeb
                </button>
              </li>
            )}

            {/* <li className="pointer">
              <button
                className="tg-anchor-button"
                data-toggle="modal"
                data-target={"#activateInsuranceModal"}
                onClick={() => {
                  this.setupCurrentBusinessId(businessId);
                }}
              >
                Activate Insurance
              </button>
            </li>

            <li className="pointer">
              <button
                className="tg-anchor-button"
                data-toggle="modal"
                data-target={"#insuranceDocumentsModal"}
              >
                Insurance Docs
              </button>
            </li> */}

            <li className="pointer">
              <button
                className="tg-anchor-button"
                data-toggle="modal"
                data-target={"#enableBuyerProtection "}
                onClick={() => {
                  this.setupCurrentBusinessId(businessId);
                }}>
                Enable 4X Account
              </button>
            </li>

            <li className="pointer">
              <button
                className="tg-anchor-button"
                data-toggle="modal"
                data-target={"#deleteSeals"}
                onClick={() => {
                  this.setupCurrentBusinessId(businessId);
                }}>
                Delete Seals
              </button>
            </li>

            <li className="pointer">
              <button
                className="tg-anchor-button"
                data-toggle="modal"
                data-target={"#addSealUrl"}
                onClick={() => {
                  this.setupCurrentBusinessId(businessId);
                }}>
                Seal URLS
              </button>
            </li>

            <li className="pointer">
              <button
                className="tg-anchor-button"
                data-toggle="modal"
                data-target={"#adminEnableSslAutoRenewButtonModal"}
                onClick={() => {
                  this.setupCurrentBusinessId(businessId);
                }}>
                Enable SSL Auto Renew
              </button>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

ControlButtons.contextType = AppContext;

const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ControlButtons);
