import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import { convert24HourFormat } from "../../helpers/timeZone";
import { setStorageItem } from "../../helpers/storage";
import ControlButtons from "./controlButtons";
import {
  callApiGetAdvanceSearchBusinessData,
  callApiGetUserUsingBusinessId,
  callApiGetDomainInfoUsingBusinessId,
  callApiUpdateBusinessData,
  callApiGetBusinessData
} from "../../services/index";
import AppContext from "../../components/context/appContext";
import BusinessUsersModal from "../modals/businessUsersModal";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class AdvancedSearchTab extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      userId: null,
      businesses: [],
      resultLimit: 25,
      host: "",
      accountType: "",
      businessId: "",
      email: "",
      phone: "",
      name: "",
      startDate: null,
      endDate: null,
      datePickerShow: "none",
      criteriaShow: "block",
      showType: "none",
      searchBy: "host",
      showCancelledSites: false,
      showInvalidSites: false,
      searchText: "",
      pciToolTipActive: false,
      pciTimeText: "",
      sslToolTipActive: false,
      sslToolTipText: ""
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { userData } = this.context;
    if (prevState.userId !== userData._id) {
      this.setState({
        userId: userData._id
      });
    }

    if (
      prevProps.controlType !== this.props.controlType &&
      this.props.controlType === "advanced-search"
    ) {
      this.onClickAdvanceSearch();
    }
  }

  handleChange = evt => {
    let eventValue = evt.target.value;

    switch (eventValue) {
      case "createdAt":
        this.setState({
          datePickerShow: "block",
          criteriaShow: "none",
          showType: "none",
          searchBy: evt.target.value
        });
        break;

      case "accountType":
        this.setState({
          showType: "block",
          criteriaShow: "none",
          searchBy: evt.target.value
        });
        break;
      default:
        this.setState({
          datePickerShow: "none",
          criteriaShow: "block",
          showType: "none",
          searchBy: eventValue !== "mostRecent" ? evt.target.value : ""
        });
        break;
    }
  };

  onClickAdvanceSearch = async () => {
    let businessQuery, businessStatus;

    if (this.state.showCancelledSites) {
      businessStatus = ["canceled", "invalid"];
    } else {
      businessStatus = ["active", "invalid"];
    }

    switch (this.state.searchBy) {
      case "host":
        businessQuery = {
          action: "host",
          searchText: this.state.searchText,
          checkInvalid: this.state.showInvalidSites,
          limit: this.state.resultLimit,
          status: businessStatus
        };
        break;
      case "businessId":
        businessQuery = {
          action: "business-id",
          searchText: this.state.searchText,
          checkInvalid: this.state.showInvalidSites,
          limit: this.state.resultLimit,
          status: businessStatus
        };
        break;
      case "email":
        businessQuery = {
          action: "email",
          searchText: this.state.searchText,
          checkInvalid: this.state.showInvalidSites,
          limit: this.state.resultLimit,
          status: businessStatus
        };
        break;
      case "phone":
        businessQuery = {
          action: "phone",
          searchText: this.state.searchText,
          checkInvalid: this.state.showInvalidSites,
          limit: this.state.resultLimit,
          status: businessStatus
        };
        break;
      case "name":
        businessQuery = {
          action: "name",
          searchText: this.state.searchText,
          checkInvalid: this.state.showInvalidSites,
          limit: this.state.resultLimit,
          status: businessStatus
        };
        break;
      case "createdAt":
        businessQuery = {
          action: "created-at",
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          searchText: "",
          checkInvalid: this.state.showInvalidSites,
          limit: this.state.resultLimit,
          status: businessStatus
        };
        break;
      case "cancelled-sites":
        businessQuery = {
          action: "get-cancelled-businesses",
          searchText: this.state.searchText,
          checkInvalid: this.state.showInvalidSites,
          limit: this.state.resultLimit,
          status: businessStatus
        };
        break;

      case "accountType":
        businessQuery = {
          action: "type",
          searchText: this.state.accountType,
          checkInvalid: this.state.showInvalidSites,
          limit: this.state.resultLimit,
          status: businessStatus
        };
        break;
      default:
        businessQuery = {
          action: "search-businesses",
          searchText: this.state.searchText,
          checkInvalid: this.state.showInvalidSites,
          limit: this.state.resultLimit,
          status: businessStatus
        };
        break;
    }

    const searchedData = await callApiGetAdvanceSearchBusinessData(businessQuery);

    this.setState({
      businesses:
        searchedData &&
        searchedData.success === true &&
        searchedData.data &&
        searchedData.data.length > 0
          ? searchedData.data
          : []
    });
  };

  onTextKeyPress = evt => {
    if (evt.key === "Enter") {
      this.onClickAdvanceSearch(evt);
    }
  };

  handleChangeStartDate = date => {
    this.setState({
      startDate: date
    });
  };

  handleChangeEndDate = date => {
    this.setState({
      endDate: date
    });
  };

  iconStatus = (pciStatus, malwareStatus, sslStatus, business) => {
    const iconStyle = { width: "24px", height: "24px" };
    let pciIcon = "https://tg3images.s3.us-east-2.amazonaws.com/pci-gray.png";
    let malIcon = "https://tg3images.s3.us-east-2.amazonaws.com/mal-gray.png";
    let sealIcon = "https://tg3images.s3.us-east-2.amazonaws.com/ssl-gray.png";
    switch (pciStatus) {
      case "pass":
        pciIcon = "https://tg3images.s3.us-east-2.amazonaws.com/pci-pass.png";
        break;
      case "fail":
        pciIcon = "https://tg3images.s3.us-east-2.amazonaws.com/pci-fail.png";
        break;
      default:
        pciIcon = "https://tg3images.s3.us-east-2.amazonaws.com/pci-gray.png";
        break;
    }

    switch (malwareStatus) {
      case "clean":
        malIcon = "https://tg3images.s3.us-east-2.amazonaws.com/mal-pas.png";
        break;
      case "potentially suspicious":
        malIcon = "https://tg3images.s3.us-east-2.amazonaws.com/mal-fail.png";
        break;
      case "suspicious":
        malIcon = "https://tg3images.s3.us-east-2.amazonaws.com/mal-fail.png";
        break;
      default:
        malIcon = "https://tg3images.s3.us-east-2.amazonaws.com/mal-gray.png";
        break;
    }

    switch (sslStatus) {
      case "active":
        sealIcon = "https://tg3images.s3.us-east-2.amazonaws.com/ssl-pass.png";
        break;
      default:
        sealIcon = "https://tg3images.s3.us-east-2.amazonaws.com/ssl-gray.png";
        break;
    }

    let showPCIToolTip = "";
    let showPCIToolTipStyle = {
      display: "none"
    };

    if (this.state.pciToolTipActive) {
      showPCIToolTip = "roleTooltipTextV3";
      showPCIToolTipStyle = {
        width: "250px",
        bottom: "35px",
        left: "0px",
        padding: "7px"
      };
    }

    let pciScanToolTip = "This business has never been scanned";

    if (business.lastPciStatus !== null && business.scanFrequency) {
      pciScanToolTip =
        "PCI Scan : " +
        business.scanFrequency.charAt(0).toUpperCase() +
        business.scanFrequency.slice(1) +
        " @ " +
        this.state.pciToolTipText;
    }

    return (
      <span>
        <span>
          <span className="account-title1">
            &nbsp;
            <span
              className="roleTooltipV3"
              onMouseEnter={() => this.getUserPCIscanTime(business._id)}>
              <img style={iconStyle} src={pciIcon} alt="PCI scan frequency" />
              <span className={showPCIToolTip} style={showPCIToolTipStyle}>
                <div style={{ fontSize: "11px" }}>
                  <strong>{pciScanToolTip}</strong>
                </div>
              </span>
            </span>
          </span>
        </span>

        <span>
          <span className="account-title1">
            &nbsp;
            <span className="roleTooltipV3">
              <img alt="malware-icon" style={iconStyle} src={malIcon} />
              <span
                className="roleTooltipTextV3"
                style={{
                  width: "250px",
                  bottom: "35px",
                  left: "0px",
                  padding: "7px"
                }}>
                <div style={{ fontSize: "11px" }}>
                  <strong>
                    {"Next Malware Scan Date : " +
                      moment(business.nextMalwareScan).format("MMMM Do YYYY, h:mm:ss a")}
                  </strong>
                </div>
              </span>
            </span>
          </span>
        </span>

        <span>
          <span className="account-title1">
            &nbsp;
            <span
              className="roleTooltipV3"
              onMouseEnter={() => this.getBusinessDomainInfo(business._id)}>
              <img alt="sealIcon" style={iconStyle} src={sealIcon} />
              <span
                className="roleTooltipTextV3"
                style={{
                  width: "250px",
                  bottom: "35px",
                  left: "0px",
                  padding: "7px"
                }}>
                <div style={{ fontSize: "11px" }}>
                  <strong>{this.state.sslToolTipText}</strong>
                </div>
              </span>
            </span>
          </span>
        </span>
      </span>
    );
  };

  getUserPCIscanTime = async businessId => {
    const userInfo = await callApiGetUserUsingBusinessId(businessId);
    const user = userInfo.data[0];
    const pciScanTime =
      user && user.hasOwnProperty("pciScanTime") && user.pciScanTime !== null
        ? user.pciScanTime
        : "12";
    const pciScanTimeZone = user.timezone !== null ? user.timezone : "America/Denver";

    const pciTimeText = convert24HourFormat(pciScanTime) + " - " + pciScanTimeZone;
    this.setState({ pciToolTipActive: true, pciToolTipText: pciTimeText });
  };

  getBusinessDomainInfo = async businessId => {
    const domainInfo = await callApiGetDomainInfoUsingBusinessId(businessId);
    const domain = domainInfo.data[0];

    this.setState({
      sslToolTipActive: true,
      sslToolTipText:
        domain && domain.sslInfo && domain.sslInfo.nextCheckDate
          ? "Next SSL Scan Date : " +
            moment(domain.sslInfo.nextCheckDate).format("MMMM Do YYYY, h:mm:ss a")
          : "Not scanned yet"
    });
  };

  changeBusinessAccountType = async (userId, businessId, currentStatus, newStatus) => {
    let currentBusinessData = await callApiGetBusinessData(businessId);
    let accountTypeChangeLog = null;

    // console.log("currentBusinessData", currentBusinessData);

    if (
      currentBusinessData &&
      currentBusinessData.accountTypeChangeLog &&
      currentBusinessData.accountTypeChangeLog.length > 0
    ) {
      accountTypeChangeLog = currentBusinessData.accountTypeChangeLog;
      accountTypeChangeLog.push({
        currentStatus: currentStatus,
        newStatus: newStatus,
        userId: userId,
        actionDate: new Date()
      });
    } else {
      // console.log("H---------------------");
      accountTypeChangeLog = {
        currentStatus: currentStatus,
        newStatus: newStatus,
        userId: userId,
        actionDate: new Date()
      };
    }

    const updateResult = await callApiUpdateBusinessData(businessId, {
      purpose: "accountType",
      accountType: newStatus,
      accountTypeChangeLog: accountTypeChangeLog,
      onBsUpdate: true
    });

    if (updateResult.success) {
      this.onClickAdvanceSearch();

      toast.success(updateResult.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500
      });
    }
  };

  render() {
    const {
      resultLimit,
      host,
      accountType,
      businessId,
      email,
      phone,
      name,
      startDate,
      endDate,
      datePickerShow,
      criteriaShow,
      showType,
      searchBy,
      searchText,
      showCancelledSites,
      showInvalidSites,
      businesses,
      userId
    } = this.state;

    return (
      <>
        <div id="advsearch" className="tab-pane fade">
          <h4 style={{ marginBottom: "25px" }}>ADVANCED SEARCH</h4>
          <div className="row no-pad">
            <div className="col-lg-2 col-md-2 col-sm-12">
              <label>Search By:</label>
              <select id="dateRange" className="form-control" onChange={this.handleChange}>
                <option value="host" defaultValue="host">
                  Website
                </option>
                <option value="mostRecent">Most Recent</option>
                <option value="accountType">Type</option>
                <option value="businessId">ID</option>
                <option value="email">Email</option>
                <option value="phone">Phone</option>
                <option value="name">Name</option>
                <option value="createdAt">Date Range</option>
              </select>
            </div>

            {searchBy ? (
              <div
                id="AdvSearchBy"
                style={{ display: criteriaShow }}
                className="col-lg-3 col-md-3 col-sm-12">
                <label>Search Criteria:</label>
                <input
                  data-testid="inputSearchBox"
                  type="text"
                  className="form-control"
                  value={searchText}
                  onChange={evt => {
                    this.setState({
                      searchText: evt.target.value
                    });
                  }}
                  onKeyPress={this.onTextKeyPress}
                />
              </div>
            ) : (
              ""
            )}

            <div
              id="AdvSearchFrom"
              style={{ display: datePickerShow }}
              className="col-lg-4 col-md-3 col-sm-12">
              <label style={{ display: "block" }}>From - To:</label>
              <div className="input-group">
                <DatePicker
                  selected={startDate}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  onChange={this.handleChangeStartDate}
                  className="form-control"
                />
                &nbsp;&nbsp;&nbsp;
                <DatePicker
                  selected={endDate}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  onChange={this.handleChangeEndDate}
                  className="form-control"
                />
              </div>
            </div>

            <div style={{ display: showType }}>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <label>Type</label>
                <select
                  id="selBusinessType"
                  className="form-control"
                  defaultValue={accountType}
                  onChange={evt => {
                    this.setState({
                      accountType: evt.target.value
                    });
                  }}>
                  <option value="free">Free</option>
                  <option value="basic">Ultimate Website Protection</option>
                  <option value="security">Total Website Protection</option>
                  <option value="60-day-trial">60 Day Trial</option>
                  <option value="90-day-trial">90 Day Trial</option>
                  <option value="monthly-scan">Monthly Scan</option>
                  <option value="vulnerability-scanning">Vulnerability Scanning</option>
                  <option value="security-with-dark-web">PCI/Malware Scanning With Dark Web</option>
                </select>
              </div>
            </div>

            <div>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <label>Show Result:</label>
                <select
                  id="paginateResult"
                  className="form-control"
                  defaultValue={resultLimit}
                  onChange={evt => {
                    this.setState({
                      resultLimit: parseInt(evt.target.value)
                    });
                  }}>
                  <option value="25">Last 25</option>
                  <option value="50">Last 50</option>
                  <option value="100">Last 100</option>
                </select>
              </div>
            </div>

            <button
              data-testid="btnSearch"
              style={{
                position: "relative",
                top: "25px"
              }}
              className="col-lg-2 col-md-2 col-sm-12 btn-140-width btn-success btn-md"
              onClick={() => this.onClickAdvanceSearch()}>
              Search
            </button>

            <div
              style={{
                cursor: "pointer",
                position: "relative",
                top: "32px",
                marginLeft: "45px"
              }}>
              <input
                type="checkbox"
                value={showCancelledSites}
                name="pciScanCheckedEmail"
                checked={showCancelledSites}
                onChange={evt =>
                  this.setState(
                    {
                      showCancelledSites: !showCancelledSites
                    },
                    () => {
                      this.onClickAdvanceSearch();
                    }
                  )
                }
              />
              <label
                style={{
                  cursor: "pointer",
                  marginLeft: "15px"
                }}
                onClick={() =>
                  this.setState(
                    {
                      showCancelledSites: !showCancelledSites
                    },
                    () => {
                      this.onClickAdvanceSearch();
                    }
                  )
                }>
                Show Cancelled Sites
              </label>
              &nbsp;&nbsp;
              <input
                type="checkbox"
                value={showInvalidSites}
                name="pciScanCheckedEmail"
                checked={showInvalidSites}
                onChange={evt =>
                  this.setState(
                    {
                      showInvalidSites: !showInvalidSites
                    },
                    () => {
                      this.onClickAdvanceSearch();
                    }
                  )
                }
              />
              <label
                style={{
                  cursor: "pointer",
                  marginLeft: "15px"
                }}
                onClick={() =>
                  this.setState(
                    {
                      showInvalidSites: !showInvalidSites
                    },
                    () => {
                      this.onClickAdvanceSearch();
                    }
                  )
                }>
                Show Invalid Sites
              </label>
            </div>
          </div>

          <table className="table table-striped">
            <thead>
              <tr>
                <th>
                  <label>Domain/IP</label>
                </th>
                <th>
                  <label>Email</label>
                </th>
                <th>
                  <label>Business</label>
                </th>
                <th>
                  <label />
                </th>
                <th>
                  <label>Package Type</label>
                </th>
                <th>
                  <label>Created</label>
                </th>
                <th>
                  <label>Status</label>
                </th>
                <th>
                  <label>Action</label>
                </th>
              </tr>
            </thead>
            <tbody className="scan-status-list">
              {businesses.length > 0 ? (
                businesses.map(business => {
                  return (
                    <tr key={business._id}>
                      <td>
                        {business.primaryDomain ? (
                          <button
                            className="text-link tg-link"
                            data-testid="viewUserDashboard"
                            onClick={() => {
                              setStorageItem("businessId", cryptr.encrypt(business._id));
                              window.open("/");
                            }}>
                            {business.primaryDomain}
                          </button>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td>
                        <button
                          className="tg-dashboard-passed-link"
                          data-toggle="modal"
                          data-target="#businessUsersModal"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.context.updateCurrentBusinessId(business._id);
                          }}>
                          View
                        </button>
                      </td>
                      <td>
                        {business.businessName} <br />
                        {business._id}
                      </td>
                      <td>
                        {this.iconStatus(
                          business.pciStatus,
                          business.currentMalwareScanResult,
                          business.sealStatus,
                          business
                        )}
                      </td>
                      <td>
                        <div className="dropdown">
                          <button
                            className="btn btn-default dropdown-toggle btn-sm"
                            type="button"
                            data-toggle="dropdown">
                            {business.accountType !== ""
                              ? business.accountType === "basic"
                                ? "Ultimate Website Protection"
                                : business.accountType === "security"
                                  ? "Total Website Protection"
                                  : business.accountType === "monthly-scan"
                                    ? "Monthly Scan"
                                    : business.accountType === "vulnerability-scanning"
                                      ? "Vulnerability Scanning"
                                      : business.accountType === "security-with-dark-web"
                                        ? "Total Website Protection With Dark Web"
                                        : business.accountType.charAt(0).toUpperCase() +
                                          business.accountType.slice(1)
                              : "Option"}
                            {"  "}
                            <span className="caret" />
                          </button>
                          <ul className="dropdown-menu">
                            <li
                              className="pointer"
                              style={{
                                display: business.accountType === "free" ? "none" : "block"
                              }}>
                              <button
                                className="tg-dashboard-link"
                                onClick={e => {
                                  e.preventDefault();
                                  this.changeBusinessAccountType(
                                    userId,
                                    business._id,
                                    business.accountType,
                                    "free"
                                  );
                                }}>
                                Free
                              </button>
                            </li>
                            <li
                              className="pointer"
                              style={{
                                display: business.accountType === "security" ? "none" : "block"
                              }}>
                              <button
                                className="tg-dashboard-link"
                                onClick={e => {
                                  e.preventDefault();
                                  this.changeBusinessAccountType(
                                    userId,
                                    business._id,
                                    business.accountType,
                                    "security"
                                  );
                                }}>
                                Total Website Protection
                              </button>
                            </li>
                            <li
                              className="pointer"
                              style={{
                                display: business.accountType === "basic" ? "none" : "block"
                              }}>
                              <button
                                className="tg-dashboard-link"
                                onClick={e => {
                                  e.preventDefault();
                                  this.changeBusinessAccountType(
                                    userId,
                                    business._id,
                                    business.accountType,
                                    "basic"
                                  );
                                }}>
                                Ultimate Website Protection
                              </button>
                            </li>
                            <li
                              className="pointer"
                              style={{
                                display: business.accountType === "90-day-trial" ? "none" : "block"
                              }}>
                              <button
                                className="tg-dashboard-link"
                                onClick={e => {
                                  e.preventDefault();
                                  this.changeBusinessAccountType(
                                    userId,
                                    business._id,
                                    business.accountType,
                                    "90-day-trial"
                                  );
                                }}>
                                90 Day Trial
                              </button>
                            </li>
                            <li
                              className="pointer"
                              style={{
                                display: business.accountType === "60-day-trial" ? "none" : "block"
                              }}>
                              <button
                                className="tg-dashboard-link"
                                onClick={e => {
                                  e.preventDefault();
                                  this.changeBusinessAccountType(
                                    userId,
                                    business._id,
                                    business.accountType,
                                    "60-day-trial"
                                  );
                                }}>
                                60 Day Trial
                              </button>
                            </li>
                            <li
                              className="pointer"
                              style={{
                                display: business.accountType === "pci-malware" ? "none" : "block"
                              }}>
                              <button
                                className="tg-dashboard-link"
                                onClick={e => {
                                  e.preventDefault();
                                  this.changeBusinessAccountType(
                                    userId,
                                    business._id,
                                    business.accountType,
                                    "pci-malware"
                                  );
                                }}>
                                Pci Malware
                              </button>
                            </li>
                            <li
                              className="pointer"
                              style={{
                                display: business.accountType === "monthly-scan" ? "none" : "block"
                              }}>
                              <button
                                className="tg-dashboard-link"
                                onClick={e => {
                                  e.preventDefault();
                                  this.changeBusinessAccountType(
                                    userId,
                                    business._id,
                                    business.accountType,
                                    "monthly-scan"
                                  );
                                }}>
                                Monthly Scan
                              </button>
                            </li>
                            <li
                              className="pointer"
                              style={{
                                display:
                                  business.accountType === "vulnerability-scanning"
                                    ? "none"
                                    : "block"
                              }}>
                              <button
                                className="tg-dashboard-link"
                                onClick={e => {
                                  e.preventDefault();
                                  this.changeBusinessAccountType(
                                    userId,
                                    business._id,
                                    business.accountType,
                                    "vulnerability-scanning"
                                  );
                                }}>
                                Vulnerability Scanning
                              </button>
                            </li>
                            <li
                              className="pointer"
                              style={{
                                display:
                                  business.accountType === "security-with-dark-web"
                                    ? "none"
                                    : "block"
                              }}>
                              <button
                                className="tg-dashboard-link"
                                onClick={e => {
                                  e.preventDefault();
                                  this.changeBusinessAccountType(
                                    userId,
                                    business._id,
                                    business.accountType,
                                    "security-with-dark-web"
                                  );
                                }}>
                                PCI/Malware Scanning With Dark Web
                              </button>
                            </li>
                          </ul>
                        </div>
                      </td>
                      <td>{moment(business.createdAt).format("MMMM Do YYYY, h:mm:ss a")}</td>

                      <td>
                        <a href="/" onClick={e => e.preventDefault()} className="text-link">
                          {business.status === "active"
                            ? "Active"
                            : business.status === "invalid"
                              ? "Invalid"
                              : "Canceled"}
                        </a>
                      </td>
                      <td>
                        <ControlButtons businessId={business._id} />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="8">
                    <div className="alert alert-info">No records!</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <BusinessUsersModal />
      </>
    );
  }
}

AdvancedSearchTab.contextType = AppContext;

const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedSearchTab);
