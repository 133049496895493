import React from "react";
import Aux from "../../components/hoc/containerAux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../helpers/config";
import { setStorageItem } from "../../helpers/storage";
import { callApiGetCheckScanReportsPendingDomainData, callApiUpdateDomainData } from "../../services/index";
import ControlButtons from "./controlButtons";
import AppContext from "../../components/context/appContext";
import NoSealsReport from "./noSealsReport";
import SealsGeneratedReport from "./sealsGeneratedReport";
import RegenerateAllSeals from "./regenerateSealsReport";
import RunningSslScanReport from "./runningSslScanReport";
import InvalidSslScanReport from "./invalidSslScanReport";
import PendingSslScanReport from "./pendingSslScanReport";
import ActiveSslExpiresReport from "./activeSslExpiresReport";
import CanceledSitesSslScanReport from "./canceledSitesSslScanReport";
import VerifyingSslScanReport from "./verifyingSslScanReport";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class SslScanReports extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = { currentDomainId: null };
  }

  updateCurrentDomainId = (domainId) => {
    this.setState({
      currentDomainId: domainId,
    });
  };

  render() {
    const { currentDomainId } = this.state;

    return (
      <>
        <div style={{ margin: "15px" }}>
          <hr />
          {/* <ActiveSslExpiresReport
            updateCurrentDomainId={this.updateCurrentDomainId}
          />
          <PendingSslScanReport
            updateCurrentDomainId={this.updateCurrentDomainId}
          /> */}
          <RunningSslScanReport updateCurrentDomainId={this.updateCurrentDomainId} />
          <InvalidSslScanReport updateCurrentDomainId={this.updateCurrentDomainId} />
          <VerifyingSslScanReport updateCurrentDomainId={this.updateCurrentDomainId} />
          <CanceledSitesSslScanReport updateCurrentDomainId={this.updateCurrentDomainId} />

          <div id="domainRescanSslConfirmationModal" className="modal" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title f800">Rescan SSL Certificate</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="closeModal">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="col-lg-12 col-md-12 text-left p-0">
                    <p>
                      <strong>Rescan the ssl certificate?</strong>
                    </p>
                  </div>
                </div>
                <div className="modal-footer text-right">
                  <button type="button" className="btn btn-danger btn-140-width btn-md" data-dismiss="modal">
                    No
                  </button>
                  <button
                    type="button"
                    className="btn-success btn-md pull-right btn-140-width"
                    data-dismiss="modal"
                    onClick={async () => {
                      await callApiUpdateDomainData(currentDomainId);
                      toast.success("SSL Scan Started. Please check back in a few minutes.", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2500,
                      });
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

SslScanReports.contextType = AppContext;

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SslScanReports);
