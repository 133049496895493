import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import Aux from "../../../components/hoc/containerAux";
import PciSlidePanel from "./slidePanels/pciSlidePanel";
import DarkWebSlidePanel from "./slidePanels/darkWebSlidePanel";
import PciSlidePanelData from "./slidePanels/pciSlidePanelData";
import DarkWebSlidePanelData from "./slidePanels/darkWebSlidePanelData";
import MalwareSlidePanel from "./slidePanels/malwareSlidePanel";
import MalwareSlidePanelData from "./slidePanels/malwareSlidePanelData";
import SslSlidePanel from "./slidePanels/sslSlidePanel";
import SslSlidePanelData from "./slidePanels/sslSlidePanelData";
import SlideContext from "../../context/slidePanelContexts";
import ScanSetDetails from "./ScanSetDetails/scanSetDetails";
import ScanHistoryContext from "../../context/ScanHistoryContext";
import "./panels.css";
import {
  startImmediateScan,
  rescanDomainSsl,
  getBusinessDetailsById
} from "../../../modules/actions/index";

class SlidePanels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 3000);
  }

  render() {
    const { businessId, index, value } = this.props;
    const { isLoading } = this.state;
    return (
      <Aux>
        <SlideContext.drawerContext.Provider value={value}>
          <PciSlidePanel index={index} businessId={businessId} isLoading={isLoading}>
            <PciSlidePanelData index={index} businessId={businessId} />
          </PciSlidePanel>
        </SlideContext.drawerContext.Provider>

        <SlideContext.drawerContext.Provider value={value}>
          <DarkWebSlidePanel index={index} businessId={businessId} isLoading={isLoading}>
            <DarkWebSlidePanelData index={index} businessId={businessId} />
          </DarkWebSlidePanel>
        </SlideContext.drawerContext.Provider>

        <SlideContext.drawerContext.Provider value={value}>
          <MalwareSlidePanel index={index} businessId={businessId}>
            <MalwareSlidePanelData index={index} businessId={businessId} />
          </MalwareSlidePanel>
        </SlideContext.drawerContext.Provider>

        <SlideContext.drawerContext.Provider value={value}>
          <SslSlidePanel index={index} businessId={businessId}>
            <SslSlidePanelData
              businessId={businessId}
              goToPageGetAutoRenewalSSL={this.props.goToPageGetAutoRenewalSSL}
            />
          </SslSlidePanel>
        </SlideContext.drawerContext.Provider>

        <SlideContext.drawerContext.Provider value={value}>
          <ScanSetDetails
            businessDetails={value}
            businessId={businessId}
            index={index}
            goToPageInsurance={() => {}}
            isSuperAdmin={false}
          />
        </SlideContext.drawerContext.Provider>
      </Aux>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goToPageInsurance: () => push("/insurance"),
      goToPageTrustSeals: () => push("/trust-seals"),
      goToPageVulnerabilities: owner => push("/vulnerabilities/" + owner),
      goToPageMalwareScanning: () => push("/malware-scanning"),
      goToPageMalwareScannedFiles: () => push("/malware-scanned-files"),
      goToPageSSL: () => push("/ssl"),
      goToPageGetAutoRenewalSSL: domainId => push("/get-auto-renewing-ssl", domainId),
      startImmediateScan,
      rescanDomainSsl,
      getBusinessDetailsById
    },
    dispatch
  );
SlidePanels.contextType = ScanHistoryContext;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SlidePanels);
