import React, { useState, useEffect } from "react";
import ControlButtons from "../controlButtons";
import moment from "moment";
import _ from "lodash";
import { callApiUpdateBusinessData } from "../../../services";
import { convertTimeTo } from "../../../helpers/timeZone";

const FreeScans = ({
  index,
  isLoadingFreeScans,
  freeScanActive,
  freeScans,
  getFreeScans,
  goToDashboard,
}) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [scans, setScans] = useState(freeScans);
  useEffect(
    () => {
      // Update the state whenever freeScans changes
      setScans(freeScans);
    },
    [freeScans]
  );

  const markForDeletion = async () => {
    if (selectedValues.length > 0) {
      console.log("delete ids!", selectedValues);
      await callApiUpdateBusinessData(selectedValues[0], {
        purpose: "delete_free_account",
        businessIds: selectedValues,
      });

      const updatedStore = scans.filter(
        (item) => !selectedValues.includes(item._id)
      );
      setScans(updatedStore);
      setSelectedValues([]);
    }
  };
  const handleCheckboxChange = (value) => {
    setSelectedValues((prevValues) => {
      if (prevValues.includes(value)) {
        return prevValues.filter((item) => item !== value);
      } else {
        return [...prevValues, value];
      }
    });
  };

  return (
    <>
      <div className="reportButton">
        <button
          disabled={isLoadingFreeScans}
          className="btn-success btn-sm"
          onClick={() => getFreeScans()}
        >
          {isLoadingFreeScans ? "Loading..." : "FREE SCANS"}
        </button>
        <div className="clearfix" />
      </div>
      <p>
        Sites with check "<i className="fa fa-check" />" have an active clone
        account.
      </p>
      <hr />
      {freeScanActive && !isLoadingFreeScans && freeScans.length > 0 ? (
        <>
          <table className="tg table table-bordered table-hover">
            <thead>
              <tr>
                <th />
                <th>
                  {
                    <a
                      onClick={() => markForDeletion()}
                      style={{ cursor: "pointer" }}
                    >
                      <i class="fa fa-trash" />
                    </a>
                  }
                </th>
                <th>Device (ip/url)</th>
                <th>Frequency</th>
                <th>Last Complete Scan</th>
                <th># of Hrs from Last Scan</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {scans.map((scan, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <th>
                    <label>
                      <input
                        type="checkbox"
                        value={scan._id}
                        checked={selectedValues.includes(`${scan._id}`)}
                        onChange={() => handleCheckboxChange(`${scan._id}`)}
                      />
                    </label>
                  </th>
                  <td>
                    <span
                      className="a-pointer"
                      onClick={() => {
                        goToDashboard(scan._id);
                      }}
                    >
                      {scan.scanVendor.hasOwnProperty("clone") ? (
                        <i className="fa fa-check" />
                      ) : null}{" "}
                      {scan.businessName}{" "}
                    </span>
                  </td>
                  <td>{_.upperFirst(scan.businessFrequency)}</td>
                  <td>
                    {convertTimeTo(scan.dateLastScanned, scan.pciScanTimeZone)}{" "}
                  </td>
                  <td>
                    {moment()
                      .utc()
                      .diff(
                        moment(
                          convertTimeTo(
                            scan.dateLastScanned,
                            scan.pciScanTimeZone
                          )
                        ),
                        "hours"
                      )}{" "}
                    hr(s)
                  </td>
                  <td>
                    <ControlButtons businessId={scan._id} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}
    </>
  );
};

export default FreeScans;
