import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import moment from 'moment';
import Aux from '../../hoc/containerAux';
import MalwareTypeOfReport from './malwareTypeOfReport';
import { viewUserBusinessAccountDashboard } from '../../../helpers/general';
import config from '../../../helpers/config';
import { sendEmailForReport } from '../../../modules/actions/index';
import EmailTemplateReport from '../../../components/control-panel/emailTemplateReport';
import {
  callBusinessInfo,
  callGetDomains,
  callCanceledBusinesses,
  callMissingMalwareScans,
  callMalwarePatchBusiness,
  callRunningMalwareSites,
  callCanceledRunningMalwareSites,
  callRunningStuckSites,
  callMalwareSearchScansWithin24Hours,
  callActiveBusinesses,
  callFreeScanBusinesses,
  callFailedScanBusinesses,
  callMalwareSearchByBusinessId
} from '../../../services';

class MalwareReports extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      malwareInfoDataRunning: [],
      malwareInfoDataStuck: [],
      malwareInfoDataFinish: [],
      malwareInfoDataUndef: [],
      malwareInfoDataNeverScanned: [],
      malwareInfoDataCanceledSites: [],
      malwareInfoDataFreeSites: [],
      malwareInfoDataFailedSites: [],
      malwareMultipleSites: [],
      malwareMultipleSitesFinal: [],
      malwareMultipleSitesFinal2: [],
      missingScanBusinesses: [],
      running: 'none',
      stuck: 'none',
      multiple: 'none',
      canceled: 'none',
      missing: 'none',
      free: 'none',
      failed: 'none',
      displayRunning: 'none',
      displayStuck: 'none',
      displayFailed: 'none',
      displayMultiple: 'none',
      displayCanceled: 'none',
      displayMissing: 'none',
      displayFree: 'none',
      showMissing: false,
      showFailed: false,
      showStuck: false,
      showRunning: false,
      showMultiple: false,
      showCanceled: false,
      showFree: false,
      malwareReportTypes: ['stuck', 'running', 'missing', 'canceled', 'free', 'failed'],
      malwareReportTitle: {
        running: 'Running Scans',
        stuck: 'Stuck Scans',
        missing: 'Missing Scans',
        canceled: 'Canceled Sites',
        free: 'Free Sites',
        failed: 'Failed Scans'
      },
      malwareReportSubtitle: {
        running: 'Scans currently running.',
        stuck: 'Scans that have been stuck for 8 hours',
        missing: 'Sites that have not been scanned within their scheduled time.',
        canceled: 'Canceled sites that have been scanned within the last 30 days.',
        free: 'Free scans that have been scanned more than once within the last 30 days.',
        failed: 'Failed scans that are supicious, potentially suspicious and malicious'
      },
      selectedReportBusinessIdMalware: null
    };
  }

  async malwareMultipleSites() {
    let domainsMultiple = await callMalwareSearchScansWithin24Hours();
    let malwareMultipleSites = [];

    if (domainsMultiple.total > 0) {
      let domainsData = domainsMultiple.data;
      for (let j = 0; j < domainsData.length; j++) {
        let domainsInfo = { _id: domainsData[j]._id };
        domainsInfo['businessId'] = domainsData[j].businessId;
        malwareMultipleSites.push(domainsInfo);
      }

      if (this._isMounted) {
        this.setState({
          malwareMultipleSites
        });
      }
    }

    let malwareMultipleSitesFinal = [];
    for (let x = 0; x < this.state.malwareMultipleSites.length; x++) {
      let businessId = this.state.malwareMultipleSites[x].businessId;
      let ctr = 0;
      for (let y = 0; y < this.state.malwareMultipleSites.length; y++) {
        if (businessId === this.state.malwareMultipleSites[y].businessId) {
          ctr++;
        }
      }
      if (ctr > 1) {
        let businessInfo = { _id: businessId };
        businessInfo['businessId'] = businessId;
        malwareMultipleSitesFinal.push(businessInfo);
      }
    }
    let uniqueBusiness = [...new Set(malwareMultipleSitesFinal.map(i => i.businessId))];

    let malwareMultipleSitesFinal2 = [];

    for (let a = 0; a < uniqueBusiness.length; a++) {
      let ctr = 1;
      let business2 = await callBusinessInfo(uniqueBusiness[a]);

      if (business2 !== undefined) {
        ctr = ctr + a;
        let businessInfo = { _id: uniqueBusiness[a] };
        businessInfo['businessId'] = uniqueBusiness[a];
        businessInfo['host'] = business2.primaryDomain;
        businessInfo['frequency'] = business2.malwareFrequency;
        businessInfo['lastMalwareScan'] = business2.lastMalwareScan;
        businessInfo['malwareScanStatus'] = business2.currentMalwareScanResult;
        businessInfo['number'] = ctr;
        malwareMultipleSitesFinal2.push(businessInfo);
      }
    }
    if (this._isMounted) {
      this.setState({
        malwareMultipleSitesFinal2
      });
    }
  }

  async malwareCanceledSites() {
    let business = await callCanceledRunningMalwareSites();

    let malwareInfoDataCanceledSites = [];

    if (business.data.length > 0) {
      let businessData = business.data;
      for (let j = 0; j < businessData.length; j++) {
        let businessInfo = { _id: businessData[j]._id };
        let number = 1;
        number = number + j;
        businessInfo['number'] = number;
        businessInfo['businessId'] = businessData[j]._id;
        businessInfo['host'] = businessData[j].primaryDomain;
        businessInfo['canceledAt'] = businessData[j].canceledAt;
        businessInfo['frequency'] = businessData[j].malwareFrequency;
        businessInfo['lastMalwareScan'] = businessData[j].lastMalwareScan;
        businessInfo['malwareScanStatus'] = businessData[j].currentMalwareScanResult;
        malwareInfoDataCanceledSites.push(businessInfo);
      }

      this.setState({
        malwareInfoDataCanceledSites
      });
    }
  }

  async malwareFreeSites() {
    let business = await callFreeScanBusinesses();
    let malwareInfoDataFreeSites = [];

    if (business.total > 0) {
      let businessData = business.data;
      for (let j = 0; j < businessData.length; j++) {
        let businessInfo = { _id: businessData[j]._id };
        let number = 1;

        businessInfo['number'] = number;
        businessInfo['businessId'] = businessData[j]._id;
        businessInfo['host'] = businessData[j].primaryDomain;
        businessInfo['frequency'] = businessData[j].malwareFrequency;
        businessInfo['lastMalwareScan'] = businessData[j].lastMalwareScan;
        businessInfo['malwareScanStatus'] = businessData[j].currentMalwareScanResult;

        let malwareHistory = await callMalwareSearchByBusinessId(businessData[j]._id);

        if (malwareHistory.data.length >= 2) {
          malwareInfoDataFreeSites.push(businessInfo);
          number = number + j;
        }
      }

      if (this._isMounted) {
        this.setState({
          malwareInfoDataFreeSites
        });
      }
    }
  }

  async malwareMissinggScans() {
    let business = await callMissingMalwareScans();

    let missingScanBusinesses = [];
    if (business.data.length > 0) {
      let businessData = business.data;
      for (let j = 0; j < businessData.length; j++) {
        missingScanBusinesses.push(businessData[j]);
      }

      this.setState({
        missingScanBusinesses
      });
    }
  }

  async malwareOtherReports() {
    let domains = await callGetDomains();
    let malwareInfoDataRunning = [];
    let malwareInfoDataUndef = [];
    let malwareInfoDataFinish = [];
    let malwareInfoDataNeverScanned = [];

    if (domains.total > 0) {
      let domainData = domains.data;
      for (let j = 0; j < domainData.length; j++) {
        let malInfo = { _id: domainData[j]._id };
        let number = 0;
        let frequency = '';
        let lastMalwareScan = '';
        if (domainData[j] !== undefined || domainData[j] !== null) {
          const businessInfoData = await callBusinessInfo(domainData[j].businessId);
          if (businessInfoData !== undefined) {
            frequency = businessInfoData.malwareFrequency;
            lastMalwareScan = businessInfoData.lastMalwareScan;
          }
        }

        number++;
        malInfo['number'] = number;
        malInfo['domainId'] = domainData[j]._id;
        malInfo['host'] = domainData[j].host;
        malInfo['frequency'] = frequency;
        malInfo['lastMalwareScan'] = lastMalwareScan;
        malInfo['malwareScanStatus'] = domainData[j].malwareScanStatus;

        if (domainData[j].malwareScanStatus === 'running') {
          malwareInfoDataRunning.push(malInfo);
        } else if (domainData[j].malwareScanStatus === null) {
          malwareInfoDataNeverScanned.push(malInfo);
        } else {
          malwareInfoDataFinish.push(malInfo);
        }
      }

      if (this._isMounted) {
        this.setState({
          malwareInfoDataNeverScanned,
          malwareInfoDataFinish,
          malwareInfoDataUndef
        });
      }
    }
  }

  async malwareFailedSites() {
    const failedScans = await callFailedScanBusinesses();
    const today = moment(new Date());
    const failedBusinesses = [];

    if (failedScans.data.length > 0) {
      const businesses = failedScans.data;

      for (let x = 0; x < businesses.length; x++) {
        const business = businesses[x];

        const diff = today.diff(business.nextMalwareScan, 'hours');
        failedBusinesses.push({
          businessId: business.businessId,
          host: business.primaryDomain,
          frequency: business.malwareFrequency,
          lastMalwareScan: business.lastMalwareScan,
          malwareScanStatus: business.lastMalwareScanResult,
          diffHours: diff
        });
      }

      this.setState({ malwareInfoDataFailedSites: failedBusinesses });
    }
  }

  async malwareRunningSites() {
    const runningScans = await callRunningMalwareSites();
    const today = moment(new Date());
    const runningDomains = [];
    if (runningScans.data !== null) {
      if (runningScans.data.length > 0) {
        for (let x = 0; x < runningScans.data.length; x++) {
          const business = runningScans.data[x];
          const diff = today.diff(business.nextMalwareScan, 'hours');

          runningDomains.push({
            businessId: business.businessId,
            host: business.primaryDomain,
            frequency: business.malwareFrequency,
            lastMalwareScan: business.lastMalwareScan,
            malwareScanStatus: business.lastMalwareScanResult,
            diffHours: diff
          });
        }

        this.setState({ malwareInfoDataRunning: runningDomains });
      }
    }
  }

  async malwareStuckSites() {
    const runningScans = await callRunningStuckSites();
    const today = moment(new Date());
    const runningDomains = [];
    if (runningScans !== null) {
      if (runningScans.length > 0) {
        for (let x = 0; x < runningScans.length; x++) {
          const business = runningScans[x];
          const diff = today.diff(business.nextMalwareScan, 'hours');
          runningDomains.push({
            businessId: business.businessId,
            host: business.primaryDomain,
            frequency: business.frequency,
            lastMalwareScan: business.lastMalwareScan,
            malwareScanStatus: business.scanStatus,
            diffHours: diff
          });
        }

        this.setState({ malwareInfoDataStuck: runningDomains });
      }
    }
  }

  viewUserAccountDashboard = businessId => {
    viewUserBusinessAccountDashboard(businessId);
    window.open(config.urls.dashboardUrl, '_blank');
  };

  componentDidMount() {
    /* this._isMounted = true;
    this.malwareMultipleSites();
    this.malwareOtherReports();
    */
    this.malwareMissinggScans();
    this.malwareFreeSites();
    this.malwareFailedSites();
    this.malwareCanceledSites();
    this.malwareRunningSites();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  showReportToggle(x) {
    this.state[x] === 'none'
      ? this.setState({
          [x]: 'table'
        })
      : this.setState({
          [x]: 'none'
        });
  }

  showTypeOfReport(x, i) {
    return (
      <div
        className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad"
        key={i + Math.random()}>
        <div className=" tab-content">
          <div className="tab-pane fade in active reportsTab">
            <div className="reportButton">
              <button className="btn-success btn-sm" onClick={this.showReportToggle.bind(this, x)}>
                {this.state.malwareReportTitle[x]}
              </button>
              <div className="clearfix" />
            </div>
            <p>{this.state.malwareReportSubtitle[x]}</p>
            <hr />
            <table
              style={{ display: this.state[x] }}
              className="tg table table-bordered table-hover">
              <thead>
                {x === 'vulnerabilities' ? (
                  <tr>
                    <th />
                    <th>Vulnerabilitiy Name</th>
                    <th>Vulnerability ID</th>
                    <th>Status</th>

                    <th>Date</th>
                  </tr>
                ) : (
                  <tr>
                    <th />
                    <th>Domain/Subdomains/IPs</th>
                    <th>Frequency</th>
                    <th> {x == 'canceled' ? 'Date Canceled' : 'Date'} </th>
                    <th>Last Scan</th>
                    {x !== 'canceled' && x !== 'missing' ? (
                      <th>
                        {x === 'missing'
                          ? '# of Hrs from Last Scan'
                          : x === 'running'
                            ? 'Previous Status'
                            : '	Status'}
                      </th>
                    ) : null}

                    <th>{x !== 'missing' ? 'Email Client' : 'Action'}</th>
                    <th />
                  </tr>
                )}
              </thead>
              <tbody>
                {x === 'running' ? (
                  this.state.malwareInfoDataRunning.length > 0 ? (
                    this.state.malwareInfoDataRunning.map((v, i) => {
                      return (
                        <MalwareTypeOfReport
                          key={Math.random()}
                          number={i + 1}
                          host={v.host}
                          frequency={v.frequency}
                          malwareScanStatus={v.malwareScanStatus}
                          lastMalwareScan={v.lastMalwareScan}
                          malwareReportType={x}
                          setMalwareSelectedBusinessId={this.setMalwareSelectedBusinessId}
                          businessId={v._id}
                          viewUserAccountDashboard={() =>
                            this.viewUserAccountDashboard(v.businessId)
                          }
                        />
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="6">
                        <div className="alert alert-info">No results</div>
                      </td>
                    </tr>
                  )
                ) : x === 'missing' ? (
                  this.state.missingScanBusinesses.map.length > 0 ? (
                    this.state.missingScanBusinesses.map((v, i) => {
                      return (
                        <MalwareTypeOfReport
                          key={Math.random()}
                          number={i + 1}
                          host={v.primaryDomain}
                          frequency={v.malwareFrequency}
                          malwareScanStatus={v.malwareScanStatus}
                          malwareStatusReport={v.malwareStatusReport}
                          lastMalwareScan={v.lastMalwareScan}
                          diffHours={v.diffHours}
                          malwareReportType={x}
                          setMalwareSelectedBusinessId={this.setUpdateMalwareBySelectedBusinessId}
                          businessId={v._id}
                          viewUserAccountDashboard={() =>
                            this.viewUserAccountDashboard(v.businessId)
                          }
                        />
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="6">
                        <div className="alert alert-info">No results</div>
                      </td>
                    </tr>
                  )
                ) : x === 'multiple' ? (
                  this.state.malwareMultipleSitesFinal2.length > 0 ? (
                    this.state.malwareMultipleSitesFinal2.map((v, i) => {
                      return (
                        <MalwareTypeOfReport
                          key={Math.random()}
                          number={i + 1}
                          host={v.host}
                          frequency={v.frequency}
                          malwareScanStatus={v.malwareScanStatus}
                          lastMalwareScan={v.lastMalwareScan}
                          malwareReportType={x}
                          setMalwareSelectedBusinessId={this.setMalwareSelectedBusinessId}
                          businessId={v._id}
                          viewUserAccountDashboard={() =>
                            this.viewUserAccountDashboard(v.businessId)
                          }
                        />
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="6">
                        <div className="alert alert-info">No results</div>
                      </td>
                    </tr>
                  )
                ) : x === 'canceled' ? (
                  this.state.malwareInfoDataCanceledSites.length > 0 ? (
                    this.state.malwareInfoDataCanceledSites.map((v, i) => {
                      return (
                        <MalwareTypeOfReport
                          key={Math.random()}
                          isCanceled={true}
                          number={i.number}
                          host={v.host}
                          frequency={v.frequency}
                          malwareScanStatus={v.malwareScanStatus}
                          lastMalwareScan={v.lastMalwareScan}
                          canceledDate={v.canceledAt}
                          malwareReportType={x}
                          setMalwareSelectedBusinessId={this.setMalwareSelectedBusinessId}
                          businessId={v._id}
                          viewUserAccountDashboard={() =>
                            this.viewUserAccountDashboard(v.businessId)
                          }
                        />
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="6">
                        <div className="alert alert-info">No results</div>
                      </td>
                    </tr>
                  )
                ) : x === 'free' ? (
                  this.state.malwareInfoDataFreeSites.length > 0 ? (
                    this.state.malwareInfoDataFreeSites.map((v, i) => {
                      return (
                        <MalwareTypeOfReport
                          key={Math.random()}
                          number={i + 1}
                          host={v.host}
                          frequency={v.frequency}
                          malwareScanStatus={v.malwareScanStatus}
                          lastMalwareScan={v.lastMalwareScan}
                          malwareReportType={x}
                          setMalwareSelectedBusinessId={this.setMalwareSelectedBusinessId}
                          businessId={v._id}
                          viewUserAccountDashboard={() =>
                            this.viewUserAccountDashboard(v.businessId)
                          }
                        />
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="6">
                        <div className="alert alert-info">No results</div>
                      </td>
                    </tr>
                  )
                ) : x === 'failed' ? (
                  this.state.malwareInfoDataFailedSites.length > 0 ? (
                    this.state.malwareInfoDataFailedSites.map((v, i) => {
                      return (
                        <MalwareTypeOfReport
                          key={Math.random()}
                          number={i + 1}
                          host={v.host}
                          frequency={v.frequency}
                          malwareScanStatus={v.malwareScanStatus}
                          lastMalwareScan={v.lastMalwareScan}
                          malwareReportType={x}
                          setMalwareSelectedBusinessId={this.setMalwareSelectedBusinessId}
                          businessId={v.businessId}
                          viewUserAccountDashboard={() =>
                            this.viewUserAccountDashboard(v.businessId)
                          }
                        />
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="6">
                        <div className="alert alert-info">No results</div>
                      </td>
                    </tr>
                  )
                ) : x === 'stuck' ? (
                  this.state.malwareInfoDataRunning.length > 0 ? (
                    this.state.malwareInfoDataRunning.map((v, i) => {
                      return (
                        <MalwareTypeOfReport
                          key={Math.random()}
                          number={i + 1}
                          host={v.host}
                          frequency={v.frequency}
                          malwareScanStatus={v.malwareScanStatus}
                          lastMalwareScan={v.lastMalwareScan}
                          malwareReportType={x}
                          setMalwareSelectedBusinessId={this.setMalwareSelectedBusinessId}
                          businessId={v._id}
                          viewUserAccountDashboard={() =>
                            this.viewUserAccountDashboard(v.businessId)
                          }
                        />
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="6">
                        <div className="alert alert-info">No results</div>
                      </td>
                    </tr>
                  )
                ) : (
                  ''
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  sendMalwareReport = data => {
    data.businessId = this.state.selectedReportBusinessIdMalware;
    this.props.sendEmailForReport(data);
  };

  setMalwareSelectedBusinessId = businessId => {
    this.setState({
      selectedReportBusinessIdMalware: businessId
    });
  };

  setUpdateMalwareBySelectedBusinessId = async businessId => {
    this.setState({
      selectedReportBusinessIdMalware: businessId
    });

    const missingMalwareScans = [...this.state.missingScanBusinesses];
    const updatedMalwareScans = this.state.missingScanBusinesses.filter(
      business => business.businessId !== businessId
    );
    this.setState({ missingScanBusinesses: updatedMalwareScans });

    await callMalwarePatchBusiness(businessId, {
      malwareStatusReport: 'rescan',
      scanAttempt: 0
    });
  };

  render() {
    const { malwareReportTypes } = this.state;

    return (
      <Aux>
        {malwareReportTypes.map((v, i) => {
          return this.showTypeOfReport(v, i);
        })}

        {malwareReportTypes.map((type, index) => {
          return (
            <div key={index} id={type + 'Malware'} className="modal" tabIndex="-1" role="dialog">
              <EmailTemplateReport sendSslReport={this.sendMalwareReport} />
            </div>
          );
        })}
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      sendEmailForReport,
      goToDashboard: () =>
        push('/', {
          accountAdminView: true
        })
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MalwareReports);
