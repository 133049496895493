import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  callApiGetUserBusinessData,
  callApiGetBusinessData,
  callApiUpdateBusinessData,
  callApiCreateBusinessData,
  callApiCreateBusinessUserData,
  callApiDeleteBusinessUser,
  callApiGetBusinessUsersList,
  callApiCreateBusinessNotes,
  callApiCancelOrActivateBusiness,
  callApiDeleteFreeCloneBusinessData
} from '../../services/index';
import {
  GET_USER_BUSINESS_DATA_SUCCESS,
  GET_USER_BUSINESS_DATA_FAILURE,
  GET_BUSINESS_DATA_SUCCESS,
  GET_BUSINESS_DATA_FAILURE,
  UPDATE_BUSINESS_DATA_SUCCESS,
  UPDATE_BUSINESS_DATA_FAILURE,
  CREATE_BUSINESS_DATA_SUCCESS,
  CREATE_BUSINESS_DATA_FAILURE,
  CREATE_BUSINESS_USER_DATA_SUCCESS,
  CREATE_BUSINESS_USER_DATA_FAILURE,
  DELETE_BUSINESS_USER_DATA_SUCCESS,
  DELETE_BUSINESS_USER_DATA_FAILURE,
  CREATE_BUSINESS_NOTES_SUCCESS,
  CREATE_BUSINESS_NOTES_FAILURE
} from '../types/index';

export const getUserBusinessData = data => {
  return async dispatch => {
    return callApiGetUserBusinessData(data)
      .then(data => {
        dispatch({
          type: GET_USER_BUSINESS_DATA_SUCCESS,
          payload: data
        });
      })
      .catch(err => {
        dispatch({
          type: GET_USER_BUSINESS_DATA_FAILURE
        });
      });
  };
};

export const getBusinessData = businessId => {
  return async dispatch => {
    return callApiGetBusinessData(businessId)
      .then(data => {
        dispatch({
          type: GET_BUSINESS_DATA_SUCCESS,
          payload: data
        });
      })
      .catch(err => {
        dispatch({
          type: GET_BUSINESS_DATA_FAILURE
        });
      });
  };
};

export const updateBusinessData = (businessId, data) => {
  return async dispatch => {
    return callApiUpdateBusinessData(businessId, data)
      .then(resData => {
        dispatch({
          type: UPDATE_BUSINESS_DATA_SUCCESS,
          payload: resData
        });

        if (resData.success && resData.message != '') {
          toast.success(resData.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500
          });
        } else {
          toast.error(resData.errors, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500
          });
        }
      })
      .catch(err => {
        dispatch({
          type: UPDATE_BUSINESS_DATA_FAILURE
        });

        toast.error(err.errors, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2500
        });
      });
  };
};

export const createBusinessData = data => {
  return async dispatch => {
    return callApiCreateBusinessData(data)
      .then(resData => {
        dispatch({
          type: CREATE_BUSINESS_DATA_SUCCESS,
          payload: resData
        });

        if (resData.success && resData.message != '') {
          //console.log(resData);
          toast.success('Account created successfully', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500
          });
        } else if (resData && resData.errors !== '') {
          toast.error(resData.errors, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500
          });
        }

        return resData;
      })
      .catch(err => {
        dispatch({
          type: CREATE_BUSINESS_DATA_FAILURE
        });

        // console.log('err.errors', err.errors);
        if (err.errors && err.errors.length > 0) {
          err.errors.map((v, i) => {
            let errMessage = '';
            let fieldName =
              Object.keys(v)[0]
                .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
                .toLowerCase() + '\n';

            let fieldValue = Object.values(v)[0];

            toast.error(
              errMessage +
                fieldName.charAt(0).toUpperCase() +
                fieldName.slice(1) +
                ' - ' +
                fieldValue,
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2500
              }
            );
          });
        }
      });
  };
};

export const createBusinessUserData = (businessId, data) => {
  return async dispatch => {
    return callApiCreateBusinessUserData(businessId, data)
      .then(resData => {
        if (resData.success && resData.message != '') {
          toast.success(resData.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500
          });
        } else if (resData && resData.errors !== '') {
          toast.error(resData.errors, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500
          });
        }

        return callApiGetBusinessUsersList({ businessId: businessId }).then(inResData => {
          dispatch({
            type: CREATE_BUSINESS_USER_DATA_SUCCESS,
            payload: inResData
          });
        });
      })
      .catch(err => {
        dispatch({
          type: CREATE_BUSINESS_USER_DATA_FAILURE
        });

        toast.error(err.errors, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2500
        });
      });
  };
};

export const deleteBusinessUserData = (userId, businessId) => {
  return async dispatch => {
    return callApiDeleteBusinessUser(userId, businessId)
      .then(resData => {
        dispatch({
          type: DELETE_BUSINESS_USER_DATA_SUCCESS,
          payload: resData
        });

        if (resData.success && resData.message != '') {
          toast.success(resData.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500
          });
        } else if (resData && resData.errors !== '') {
          toast.error(resData.errors, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500
          });
        }
      })
      .catch(err => {
        dispatch({
          type: DELETE_BUSINESS_USER_DATA_FAILURE
        });

        toast.error(err.errors, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2500
        });
      });
  };
};

export const createBusinessNotes = payload => {
  return async dispatch => {
    return callApiCreateBusinessNotes(payload)
      .then(resData => {
        dispatch({
          type: CREATE_BUSINESS_NOTES_SUCCESS,
          payload: resData
        });

        if (resData.success && resData.message != '') {
          toast.success(resData.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500
          });
        } else if (resData && resData.errors !== '') {
          toast.error(resData.errors, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500
          });
        }
      })
      .catch(err => {
        dispatch({
          type: CREATE_BUSINESS_NOTES_FAILURE
        });

        toast.error(err.errors, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2500
        });
      });
  };
};

export const cancelOrActivateBusiness = (id, userId, currentBusinessStatus) => {
  return async dispatch => {
    return callApiGetBusinessData(id).then(async business => {
      // console.log('business', business);
      let pendingStatusLog = business.statusLog.filter(status => {
        return status.currentStatus === 'pending';
      });

      console.log('pendingStatusLog', pendingStatusLog);
      let statusLog = business.statusLog,
        currentStatus = null,
        newStatus = null,
        updateStatusLog = false,
        toastMessage = null;

      if (
        business.statusLog.length > 0 &&
        pendingStatusLog.length > 0 &&
        pendingStatusLog.newStatus !== business.status
      ) {
        toastMessage = 'Account scheduled for cancelation. Please check back in a few minutes.';
      } else {
        updateStatusLog = true;
        currentStatus = 'pending';

        switch (currentBusinessStatus) {
          case 'active':
            newStatus = 'canceled';
            break;

          case 'canceled':
            newStatus = 'active';
            break;

          default:
            break;
        }
      }

      if (updateStatusLog) {
        statusLog.push({
          currentStatus: currentStatus,
          newStatus: newStatus,
          userId: userId,
          notes: '',
          actionDate: new Date(),
          date: new Date()
        });

        const businessStatus = {
          statusLog: statusLog
        };

        return callApiCancelOrActivateBusiness(id, businessStatus).then(data => {
          toast.success(
            'Account scheduled for cancellation/activation. Please check back in a few minutes.',
            {
              position: toast.POSITION.TOP_RIGHT
            }
          );
        });
      } else {
        toast.info(toastMessage, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    });
  };
};

export const deleteFreeCloneAccount = id => {
  return async dispatch => {
    return callApiDeleteFreeCloneBusinessData(id).then(business => {
      console.log('account deleted!');
    });
  };
};

export const cancelCloneAccount = id => {
  return callApiDeleteFreeCloneBusinessData(id).then(business => {
    console.log('account deleted!');
  });
};

export const cancelOrActivateSkippedActionByMember = (businessId, userId) => {
  return async dispatch => {
    return callApiGetBusinessData(businessId).then(business => {
      let statusLogData = business.statusLog;

      if (statusLogData && statusLogData.length > 0) {
        statusLogData.filter(eachRow => {
          if (eachRow.currentStatus === 'pending') {
            eachRow.currentStatus = 'completed';
            eachRow.skippedMemberId = userId;
          }

          return true;
        });

        return callApiCancelOrActivateBusiness(businessId, statusLogData)
          .then(business => {
            toast.success('Action skipped successfully.', {
              position: toast.POSITION.TOP_RIGHT
            });
          })
          .catch(err => {
            toast.error('Action skipped was unsuccessfull.', {
              position: toast.POSITION.TOP_RIGHT
            });
          });
      }
    });
  };
};
