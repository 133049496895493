import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import moment from "moment";
import "./reports.css";
import config from "../../helpers/config";
import { getStorageItem } from "../../helpers/storage";
import { viewUserBusinessAccountDashboard } from "../../helpers/general";
import Aux from "../../components/hoc/containerAux";
import EmailTemplateReport from "../../components/control-panel/emailTemplateReport";
import ControlButtons from "../../components/control-panel/controlButtons";
import ReportTab from "./_partials/reportTab";
import {
  callOnGetBusinessReport,
  callOnGetReportCancelledSites,
  callOnGetResultMissingScans,
  callOnGetNonExistentScans,
} from "../../services/reportService";
import { getBusinesses, sendEmailForReport } from "../../modules/actions/index";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class PCIReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: null,
      reportTypes: [
        "failScan",
        "missingScan",
        "runningScan",
        "runningStuck",
        "freeScan",
        "cancelledScan",
        "falsePositives",
        "nonExistentScans",
        "vulnerabilities",
        "scanSchedules",
      ],
      showReport: {
        failScan: false,
        missingScan: false,
        runningScan: false,
        runningStuck: false,
        cancelledScan: false,
        falsePositives: false,
        nonExistentScans: false,
        vulnerabilities: false,
        scanSchedules: false,
      },
      data: {
        failScan: [],
        missingScan: [],
        runningScan: [],
        runningStuck: [],
        cancelledScan: [],
        falsePositives: [],
        nonExistentScans: [],
        vulnerabilities: [],
        scanSchedules: [],
      },
      isAdmin: null,
      timeZone: null,
      toggledIndex: 0,
      selectedReportBusinessIdPci: null,
      show90daysTialAccounts: false,
      businesses: [],
    };
  }

  async componentDidMount() {
    let userData = getStorageItem("userData");
    if (userData) {
      let userString = cryptr.decrypt(userData);
      let user = JSON.parse(userString);
      const timeZone = user.timezone ? user.timezone : null;
      this.setState({
        isAdmin: user.isAdmin,
        timeZone: timeZone,
        userId: user._id,
      });

      let businessQuery = {
        action: "get-businesses-as-per-type",
        searchKeyword: "90-day-trial",
        limit: 25,
        status: "active",
      };

      this.props.getBusinesses(businessQuery);
    }
  }

  componentDidUpdate() {
    if (!this.state.isAdmin) {
      this.props.history.replace("/");
    }
  }

  showReportHandler = async (reportType, index) => {
    const showReport = { ...this.state.showReport };
    const data = { ...this.state.data };
    let reportData = null;
    showReport[reportType] = !this.state.showReport[reportType];
    let params = null;
    switch (reportType) {
      case "failScan":
        params = "fail";
        break;
      case "missingScan":
        params = "missing";
        break;
      case "cancelledScan":
        params = "cancel";
        break;
      case "freeScan":
        params = "freescans";
        break;
      case "falsePositives":
        params = "falsepositives";
        break;
      case "runningStuck":
        params = "runningstuck";
        break;
      case "runningScan":
        params = "running";
        break;
      case "nonExistentScans":
        params = "nonexistentscans";
        break;
      case "vulnerabilities":
        params = "vulnerabilities";
        break;
      case "scanSchedules":
        params = "scanSchedules";
        break;

      default:
        params = "running";
        break;
    }
    if (params === "nonexistentscans") {
      reportData = await callOnGetNonExistentScans();
      data[reportType] = reportData;
    } else {
      reportData = await callOnGetBusinessReport(params);
      data[reportType] = reportData.data;
    }

    this.setState({
      showReport,
      toggledIndex: index,
      data,
    });
  };

  processMissingScans = async (params) => {
    await callOnGetResultMissingScans(params);
    viewUserBusinessAccountDashboard(params.businessID);
    this.props.goToDashboard();
  };

  viewUserAccountDashboard = (e, businessId) => {
    viewUserBusinessAccountDashboard(businessId);
    window.open(config.urls.dashboardUrl, "_blank");
  };

  showReportTypes = () => {
    return this.state.reportTypes.map((type, index) => {
      let reportName = null;
      let buttonAction = null;
      let description = null;
      switch (type) {
        case "failScan":
          reportName = "Failed Scans";
          buttonAction = this.state.showReport.failScan
            ? "Hide Report"
            : "Show Report";
          description = "Sites that have been scanned with failed results";
          break;
        case "missingScan":
          reportName = "Missing Scans";
          buttonAction = this.state.showReport.missingScan
            ? "Hide Report"
            : "Show Report";
          description =
            "Sites that have not been scanned with their scheduled time";
          break;
        case "runningScan":
          reportName = "Running Scans";
          buttonAction = this.state.showReport.runningScan
            ? "Hide Report"
            : "Show Report";
          description = "Scans currently running";
          break;
        case "runningStuck":
          reportName = "Stuck Scans";
          buttonAction = this.state.showReport.runningStuck
            ? "Hide Report"
            : "Show Report";
          description = "Scans currently running for more than 8 hours";
          break;
        case "freeScan":
          reportName = "Free Scans";
          buttonAction = this.state.showReport.freeScan
            ? "Hide Report"
            : "Show Report";
          description =
            "Free sites that have been scanned more than one time within the last 30 days";
          break;
        case "falsePositives":
          reportName = "False Positives";
          buttonAction = this.state.showReport.falsePositives
            ? "Hide Report"
            : "Show Report";
          description = "Submitted false positives";
          break;
        case "nonExistentScans":
          reportName = "Test Scans";
          buttonAction = this.state.showReport.nonExistentScans
            ? "Hide Report"
            : "Show Report";
          description = "Scans that do not exists on live server";
          break;
        case "vulnerabilities":
          reportName = "Vulnerabilities";
          buttonAction = this.state.showReport.vulnerabilities
            ? "Hide Report"
            : "Show Report";
          description = "Vulnerabilities found on PCI scans";
          break;
        case "scanSchedules":
          reportName = "Duplicate Schedules";
          buttonAction = this.state.showReport.vulnerabilities
            ? "Hide Report"
            : "Show Report";
          description = "Report for duplicate scan time and time zone";
          break;
        case "cancelledScan":
          reportName = "Canceled Scans";
          buttonAction = this.state.showReport.cancelledScan
            ? "Hide Report"
            : "Show Report";
          description =
            "Canceled sites that have been scanned within the last 30 days";
          break;
        default:
          break;
      }

      return (
        <ReportTab
          key={type + "-" + index}
          type={type}
          index={index}
          toggledIndex={this.state.toggledIndex}
          reportName={reportName}
          description={description}
          toggle={this.showReportHandler}
          action={buttonAction}
          state={{
            "0": this.state.showReport.failScan,
            "1": this.state.showReport.missingScan,
            "2": this.state.showReport.runningScan,
            "3": this.state.showReport.runningStuck,
            "4": this.state.showReport.freeScan,
            "5": this.state.showReport.cancelledScan,
            "6": this.state.showReport.falsePositives,
            "7": this.state.showReport.nonExistentScans,
            "8": this.state.showReport.vulnerabilities,
            "9": this.state.showReport.scanSchedules,
          }}
          data={this.state.data}
          userId={this.state.userId}
          timezone={this.state.timeZone}
          viewUserAccountDashboard={this.viewUserAccountDashboard}
          processMissingScans={this.processMissingScans}
          setSelectedBusinessId={this.setSelectedBusinessId}
          history={this.props.history}
          getBusinessDetails={this.props.getBusinessDetails}
          populateDelayModalData={this.props.populateDelayModalData}
          populateBusinessUsersData={this.props.populateBusinessUsersData}
        />
      );
    });
  };

  setSelectedBusinessId = (businessId) => {
    this.setState({
      selectedReportBusinessIdPci: businessId,
    });
  };

  sendPCIReport = (data) => {
    data.businessId = this.state.selectedReportBusinessIdPci;
    this.props.sendEmailForReport(data);
  };

  componentWillReceiveProps(newProps) {
    if (newProps.businesses) {
      this.setState({
        businesses: newProps.businesses,
      });
    }
  }

  render() {
    const { reportTypes, show90daysTialAccounts, businesses } = this.state;
    const {
      userId,
      populateDelayModalData,
      populateBusinessUsersData,
    } = this.props;

    return (
      <Aux>
        <div id="mcontent-wrapper">
          <div id="reports-failed-scan" className="">
            {this.showReportTypes()}

            <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
              <div className="reportButton">
                <button
                  className="btn-success btn-sm btn-trial-account"
                  onClick={() => {
                    this.setState({
                      show90daysTialAccounts: !show90daysTialAccounts,
                    });
                  }}
                >
                  90 Days Trial Accounts
                </button>
                <div className="clearfix" />
              </div>
              <p>Sites that have created as 90 Days Trial Accounts</p>
              <hr />

              {businesses && businesses.length > 0 ? (
                <table
                  className="tg table table-bordered table-hover"
                  style={{
                    display: show90daysTialAccounts ? "table" : "none",
                  }}
                >
                  <thead>
                    <tr style={{ fontWeight: "bold" }}>
                      <td>Domain/IP</td>
                      <td>Business</td>
                      <td>Package Type</td>
                      <td>Created</td>
                      <td>Status</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody className="scan-status-list">
                    {businesses.map((v, i) => {
                      return (
                        <tr key={i}>
                          <td>{v.primaryDomain}</td>
                          <td>
                            {v.businessName}
                            <br />
                            {v._id}
                          </td>
                          <td>{v.accountType}</td>
                          <td>{moment(v.createdAt).format("lll")}</td>
                          <td>
                            {v.status.charAt(0).toUpperCase() +
                              v.status.slice(1)}
                          </td>
                          <td>
                            <ControlButtons
                              business={v}
                              userId={userId}
                              populateDelayModalData={populateDelayModalData}
                              populateBusinessUsersData={
                                populateBusinessUsersData
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                ""
              )}
            </div>
          </div>

          {reportTypes.map((type, index) => {
            return (
              <div
                key={index}
                id={type + "Pci"}
                className="modal"
                tabIndex="-1"
                role="dialog"
              >
                <EmailTemplateReport sendSslReport={this.sendPCIReport} />
              </div>
            );
          })}
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    businesses: state.accountStore.businesses,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      sendEmailForReport,
      getBusinesses,
      goToDashboard: () =>
        push("/", {
          accountAdminView: true,
        }),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PCIReports);
