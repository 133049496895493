import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { Button, Modal } from "react-bootstrap";
import AppContext from "../../../components/context/appContext";
import config from "../../../helpers/config";
import _ from "lodash";

class StartScanButton extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false,
      buttonStatus: "Start PCI Scan",
      buttonDisabled: false,
      beyondSecurityScanInfo: {
        scanTime: _.random(6, 23) + ":00",
        scanTimeZone: "America/Chicago",
      },
    };
  }

  abortController = new AbortController();

  handleClose() {
    this.setState({ show: false });
  }

  async handleStartScan() {
    const data = {
      _id: this.props.businessId,
      scanTimeDetails: {
        scanTime: this.state.beyondSecurityScanInfo.scanTime,
        scanTimeZone: this.state.beyondSecurityScanInfo.scanTimeZone,
      },
    };

    console.log(
      "scandetails: ",
      this.state.beyondSecurityScanInfo.scanTime,
      this.state.beyondSecurityScanInfo.scanTimeZone
    );
    this.props.startScanDomainHandler(this.props.index, data);
    this.setState({
      show: false,
      buttonStatus: "Please wait...",
      buttonDisabled: true,
    });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleScanTime = (input) => {
    const beyondSecurityScanInfo = { ...this.state.beyondSecurityScanInfo };
    beyondSecurityScanInfo["scanTime"] = input.target.value;
    this.setState({ beyondSecurityScanInfo });
  };

  handleScanTimeZone = (input) => {
    const beyondSecurityScanInfo = { ...this.state.beyondSecurityScanInfo };
    beyondSecurityScanInfo["scanTimeZone"] = input.target.value;
    this.setState({ beyondSecurityScanInfo });

    if (input.target.value === 0) {
      this.setState({ invalidTimeZone: true });
    } else {
      this.setState({ invalidTimeZone: false });
    }
  };

  render() {
    const {
      show,
      beyondSecurityScanInfo,
      invalidTimeZone,
      buttonStatus,
      buttonDisabled,
    } = this.state;

    let id = "start-scan-button";
    if (isMobile) {
      id = "start-scan-button-mobile";
    }
    return (
      <>
        <div className="row" id={id}>
          <button
            data-testid="startNewPCIScan"
            onClick={this.handleShow}
            className="btn-success btn-lg"
            style={{
              margin: "0px auto",
              display: "block",
              cursor: "pointer",
            }}
            disabled={this.props.submitted ? true : false}
          >
            {isMobile
              ? this.props.submitted
                ? "Creating a Scan..."
                : "Start PCI Scan"
              : buttonStatus}
          </button>

          <Modal
            style={{ top: "0px" }}
            show={show}
            onHide={this.handleClose}
            id="startScanModal"
          >
            <Modal.Header style={{ padding: "10px" }}>
              <Modal.Title>
                <h4 style={{ fontWeight: "bold", textTransform: "uppercase" }}>
                  START NEW SCAN
                </h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: "400px" }}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm">
                    <p style={{ fontSize: "16px" }}>
                      Once you press the Start button below, we will begin
                      scanning your website/IP every day for the latest
                      vulnerabilities. <br />
                      <br />
                      If you want to change your scanning frequency, you can do
                      so after you start your first scan by clicking on the
                      circle icon under PCI Scan.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm">
                    <div className="form-group">
                      <label htmlFor="timeZoneSelect">Time Zone</label>
                      <select
                        data-testid="selPciTimeZone"
                        className="form-control"
                        id="timeZoneSelect"
                        name="timeZoneSelect"
                        onChange={this.handleScanTimeZone}
                        value={beyondSecurityScanInfo.scanTimeZone}
                      >
                        <option value="0">Please Select Timezone</option>
                        {config.timezones.map((v, i) => {
                          return (
                            <option key={i} value={v.timezone}>
                              {v.timezone}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm" style={{ marginLeft: "6.5rem" }}>
                    <div className="form-group">
                      <label htmlFor="timeZoneSelect">Scan Time</label>
                      <select
                        data-testid="selPciScanTime"
                        className="form-control col-sm-12"
                        id="timeSelect"
                        name="timeSelect"
                        onChange={this.handleScanTime}
                        value={beyondSecurityScanInfo.scanTime}
                      >
                        <option value="24:00">12:00 AM</option>
                        <option value="01:00">01:00 AM</option>
                        <option value="01:10">01:10 AM</option>
                        <option value="01:15">01:15 AM</option>
                        <option value="01:20">01:20 AM</option>
                        <option value="01:25">01:25 AM</option>
                        <option value="01:30">01:30 AM</option>
                        <option value="01:35">01:35 AM</option>
                        <option value="01:40">01:40 AM</option>
                        <option value="01:45">01:45 AM</option>
                        <option value="01:50">01:50 AM</option>
                        <option value="01:55">01:55 AM</option>

                        <option value="02:00">02:00 AM</option>
                        <option value="02:10">02:10 AM</option>
                        <option value="02:15">02:15 AM</option>
                        <option value="02:20">02:20 AM</option>
                        <option value="02:25">02:25 AM</option>
                        <option value="02:30">02:30 AM</option>
                        <option value="02:35">02:35 AM</option>
                        <option value="02:40">02:40 AM</option>
                        <option value="02:45">02:45 AM</option>
                        <option value="02:50">02:50 AM</option>
                        <option value="02:55">02:55 AM</option>

                        <option value="03:00">03:00 AM</option>
                        <option value="03:10">03:10 AM</option>
                        <option value="03:15">03:15 AM</option>
                        <option value="03:20">03:20 AM</option>
                        <option value="03:25">03:25 AM</option>
                        <option value="03:30">03:30 AM</option>
                        <option value="03:35">03:35 AM</option>
                        <option value="03:40">03:40 AM</option>
                        <option value="03:45">03:45 AM</option>
                        <option value="03:50">03:50 AM</option>
                        <option value="03:55">03:55 AM</option>

                        <option value="04:00">04:00 AM</option>
                        <option value="04:10">04:10 AM</option>
                        <option value="04:15">04:15 AM</option>
                        <option value="04:20">04:20 AM</option>
                        <option value="04:25">04:25 AM</option>
                        <option value="04:30">04:30 AM</option>
                        <option value="04:35">04:35 AM</option>
                        <option value="04:40">04:40 AM</option>
                        <option value="04:45">04:45 AM</option>
                        <option value="04:50">04:50 AM</option>
                        <option value="04:55">04:55 AM</option>

                        <option value="5:00">05:00 AM</option>
                        <option value="05:10">05:10 AM</option>
                        <option value="05:15">05:15 AM</option>
                        <option value="05:20">05:20 AM</option>
                        <option value="05:25">05:25 AM</option>
                        <option value="05:30">05:30 AM</option>
                        <option value="05:35">05:35 AM</option>
                        <option value="05:40">05:40 AM</option>
                        <option value="05:45">05:45 AM</option>
                        <option value="05:50">05:50 AM</option>
                        <option value="05:55">05:55 AM</option>

                        <option value="06:00">06:00 AM</option>
                        <option value="06:10">06:10 AM</option>
                        <option value="06:15">06:15 AM</option>
                        <option value="06:20">06:20 AM</option>
                        <option value="06:25">06:25 AM</option>
                        <option value="06:30">06:30 AM</option>
                        <option value="06:35">06:35 AM</option>
                        <option value="06:40">06:40 AM</option>
                        <option value="06:45">06:45 AM</option>
                        <option value="06:50">06:50 AM</option>
                        <option value="06:55">06:55 AM</option>

                        <option value="07:00">07:00 AM</option>
                        <option value="07:10">07:10 AM</option>
                        <option value="07:15">07:15 AM</option>
                        <option value="07:20">07:20 AM</option>
                        <option value="07:25">07:25 AM</option>
                        <option value="07:30">07:30 AM</option>
                        <option value="07:35">07:35 AM</option>
                        <option value="07:40">07:40 AM</option>
                        <option value="07:45">07:45 AM</option>
                        <option value="07:50">07:50 AM</option>
                        <option value="07:55">07:55 AM</option>

                        <option value="08:00">08:00 AM</option>
                        <option value="08:10">08:10 AM</option>
                        <option value="08:15">08:15 AM</option>
                        <option value="08:20">08:20 AM</option>
                        <option value="08:25">08:25 AM</option>
                        <option value="08:30">08:30 AM</option>
                        <option value="08:35">08:35 AM</option>
                        <option value="08:40">08:40 AM</option>
                        <option value="08:45">08:45 AM</option>
                        <option value="08:50">08:50 AM</option>
                        <option value="08:55">08:55 AM</option>

                        <option value="09:00">09:00 AM</option>
                        <option value="09:10">09:10 AM</option>
                        <option value="09:15">09:15 AM</option>
                        <option value="09:20">09:20 AM</option>
                        <option value="09:25">09:25 AM</option>
                        <option value="09:30">09:30 AM</option>
                        <option value="09:35">09:35 AM</option>
                        <option value="09:40">09:40 AM</option>
                        <option value="09:45">09:45 AM</option>
                        <option value="09:50">09:50 AM</option>
                        <option value="09:55">09:55 AM</option>

                        <option value="10:00">10:00 AM</option>
                        <option value="10:10">10:10 AM</option>
                        <option value="10:15">10:15 AM</option>
                        <option value="10:20">10:20 AM</option>
                        <option value="10:25">10:25 AM</option>
                        <option value="10:30">10:30 AM</option>
                        <option value="10:35">10:35 AM</option>
                        <option value="10:40">10:40 AM</option>
                        <option value="10:45">10:45 AM</option>
                        <option value="10:50">10:50 AM</option>
                        <option value="10:55">10:55 AM</option>

                        <option value="11:00">11:00 AM</option>
                        <option value="11:10">11:10 AM</option>
                        <option value="11:15">11:15 AM</option>
                        <option value="11:20">11:20 AM</option>
                        <option value="11:25">11:25 AM</option>
                        <option value="11:30">11:30 AM</option>
                        <option value="11:35">11:35 AM</option>
                        <option value="11:40">11:40 AM</option>
                        <option value="11:45">11:45 AM</option>
                        <option value="11:50">11:50 AM</option>
                        <option value="11:55">11:55 AM</option>

                        <option value="12:00">12:00 PM</option>
                        <option value="12:10">12:10 PM</option>
                        <option value="12:15">12:15 PM</option>
                        <option value="12:20">12:20 PM</option>
                        <option value="12:25">12:25 PM</option>
                        <option value="12:30">12:30 PM</option>
                        <option value="12:35">12:35 PM</option>
                        <option value="12:40">12:40 PM</option>
                        <option value="12:45">12:45 PM</option>
                        <option value="12:50">12:50 PM</option>
                        <option value="12:55">12:55 PM</option>

                        <option value="13:00">01:00 PM</option>
                        <option value="13:10">01:10 PM</option>
                        <option value="13:15">01:15 PM</option>
                        <option value="13:20">01:20 PM</option>
                        <option value="13:25">01:25 PM</option>
                        <option value="13:30">01:30 PM</option>
                        <option value="13:35">01:35 PM</option>
                        <option value="13:40">01:40 PM</option>
                        <option value="13:45">01:45 PM</option>
                        <option value="13:50">01:50 PM</option>
                        <option value="13:55">01:55 PM</option>

                        <option value="14:00">02:00 PM</option>
                        <option value="14:10">02:10 PM</option>
                        <option value="14:15">02:15 PM</option>
                        <option value="14:20">02:20 PM</option>
                        <option value="14:25">02:25 PM</option>
                        <option value="14:30">02:30 PM</option>
                        <option value="14:35">02:35 PM</option>
                        <option value="14:40">02:40 PM</option>
                        <option value="14:45">02:45 PM</option>
                        <option value="14:50">02:50 PM</option>
                        <option value="14:55">02:55 PM</option>

                        <option value="15:00">03:00 PM</option>
                        <option value="15:10">03:10 PM</option>
                        <option value="15:15">03:15 PM</option>
                        <option value="15:20">03:20 PM</option>
                        <option value="15:25">03:25 PM</option>
                        <option value="15:30">03:30 PM</option>
                        <option value="15:35">03:35 PM</option>
                        <option value="15:40">03:40 PM</option>
                        <option value="15:45">03:45 PM</option>
                        <option value="15:50">03:50 PM</option>
                        <option value="15:55">03:55 PM</option>

                        <option value="16:00">04:00 PM</option>
                        <option value="16:10">04:10 PM</option>
                        <option value="16:15">04:15 PM</option>
                        <option value="16:20">04:20 PM</option>
                        <option value="16:25">04:25 PM</option>
                        <option value="16:30">04:30 PM</option>
                        <option value="16:35">04:35 PM</option>
                        <option value="16:40">04:40 PM</option>
                        <option value="16:45">04:45 PM</option>
                        <option value="16:50">04:50 PM</option>
                        <option value="16:55">04:55 PM</option>

                        <option value="17:00">05:00 PM</option>
                        <option value="17:10">05:10 PM</option>
                        <option value="17:15">05:15 PM</option>
                        <option value="17:20">05:20 PM</option>
                        <option value="17:25">05:25 PM</option>
                        <option value="17:30">05:30 PM</option>
                        <option value="17:35">05:35 PM</option>
                        <option value="17:40">05:40 PM</option>
                        <option value="17:45">05:45 PM</option>
                        <option value="17:50">05:50 PM</option>
                        <option value="17:55">05:55 PM</option>

                        <option value="18:00">06:00 PM</option>
                        <option value="18:10">06:10 PM</option>
                        <option value="18:15">06:15 PM</option>
                        <option value="18:20">06:20 PM</option>
                        <option value="18:25">06:25 PM</option>
                        <option value="18:30">06:30 PM</option>
                        <option value="18:35">06:35 PM</option>
                        <option value="18:40">06:40 PM</option>
                        <option value="18:45">06:45 PM</option>
                        <option value="18:50">06:50 PM</option>
                        <option value="18:55">06:55 PM</option>

                        <option value="19:00">07:00 PM</option>
                        <option value="19:10">07:10 PM</option>
                        <option value="19:15">07:15 PM</option>
                        <option value="19:20">07:20 PM</option>
                        <option value="19:25">07:25 PM</option>
                        <option value="19:30">07:30 PM</option>
                        <option value="19:35">07:35 PM</option>
                        <option value="19:40">07:40 PM</option>
                        <option value="19:45">07:45 PM</option>
                        <option value="19:50">07:50 PM</option>
                        <option value="19:55">07:55 PM</option>

                        <option value="20:00">08:00 PM</option>
                        <option value="20:10">08:10 PM</option>
                        <option value="20:15">08:15 PM</option>
                        <option value="20:20">08:20 PM</option>
                        <option value="20:25">08:25 PM</option>
                        <option value="20:30">08:30 PM</option>
                        <option value="20:35">08:35 PM</option>
                        <option value="20:40">08:40 PM</option>
                        <option value="20:45">08:45 PM</option>
                        <option value="20:50">08:50 PM</option>
                        <option value="20:55">08:55 PM</option>

                        <option value="21:00">09:00 PM</option>
                        <option value="21:10">09:10 PM</option>
                        <option value="21:15">09:15 PM</option>
                        <option value="21:20">09:20 PM</option>
                        <option value="21:25">09:25 PM</option>
                        <option value="21:30">09:30 PM</option>
                        <option value="21:35">09:35 PM</option>
                        <option value="21:40">09:40 PM</option>
                        <option value="21:45">09:45 PM</option>
                        <option value="21:50">09:50 PM</option>
                        <option value="21:55">09:55 PM</option>

                        <option value="22:00">10:00 PM</option>
                        <option value="22:10">10:10 PM</option>
                        <option value="22:15">10:15 PM</option>
                        <option value="22:20">10:20 PM</option>
                        <option value="22:25">10:25 PM</option>
                        <option value="22:30">10:30 PM</option>
                        <option value="22:35">10:35 PM</option>
                        <option value="22:40">10:40 PM</option>
                        <option value="22:45">10:45 PM</option>
                        <option value="22:50">10:50 PM</option>
                        <option value="22:55">10:55 PM</option>

                        <option value="23:00">11:00 PM</option>
                        <option value="23:10">11:10 PM</option>
                        <option value="23:15">11:15 PM</option>
                        <option value="23:20">11:20 PM</option>
                        <option value="23:25">11:25 PM</option>
                        <option value="23:30">11:30 PM</option>
                        <option value="23:35">11:35 PM</option>
                        <option value="23:40">11:40 PM</option>
                        <option value="23:45">11:45 PM</option>
                        <option value="23:50">11:50 PM</option>
                        <option value="23:55">11:55 PM</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col1">
                    <div
                      className="alert alert-dark"
                      role="alert"
                      style={{
                        background: "#818181",
                        color: "#fff",
                        fontSize: "1.2em",
                      }}
                    >
                      <i
                        className="fa fa-info-circle"
                        style={{
                          float: "left",
                          marginRight: "10px",
                          marginTop: "4px",
                          fontSize: "2.5em",
                        }}
                      />
                      Your first scan will begin right now but future scans will
                      start at approximately the time you select.
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ textAlign: "right" }}>
                <Button
                  className="btn btn-default"
                  onClick={this.handleClose}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </Button>
                <Button
                  data-testid="clickStartScan"
                  className="btn btn-success"
                  onClick={() => this.handleStartScan()}
                  disabled={invalidTimeZone}
                >
                  Start PCI Scan
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </div>

        <div id="panelhack" />
      </>
    );
  }
}
StartScanButton.contextType = AppContext;
export default StartScanButton;
