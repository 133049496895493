import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { images } from '../../helpers/images';
import { convertTimeTo } from '../../helpers/timeZone';
import { callApiGetCertificateData } from '../../services/';

class Certificate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      businessId: null,
      primaryDomain: null,
      business: '',
      showDetails: 'block',
      businessCertificateData: {
        'website-security': {
          panelTitle: 'SECURITY SCANNING',
          image: 'tgSecurityIco',
          scanDescription: 'WEBSITE is Scanned FREQUENCY to PCI Standards',
          primaryParameterText: 'Security Scanned',
          secondaryParameterText: 'Status'
        },
        'no-malware': {
          panelTitle: 'MALWARE PROTECTION',
          image: 'tgMalwareIco',
          scanDescription: 'WEBSITE is Scanned FREQUENCY for Malware',
          primaryParameterText: 'Malware Scanned',
          secondaryParameterText: 'Status'
        },
        'active-ssl': {
          panelTitle: 'SSL PROTECTION',
          image: 'tgSslIco',
          scanDescription: 'WEBSITE has an Active SSL Certificate',
          primaryParameterText: 'SSL Provider',
          secondaryParameterText: 'Valid Until'
        }
      },
      isScanFailed: null
    };
  }

  async componentDidMount() {
    let primaryDomain = this.props.location.pathname;
    primaryDomain = primaryDomain.replace('/certificate/', '');
    primaryDomain = primaryDomain.replace('/', '');

    document.title = primaryDomain + ' - Trust Guard Security Requirements';

    const businessData = await callApiGetCertificateData(primaryDomain);

    if (businessData.success && businessData.data) {
      this.setState(
        {
          business: businessData.data
        },
        () => {
          // console.log(this.state.business);
        }
      );
    }
  }

  renderInfoParagraphs(slug) {
    switch (slug) {
      case 'website-security':
        return (
          <span>
            By actively scanning their website to Payment Card Industry Data Security Standards (PCI
            DSS),{' '}
            <span className="certDescDomain">
              {this.state.business.primaryDomain.replace('www.', '')}
            </span>{' '}
            can more effectively protect and secure your credit card and/or other personal
            information.
          </span>
        );

      case 'no-malware':
        return (
          <span>
            <span className="certDescDomain">
              {this.state.business.primaryDomain.replace('www.', '')}
            </span>{' '}
            is actively scanned for Malware using Heuristics and Artificial Intelligence to
            regularly check internal files for anomalies, so that if anything is ever discovered, it
            can quickly be resolved.
          </span>
        );

      case 'active-ssl':
        return (
          <span>
            By having an active SSL certificate,{' '}
            <span className="certDescDomain">
              {this.state.business.primaryDomain.replace('www.', '')}
            </span>{' '}
            is able to encrypt your information and send it securely over the Internet to their
            merchant bank; protecting your information during the transaction.
          </span>
        );

      default:
        return null;
    }
  }

  render() {
    let displayStyle;
    const { business, showDetails, businessCertificateData } = this.state;
    let pciDate, pciText;

    switch (business.pciStatus) {
      case 'pass':
        pciDate = business.pciScanDate;
        pciText = business.pciStatus;
        break;
      case 'fail':
        pciDate = business.pciScanDate;
        pciText = business.pciStatus;
        break;
      default:
        pciDate = business.lastPciScanDate;
        pciText = business.lastPciStatus;
        break;
    }

    const formattedLastPciDate = new Date(pciDate);
    const certLastPciDate = formattedLastPciDate
      ? (formattedLastPciDate.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        formattedLastPciDate
          .getDate()
          .toString()
          .padStart(2, '0') +
        '-' +
        formattedLastPciDate.getFullYear()
      : '';

    return (
      <div
        style={{
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          minHeight: '1000px',
          backgroundImage: 'url(' + images.tgCertificateBg + ')'
        }}>
        <nav className="navbar navbar-fixed-top navbar-inverse">
          <div className="container">
            <div
              className="col-md-10 col-md-offset-1"
              style={{ marginBottom: '7px', marginTop: '7px' }}>
              <div className="navbar-header">
                <a
                  className="navbar-brand"
                  href="https://trustguard.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    src={images.tgCertificateLogo}
                    alt="Trust Guard"
                    style={{
                      height: '47px',
                      position: 'relative',
                      top: '-10px'
                    }}
                  />
                </a>
              </div>

              <div className="pull-right">
                <button
                  type="button"
                  className="btn btn-default navbar-btn"
                  style={{
                    fontSize: '11px',
                    marginRight: '25px',
                    backgroundColor: 'rgba(204, 204, 204, 0.0)',
                    borderColor: '#f9f9f9',
                    color: '#f9f9f9',
                    padding: '2px 25px'
                  }}
                  onClick={() => {
                    window.open('https://trustguard.com', '_blank');
                  }}>
                  Free PCI, Malware <br />& SSL Scan
                </button>

                <img
                  id="256bitssl"
                  src={images.tgCertificate256BitSsl}
                  alt="256 Bit SSL Certificate"
                  style={{ height: '30px' }}
                />
              </div>
            </div>
          </div>
        </nav>

        <div id="certContainer" className="container">
          <div
            className="row"
            style={{
              marginTop: '105px',
              padding: '0px',
              backgroundColor: 'transparent'
            }}>
            <div className="col-md-12 text-center p-0 m-0" style={{ width: '100%' }}>
              <h1
                id="primCertDom"
                style={
                  (business.status !== 'canceled' &&
                    business.accountType !== 'free' && {
                      color: '#2E871C',
                      fontSize: '40px',
                      fontWeight: '600'
                    }) || {
                    color: '#c01818',
                    fontSize: '40px',
                    fontWeight: '600'
                  }
                }>
                {business.primaryDomain
                  ? business.primaryDomain.indexOf('www.') !== -1
                    ? business.primaryDomain
                        .replace('www.', '')
                        .charAt(0)
                        .toUpperCase() + business.primaryDomain.replace('www.', '').slice(1)
                    : business.primaryDomain
                  : ''}
              </h1>

              <h2
                className="tgcert-subhead"
                style={{
                  textAlign: 'center',
                  margin: '20px auto',
                  fontSize: '20px',
                  lineHeight: '1.4',
                  fontWeight: '600'
                }}>
                {business.status !== 'canceled' &&
                  business.accountType !== 'free' && (
                    <>
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            " Has successfully met the following Trust Guard<sup>®</sup> <br className='hidden-sm hidden-xs' /> Security Requirements as of "
                        }}
                      />
                      <span className="certificateDateColor">{moment().format('MM-DD-YYYY')}</span>
                    </>
                  )}
              </h2>

              <h2
                className="tgcert-subhead"
                style={{
                  textAlign: 'center',
                  margin: '20px auto',
                  fontSize: '20px',
                  lineHeight: '1.4',
                  fontWeight: '600',
                  color: '#c01818'
                }}>
                {(business.status === 'canceled' || business.accountType == 'free') &&
                business.primaryDomain
                  ? business.primaryDomain.indexOf('www.') !== -1
                    ? business.status +
                      ' ' +
                      business.primaryDomain
                        .replace('www.', '')
                        .charAt(0)
                        .toUpperCase() +
                      business.primaryDomain.replace('www.', '').slice(1)
                    : business.primaryDomain
                  : ''}

                {(business.status === 'canceled' || business.accountType === 'free') && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' is not an active member of TrustGuard<sup>®</sup>'
                    }}
                  />
                )}
              </h2>
            </div>

            <div className="row" style={{ margin: '0px', pading: '0px' }}>
              <div
                id="cert-container-list"
                className="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12 col-xs-12 col-xxs-12 pr-0">
                <div className="row">
                  <div
                    className="col-lg-9 col-md-9 col-sm-12 col-xs-12 col-xxs-12"
                    style={{ paddingLeft: '0px' }}>
                    {business.status !== 'canceled' &&
                    business.accountType !== 'free' &&
                    business.certificate
                      ? business.certificate.map((v, i) => {
                          displayStyle = 'block';

                          if (v.slug === 'website-security' && pciText === 'fail') {
                            displayStyle = 'none';
                          }

                          if (
                            v.slug === 'no-malware' &&
                            business.lastMalwareScanResult !== 'clean'
                          ) {
                            displayStyle = 'none';
                          }

                          if (v.slug === 'active-ssl') {
                            if (business.sslStatus !== 'active') {
                              displayStyle = 'none';
                            }
                          }

                          return (
                            <div
                              key={i}
                              className="panel panel-default"
                              style={{
                                borderRadius: '10px',
                                display: displayStyle
                              }}>
                              <div
                                className="panel-heading"
                                style={{
                                  backgroundColor: '#f9f9f9',
                                  padding: '17px 17px',
                                  borderTopLeftRadius: '10px',
                                  borderTopRightRadius: '10px'
                                }}>
                                <h3 className="pmain-title" style={{ textTransform: 'uppercase' }}>
                                  <strong>{businessCertificateData[v.slug].panelTitle} </strong>
                                </h3>
                              </div>
                              <div className="panel-body">
                                <div className="row">
                                  <div
                                    className="col-md-2 col-sm-2 col-xs-2 col-xxs-3 tg-cert-badge tg-mobile-badge"
                                    style={{
                                      lineHeight: '50px'
                                    }}>
                                    <img
                                      src={images[businessCertificateData[v.slug].image]}
                                      height="35px"
                                      alt={businessCertificateData[v.slug].primaryParameterText}
                                    />
                                  </div>
                                  <div
                                    className="col-md-10 col-sm-10 col-xs-10 col-xxs-9"
                                    style={{ padding: '0px' }}>
                                    <p
                                      className="secScan-p1"
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: '700',
                                        color: '#2b2b2b',
                                        margin: '0px',
                                        padding: '0px',
                                        lineHeight: '25px'
                                      }}>
                                      {v.slug === 'website-security'
                                        ? businessCertificateData[v.slug].scanDescription.includes(
                                            'FREQUENCY'
                                          )
                                          ? businessCertificateData[v.slug].scanDescription
                                              .replace(
                                                'WEBSITE',
                                                business.primaryDomain
                                                  .replace('www.', '')
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                  business.primaryDomain
                                                    .replace('www.', '')
                                                    .slice(1)
                                              )
                                              .replace(
                                                'FREQUENCY',
                                                business.scanFrequency &&
                                                  business.scanFrequency.charAt(0).toUpperCase() +
                                                    business.scanFrequency.slice(1)
                                              )
                                          : businessCertificateData[v.slug].scanDescription.replace(
                                              'WEBSITE',
                                              business.primaryDomain
                                                .replace('www.', '')
                                                .charAt(0)
                                                .toUpperCase() +
                                                business.primaryDomain.replace('www.', '').slice(1)
                                            )
                                        : ''}

                                      {v.slug === 'no-malware'
                                        ? businessCertificateData[v.slug].scanDescription.includes(
                                            'FREQUENCY'
                                          )
                                          ? businessCertificateData[v.slug].scanDescription
                                              .replace(
                                                'WEBSITE',
                                                business.primaryDomain
                                                  .replace('www.', '')
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                  business.primaryDomain
                                                    .replace('www.', '')
                                                    .slice(1)
                                              )
                                              .replace(
                                                'FREQUENCY',
                                                business.malwareFrequency.charAt(0).toUpperCase() +
                                                  business.malwareFrequency.slice(1)
                                              )
                                          : businessCertificateData[v.slug].scanDescription.replace(
                                              'WEBSITE',
                                              business.primaryDomain
                                                .replace('www.', '')
                                                .charAt(0)
                                                .toUpperCase() +
                                                business.primaryDomain.replace('www.', '').slice(1)
                                            )
                                        : ''}
                                      {v.slug === 'active-ssl'
                                        ? businessCertificateData[v.slug].scanDescription.replace(
                                            'WEBSITE',
                                            business.primaryDomain.replace('www.', '')
                                          )
                                        : ''}
                                    </p>
                                    <p
                                      className="secScanDate"
                                      style={{
                                        height: '25px',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        color: '#2b2b2b',
                                        padding: '0px',
                                        margin: '0px',
                                        lineHeight: '15px',
                                        display: showDetails
                                      }}>
                                      <span>
                                        {businessCertificateData[v.slug].primaryParameterText}:{' '}
                                        <span style={{ color: '#2E871C' }}>
                                          {v.slug === 'website-security'
                                            ? /* moment
                                                .utc(pciDate)
                                                .format("MM-DD-YYYY") */ certLastPciDate
                                            : ''}
                                          {v.slug === 'no-malware'
                                            ? moment(business.lastMalwareScan).format('MM-DD-YYYY')
                                            : ''}
                                          {v.slug === 'active-ssl' ? business.sslProvider : ''}
                                        </span>
                                      </span>
                                      <span
                                        className="secScanStatus"
                                        style={{
                                          float: 'right',
                                          marginRight: '20px'
                                        }}>
                                        <span style={{ color: '#2b2b2b' }}>
                                          {v.slug === 'website-security' && pciText === 'pass'
                                            ? this.state.businessCertificateData[v.slug]
                                                .secondaryParameterText + ' : '
                                            : ''}{' '}
                                          {v.slug === 'no-malware' &&
                                          business.lastMalwareScanResult === 'clean'
                                            ? this.state.businessCertificateData[v.slug]
                                                .secondaryParameterText + ' : '
                                            : ''}
                                          {v.slug === 'active-ssl'
                                            ? Array.isArray(business.domains)
                                              ? this.state.businessCertificateData[v.slug]
                                                  .secondaryParameterText + ' : '
                                              : this.state.businessCertificateData[v.slug]
                                                  .secondaryParameterText + ' : '
                                            : ''}
                                        </span>
                                        <span style={{ color: '#2E871C' }}>
                                          {v.slug === 'website-security' && pciText === 'pass'
                                            ? 'Passed'
                                            : ''}
                                          {v.slug === 'no-malware' &&
                                          business.lastMalwareScanResult === 'clean'
                                            ? 'Clean'
                                            : ''}
                                          {v.slug === 'active-ssl'
                                            ? moment(business.sslExpireDate).format('MM-DD-YYYY')
                                            : ''}
                                        </span>
                                      </span>
                                    </p>

                                    <p className="certificateDescriptionText certDescInfo">
                                      {this.renderInfoParagraphs(v.slug)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : ''}
                    <div
                      className="panel panel-default"
                      style={{
                        borderRadius: '10px',
                        backgroundColor: '#f9f9f9'
                      }}>
                      <div className="panel-body pointer" style={{ padding: '2px' }}>
                        <img
                          src={images.tgWebsiteBanner}
                          alt="Trust Guard Banner"
                          onClick={() => {
                            window.open('https://trustguard.com', '_blank');
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="copyright1"
                      style={{
                        color: '#333333',
                        fontSize: '10px',
                        marginBottom: '40px'
                      }}>
                      <strong>
                        {' '}
                        &copy; {moment().format('Y')} Trust Guard - All Rights Reserved.
                      </strong>{' '}
                      &nbsp; Disclaimer: This certificate confirms that{' '}
                      {business.primaryDomain + ' '}
                      has been recently scanned using one or more of the following Trust Guard
                      services: PCI DSS scanning, Heuristic Malware scanning, and/or SSL Validation
                      scanning, according to the results displayed and corresponding scan dates
                      shown. As a consumer, this certificate is meant to provide you with helpful
                      information, so that you can see the additional security measures that{' '}
                      {business.primaryDomain + ' '}has undergone on your behalf, to help you make a
                      more informed buying decision. <br />
                      <br />While reasonable efforts are made to ensure that the scanning services
                      provided are functioning properly and the information displayed is correct,
                      Trust Guard makes no warranty or claim regarding the accuracy of the
                      information provided. In addition, Trust Guard does not guarantee that the
                      scanned website is immune from hackers or that any data stored on their
                      servers is completely safe. Trust Guard is also in no way responsible for the
                      security or use of any information collected by the scanned website or stored
                      on their servers.
                    </div>
                  </div>

                  <div
                    className="col-lg-3 col-md-3 col-sm-12 col-xs-12"
                    style={{ paddingLeft: '0px' }}>
                    {business.status !== 'canceled' &&
                    business.accountType !== 'free' &&
                    business.certificateContact ? (
                      <div
                        className="panel panel-default py-2"
                        style={{
                          borderRadius: '12px'
                        }}>
                        {business.certificateContact &&
                        business.certificateContact.companyParagraph ? (
                          <div>
                            <div
                              className="panel-heading"
                              style={{
                                background: 'rgba(255, 255, 255, 0.45)',
                                borderColor: 'transparent',
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                color: '#333333'
                              }}>
                              <h3
                                className="panel-title"
                                style={{
                                  fontWeight: 'bold'
                                }}>
                                {business.businessName}
                              </h3>
                            </div>
                            <div
                              className="panel-body pt-0"
                              style={{
                                color: '#333333',
                                fontSize: '12px'
                              }}>
                              {business.certificateContact.companyParagraph}
                            </div>
                          </div>
                        ) : (
                          ''
                        )}

                        {(business.accountType !== 'free' && business.certificateContact) ||
                        business.certificateContact.address ||
                        business.certificateContact.address2 ||
                        business.certificateContact.city ||
                        business.certificateContact.state ||
                        business.certificateContact.zip ||
                        business.certificateContact.country ? (
                          <div>
                            <div
                              className="panel-heading"
                              style={{
                                background: 'rgba(255, 255, 255, 0.45)',
                                borderColor: 'transparent',
                                fontSize: '16px',
                                color: '#333333'
                              }}>
                              <h3 className="panel-title">
                                <strong>Address</strong>
                              </h3>
                            </div>
                            <div
                              className="panel-body pt-0"
                              style={{
                                fontSize: '12px',
                                color: '#2b2b2b'
                              }}>
                              {business.certificateContact && business.certificateContact.address
                                ? business.certificateContact.address
                                : ''}
                              {business.certificateContact &&
                              business.certificateContact.address ? (
                                <br />
                              ) : (
                                ''
                              )}
                              {business.certificateContact && business.certificateContact.address2
                                ? business.certificateContact.address2
                                : ''}
                              {business.certificateContact &&
                              business.certificateContact.address2 ? (
                                <br />
                              ) : (
                                ''
                              )}
                              {business.certificateContact && business.certificateContact.city
                                ? business.certificateContact.city + ' '
                                : ''}
                              {business.certificateContact && business.certificateContact.state
                                ? business.certificateContact.state + ', '
                                : ''}{' '}
                              {business.certificateContact && business.certificateContact.zip
                                ? business.certificateContact.zip
                                : ''}
                              <br />
                              {business.certificateContact && business.certificateContact.country
                                ? business.certificateContact.country
                                : ''}
                            </div>
                          </div>
                        ) : (
                          ''
                        )}

                        {business.accountType !== 'free' && business.certificateContact ? (
                          <div>
                            <div
                              className="panel-heading"
                              style={{
                                background: 'rgba(255, 255, 255, 0.45)',
                                borderColor: 'transparent',
                                fontSize: '14px',
                                color: '#333333'
                              }}>
                              <h3
                                className="panel-title"
                                style={{
                                  color: '#333333'
                                }}>
                                <strong>Contact</strong>
                              </h3>
                            </div>
                            <div
                              className="panel-body pt-0"
                              style={{
                                fontSize: '12px',
                                color: '#2b2b2b'
                              }}>
                              {business.certificateContact &&
                              business.certificateContact.contactPhone
                                ? business.certificateContact.contactPhone
                                : ''}
                              {business.certificateContact &&
                              business.certificateContact.contactPhone ? (
                                <br />
                              ) : (
                                ''
                              )}
                              {business.certificateContact &&
                              business.certificateContact.contactEmail
                                ? business.certificateContact.contactEmail
                                : ''}
                              {business.certificateContact &&
                              business.certificateContact.contactEmail ? (
                                <br />
                              ) : (
                                ''
                              )}
                              {business.certificateContact && business.certificateContact.supportUrl
                                ? business.certificateContact.supportUrl
                                : ''}
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      ''
                    )}

                    <div className="panel panel-default  py-2" style={{ borderRadius: '10px' }}>
                      <div
                        className="panel-heading"
                        style={{
                          background: 'rgba(255, 255, 255, 0.45)',
                          borderColor: 'transparent',
                          borderTopLeftRadius: '10px',
                          borderTopRightRadius: '10px'
                        }}>
                        <h4
                          className="panel-title"
                          style={{
                            fontSize: '14px'
                          }}>
                          <strong>
                            About Trust Guard<sup>®</sup>
                          </strong>
                        </h4>
                      </div>
                      <div
                        className="panel-body pt-0"
                        style={{
                          color: '#2b2b2b',
                          fontSize: '12px'
                        }}>
                        Trust Guard is a comprehensive website security solution that helps to
                        protect your personal information in 3 powerful ways: External Perimeter
                        Scanning, Internal Malware Scanning, and SSL Validation Scanning.<br />
                        <br />
                        A website Secured by Trust Guard is a website you can trust.
                      </div>
                      <div className="panel-body pt-0" style={{ textAlign: 'center' }}>
                        <button
                          className="btn btn-default"
                          style={{
                            border: '1px solid #dddddd',
                            background: 'transparent',
                            display: 'block',
                            width: '80%',
                            margin: '0 auto',
                            color: '#2b2b2b'
                          }}
                          onClick={() => {
                            window.open('https://trustguard.com', '_blank');
                          }}>
                          Learn More
                        </button>
                      </div>
                    </div>
                    <div
                      className="copyright2"
                      style={{
                        color: '#333333',
                        fontSize: '10px',
                        display: 'none',
                        marginBottom: '40px'
                      }}>
                      <strong>
                        {' '}
                        &copy; {moment().format('Y')} Trust Guard - All Rights Reserved.
                      </strong>{' '}
                      &nbsp; Disclaimer: This certificate confirms that{' '}
                      {business.primaryDomain + ' '}
                      has been recently scanned using one or more of the following Trust Guard
                      services: PCI DSS scanning, Heuristic Malware scanning, and/or SSL Validation
                      scanning, according to the results displayed and corresponding scan dates
                      shown. As a consumer, this certificate is meant to provide you with helpful
                      information, so that you can see the additional security measures that{' '}
                      {business.primaryDomain + ' '}has undergone on your behalf, to help you make a
                      more informed buying decision. <br />
                      <br />While reasonable efforts are made to ensure that the scanning services
                      provided are functioning properly and the information displayed is correct,
                      Trust Guard makes no warranty or claim regarding the accuracy of the
                      information provided. In addition, Trust Guard does not guarantee that the
                      scanned website is immune from hackers or that any data stored on their
                      servers is completely safe. Trust Guard is also in no way responsible for the
                      security or use of any information collected by the scanned website or stored
                      on their servers.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Certificate);
