import { api } from "./api";
import { callApi } from "../helpers/index";

const callOnGetPciScanData = (id, scanType = null, noLimit = false) => {
  const limit = noLimit ? 100 : 10;

  return api.service("api/scanhistory").find({
    query: {
      businessId: id,
      purpose: "get_pci_histories",
      scanType: scanType,
      limit: limit
    }
  });
};

const callOnGenerateCloneReport = data => {
  return api.service("download-report").create(data);
};

const callOnGetLatestScanData = (id, businessId = null) => {
  return callApi().then(token => {
    let data = {};
    if (businessId === null) {
      data = {
        _id: id,
        $sort: {
          createdAt: -1
        },
        $limit: 1
      };
    } else {
      data = {
        businessId: businessId,
        $sort: {
          createdAt: -1
        },
        $limit: 1
      };
    }
    return api.service("scanhistory").find({
      query: data
    });
  });
};

export { callOnGetPciScanData, callOnGetLatestScanData, callOnGenerateCloneReport };
