import React from "react";
import Aux from "../../components/hoc/containerAux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import config from "../../helpers/config";
import { setStorageItem } from "../../helpers/storage";

import { callApiGetCheckScanReportsPendingDomainData } from "../../services/index";
import ControlButtons from "./controlButtons";
import AppContext from "../../components/context/appContext";
import NoSealsReport from "./noSealsReport";
import SealsGeneratedReport from "./sealsGeneratedReport";
import DeletedSealsReport from "./deletedSealsReport";
import RegenerateAllSeals from "./regenerateSealsReport";
import HiddenSealsReport from "./hiddenSealsReport";
import SealsUrlReport from "./sealsUrlReport";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class SealReports extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {};
  }

  async componentDidMount() {}

  render() {
    return (
      <>
        <div style={{ margin: "15px" }}>
          <hr />
          <SealsUrlReport />
          {/* <NoSealsReport /> */}
          {/* <SealsGeneratedReport /> */}
          {/* <HiddenSealsReport /> */}
          <DeletedSealsReport />
          <RegenerateAllSeals />
        </div>
      </>
    );
  }
}

SealReports.contextType = AppContext;

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SealReports);
