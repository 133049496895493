import React from 'react';
import { push } from 'connected-react-router';
import AppContext from '../../components/context/appContext';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Header from '../header';
import { checkIfWordContainsRule } from '../../helpers/general';
import MalwareScannedFilesInfo from '../../components/malware/malwareScannedFilesInfo';
import Aux from '../../components/hoc/containerAux';
import SpinnerRect from '../../components/ui/spinner/spinnerRectScan';
import BlacklistedDomains from './blacklistedDomains';
import { callApiGetBusinessData, callFindMalwareVulnerabilityByReportUrl } from '../../services';

class MalwareScannedFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      malwareInfoData0: [],
      malwareInfoData: [],
      domainHost: '',
      submitted: false,
      sendForReview: false,
      displayScannedFiles: false,
      blacklistdrawer: false
    };
  }

  getVulnerabilityState(index = null) {
    const vulnerabilityResults = this.props.history.location.state.vulnerabilities;
    return vulnerabilityResults;
  }

  async componentDidMount() {
    const malwareInfoData = [...this.state.malwareInfoData];

    const business = await callApiGetBusinessData(this.props.location.state.businessId);
    const malwareRules = business.hasOwnProperty('malwareRules') ? business.malwareRules : null;

    const vulnerabilitiesFound = this.getVulnerabilityState();

    for (let i = 0; i < vulnerabilitiesFound.length; i++) {
      const domainInfo = vulnerabilitiesFound[i];
      let malInfo = {};

      malInfo['filename'] = domainInfo.fileName;
      malInfo['filetype'] = domainInfo.fileType;
      malInfo['scanStatus'] = domainInfo.threat;
      malInfo['reason'] = domainInfo.reason;
      malInfo['details'] = domainInfo.details;
      malInfo['dump'] = domainInfo.dump;
      malInfo['dumpMD5'] = domainInfo.dumpMD5;
      malInfo['scanFinish'] = domainInfo.date;
      malInfo['domainId'] = this.props.history.location.state.domainId;

      if (domainInfo.threat === 'Clean' || domainInfo.threat === 'clean') {
        malInfo['showAction'] = 'none';
        malInfo['actionStatus'] = 'No Action Needed';
      } else {
        if (malwareRules !== null) {
          const isIgnoredByRule = checkIfWordContainsRule(
            malwareRules,
            domainInfo.details.concat(' ').concat(domainInfo.dump)
          );

          malInfo['isIgnoredByRule'] =
            domainInfo.action === 'Ignored'
              ? isIgnoredByRule
                ? isIgnoredByRule.hasRule
                : false
              : false;
          malInfo['setRule'] = isIgnoredByRule ? isIgnoredByRule.dataRule : null;
        }
      }
      malInfo['actionStatus'] = domainInfo.action;
      malInfo['showAction'] = 'block';

      malwareInfoData.push(malInfo);
    }
    this.setState({
      malwareInfoData,
      displayScannedFiles: true
    });
  }

  async componentDidUpdate(prevProps, prevState) {}

  onOpenBlacklistDrawer = () => {
    this.setState({
      blacklistdrawer: !this.state.blacklistdrawer
    });
  };

  onUpdateMalwareStatus = async (index, action) => {
    const {
      domainHost,
      businessId,
      reportUrl,
      malwareHistoryId,
      malwareRules
    } = this.props.location.state;
    const malwareInfoData = [...this.state.malwareInfoData];
    malwareInfoData[index].actionStatus = action;

    const vulnerabilityData = await callFindMalwareVulnerabilityByReportUrl(
      malwareHistoryId,
      businessId
    );

    // const malwareVulnerabilities =
    //   vulnerabilityData.data[0].vulnerabilityInfo.malwareVulnerabilities;
    // malwareVulnerabilities[index].action = action;
    // this.setState({ malwareInfoData });
  };
  displayScannedFilesTable() {
    const { malwareInfoData } = this.state;
    let scannedFilesTable = null;
    let showScannedFiles = true;
    const vulnerabilities = this.props.history.location.vulnerabilities;
    if (!showScannedFiles) {
      scannedFilesTable = <SpinnerRect />;
    } else {
      scannedFilesTable = (
        <table
          className="table table-borderless"
          style={{
            border: '1px solid #efefef',
            borderCollapse: ' collapse',
            borderRadius: '5px',
            overflowX: 'auto',
            marginBottom: '15px',
            webKitOverflowScrolling: 'touch'
          }}>
          <thead className="thead-dark">
            <tr>
              <th width="25%" style={{ padding: '20px' }}>
                File name
              </th>
              <th width="10%" style={{ padding: '20px' }}>
                File Type
              </th>
              <th width="10%" style={{ padding: '20px' }}>
                Status
              </th>
              <th width="10%" style={{ padding: '20px' }}>
                Action
              </th>
              <th width="15%" style={{ padding: '20px' }}>
                Date
              </th>
              <th width="20%" style={{ padding: '20px' }}>
                Threat Status
              </th>
            </tr>
          </thead>
          <tbody style={{ border: '1px solid #efefef' }}>
            {malwareInfoData.map((malware, i) => {
              return (
                <MalwareScannedFilesInfo
                  key={Math.random()}
                  index={i}
                  fileName={malware.filename}
                  actionStatus={malware.actionStatus}
                  fileTypeName={malware.filetype}
                  reason={malware.reason}
                  details={malware.details}
                  dump={malware.dump}
                  dumpMD5={malware.dumpMD5}
                  scanFinish={malware.scanFinish}
                  domainID={malware.domainId}
                  scanStatus={malware.scanStatus}
                  showAction={malware.showAction}
                  reportUrl={this.props.location.state.reportUrl}
                  malwareHistoryID={this.props.location.state.malwareHistoryId}
                  isIgnoredByRule={malware.isIgnoredByRule}
                  setRule={malware.setRule !== undefined ? malware.setRule : null}
                  businessId={this.props.location.state.businessId}
                  toastSubmitted={false}
                  onUpdateMalwareStatus={this.onUpdateMalwareStatus}
                />
              );
            })}
          </tbody>
          <thead className="thead-dark">
            <tr>
              <th style={{ padding: '20px' }} />
              <th style={{ padding: '20px' }} />
              <th style={{ padding: '20px' }} />
              <th style={{ padding: '20px' }} />
              <th style={{ padding: '20px' }} />
              <th style={{ padding: '20px' }} />
            </tr>
          </thead>
        </table>
      );
    }
    return scannedFilesTable;
  }

  blacklistInfo() {
    let info = null;
    const blacklistreport = this.props.history.location.state.blacklistreport;

    let drawerBlacklistStyle = {
      color: '#212529',
      fontWeight: 'bolder',
      borderRadius: '.25rem',
      textAlign: 'center',
      padding: '.25em .4em',
      backgroundColor: '#ffc107',
      cursor: 'pointer'
    };
    if (this.state.blacklistdrawer) {
      drawerBlacklistStyle = {
        color: '#212529',
        fontWeight: 'bolder',
        borderRadius: '.25rem',
        textAlign: 'center',
        padding: '.25em .4em',
        backgroundColor: '#cccccc',
        cursor: 'pointer'
      };
    }

    if (blacklistreport.status === 'GenericMaliciousObject' || blacklistreport.domains.length > 0) {
      info = (
        <span style={drawerBlacklistStyle} onClick={() => this.onOpenBlacklistDrawer()}>
          <i className="fas fa-exclamation-triangle" /> Blacklisted{' '}
          {blacklistreport.domains.length >= 2 ? 'Sites ' : 'Site '}
          Detected{' '}
          <i className={this.state.blacklistdrawer ? 'fas fa-caret-up' : 'fas fa-caret-down'} />
        </span>
      );
    }

    return info;
  }

  render() {
    const { blacklistdrawer } = this.state;
    const { history } = this.props;
    const domainHost = history.location.state.domainHost;

    const malwareVulnerabilities = history.location.state.vulnerabilities;
    return (
      <Aux>
        <Header titlePage="Malware Scanned Files" bgColor="#F5F6F8" />

        <div id="mcontent-wrapper">
          <div id="PCI-scan" className="">
            <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
              <div className="inner-ptab tab-content">
                <div id="scan" className="tab-pane fade in active">
                  <p>
                    <a
                      href="/"
                      className="btn btn-secondary btn-sm mb-3"
                      onClick={e => {
                        e.preventDefault();
                        this.props.goToDashboard();
                      }}>
                      <i className="fas fa-chevron-circle-left" />&nbsp; Go Back
                    </a>
                    <br />
                    {/* {this.blacklistInfo()} */}
                    <br />
                    <br />
                    {/* <BlacklistedDomains
                      open={blacklistdrawer}
                      report={history.location.state.blacklistreport}
                      host={history.location.state.domainHost}
                    /> */}
                    Scanned files from malware scanned report of the domain :{' '}
                    <strong>{domainHost} </strong>
                  </p>
                  <br />
                  {malwareVulnerabilities.length > 0 ? (
                    this.displayScannedFilesTable()
                  ) : (
                    <div class="alert alert-success" role="alert">
                      <p>There are no vulnerabilities found.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goToDashboard: () =>
        push('/', {
          accountAdminView: true
        })
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MalwareScannedFiles);
