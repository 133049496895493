import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import ToastMessage from "../../components/toast/toastMessage";
import { viewUserBusinessAccountDashboard } from "../../helpers/general";
import {
  getBusiness,
  getUserById,
  getDomains,
  getBusinesses,
  findUsersInBusiness,
  createNote,
  cancelOrActiveDomain,
  getUserListUsingBusinessId,
  getBusinessDetailsById,
  onClickRemoveTrustSealsSettingsDate,
  updateSidebar,
} from "../../modules/actions/index";
import { callOnGetResultMissingScans } from "../../services/reportService";

class ControlButtons extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      selectedBusinessId: null,
      taskOnDomain: "",
      selectedBusinessName: "",
      selectedBusinessStatusLog: "",
      businessData: null,
      selectedBusiness: null,
      showModal: false,
      modalMessage: "",
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps) {
      if (newProps.business) {
        this.setState({ businessData: newProps.business }, () => {});
      }
    }
  }

  onClickSetBusinessId = (
    businessId,
    taskOnDomain,
    businessName = null,
    businessStatusLog = null
  ) => {
    this.setState({
      selectedBusinessId: businessId,
      taskOnDomain: taskOnDomain,
      selectedBusinessName: businessName,
      selectedBusinessStatusLog: businessStatusLog,
    });

    this.props.getBusinessDetailsById(businessId);
  };

  onClickCancelOrActiveDomain = () => {
    if (this.state.selectedBusinessId) {
      this.props.cancelOrActiveDomain(
        this.state.selectedBusinessId,
        this.props.userId,
        this.state.taskOnDomain
      );
    } else {
    }
  };

  retrieveMissingScans = async (data) => {
    this.setState({
      showModal: true,
      modalMessage:
        "We are retrieving missing scans in the background. Please check with 1 -2 minutes",
    });

    await callOnGetResultMissingScans({
      businessID: data.businessId,
      domainID: null,
    });
    this.setState({ showModal: false });
    this.setState({
      showModal: true,
      modalMessage: "Missing scans successfully retrieved.",
    });
    viewUserBusinessAccountDashboard(data.businessId, data.accountType);
    this.props.goToDashboard();
  };

  viewUserBusinessAccount = (businessId, accountType) => {
    viewUserBusinessAccountDashboard(businessId, accountType);
    this.props.updateSidebar({ showAccountSettingsMenu: true });
    this.props.goToDashboard();
  };

  onClickClearTrustSealsSettingsDate = (businessId) => {
    if (businessId) {
      let query = {
        action: "refresh-seals",
        businessId: businessId,
      };
      this.props.onClickRemoveTrustSealsSettingsDate(query);
    }
  };

  render() {
    const { showModal, modalMessage } = this.state;

    const {
      business,
      populateBusinessUsersData,
      populateDelayModalData,
    } = this.props;

    let notify = "";
    if (showModal) {
      notify = <ToastMessage type="success" notifyMessage={modalMessage} />;
    }
    return (
      <div>
        {notify}
        <div className="dropdown">
          <button
            className="btn btn-success dropdown-toggle"
            type="button"
            id="dropdownMenu1"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Controls{"      "}
            <span
              style={{ position: "relative", left: "3px" }}
              className="caret"
            />
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
            <li className="pointer">
              {business ? (
                <button
                  className="tg-anchor-button"
                  data-toggle="modal"
                  data-target={"#delayfunctionInAdvanced"}
                  onClick={(e) => {
                    populateDelayModalData(business._id);
                  }}
                >
                  Delay Functions
                </button>
              ) : (
                ""
              )}
            </li>
            <li className="pointer">
              {business ? (
                <button
                  className="tg-anchor-button"
                  data-toggle="modal"
                  data-target={"#customerInfo"}
                  onClick={(e) => {
                    e.preventDefault();
                    populateBusinessUsersData(business._id);
                  }}
                >
                  Customer Info/Notes
                </button>
              ) : (
                ""
              )}
            </li>
            <li className="pointer">
              <button className="tg-anchor-button">Account Info/Billing</button>
            </li>
            <li className="pointer">
              {business ? (
                <button
                  className="tg-anchor-button"
                  onClick={(e) => {
                    e.preventDefault();
                    this.retrieveMissingScans({
                      businessId: business._id,
                      accountType: business.accountType,
                    });
                  }}
                  data-toggle="modal"
                  data-target="#refreshModal"
                >
                  Retrieve Missing Scans
                </button>
              ) : (
                ""
              )}
            </li>
            <li className="pointer">
              {business ? (
                <button
                  className="tg-anchor-button"
                  onClick={(e) => {
                    e.preventDefault();
                    populateBusinessUsersData(business._id);
                  }}
                  data-toggle="modal"
                  data-target="#scanDetailsUpdateModal"
                >
                  Modify Scan Details
                </button>
              ) : (
                ""
              )}
            </li>
            <hr className="my-2" />
            <li className="pointer">
              {business ? (
                <button
                  className="tg-anchor-button"
                  data-toggle="modal"
                  data-target="#myModal"
                  onClick={(e) => {
                    e.preventDefault();
                    populateBusinessUsersData(business._id);
                  }}
                >
                  Users Account
                </button>
              ) : (
                ""
              )}
            </li>
            <li
              className="pointer"
              data-toggle="modal"
              data-target={"#cancelDomainInAdv"}
            >
              {business ? (
                <button
                  className="tg-anchor-button"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.populateBusinessUsersData(business._id);
                  }}
                >
                  {business.status === "active" ? "Cancel" : "Activate"} Account
                </button>
              ) : (
                ""
              )}
            </li>

            <li className="pointer">
              {business ? (
                <button
                  className="tg-anchor-button"
                  onClick={(e) => {
                    e.preventDefault();
                    this.viewUserBusinessAccount(
                      business._id,
                      business.accountType
                    );
                  }}
                >
                  View Users Account
                </button>
              ) : (
                ""
              )}
            </li>

            <li className="pointer">
              {business ? (
                <button
                  className="tg-anchor-button"
                  onClick={(e) => {
                    e.preventDefault();
                    populateBusinessUsersData(business._id);
                  }}
                  data-toggle="modal"
                  data-target={"#primarydomain"}
                >
                  Change Primary Domain
                </button>
              ) : (
                ""
              )}
            </li>

            <li className="pointer">
              {business ? (
                <button
                  className="tg-anchor-button"
                  onClick={(e) => {
                    e.preventDefault();
                    this.onClickClearTrustSealsSettingsDate(business._id);
                  }}
                >
                  Refresh Seals
                </button>
              ) : (
                ""
              )}
            </li>
            <li className="pointer">
              {business ? (
                <button
                  className="tg-anchor-button"
                  data-toggle="modal"
                  data-target={"#insuranceDocsModal"}
                  onClick={(e) => {
                    populateDelayModalData(this.props.business._id);
                  }}
                >
                  Insurance Docs XXXX
                </button>
              ) : (
                ""
              )}
            </li>
            <li className="pointer">
              <button
                className="tg-anchor-button"
                data-toggle="modal"
                data-target={"#enable4xAccount"}
                onClick={(e) => {
                  populateBusinessUsersData(business._id);
                }}
              >
                Enable 4X Account
              </button>
            </li>
            <li className="pointer">
              <button
                className="tg-anchor-button"
                data-toggle="modal"
                data-target={"#deleteSeals"}
                onClick={(e) => {
                  populateBusinessUsersData(business._id);
                }}
              >
                Delete Seals
              </button>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    domains: state.accountStore.domains,
    businesses: state.accountStore.businesses,
    businessAccount: state.accountStore.businessAccount,
    userAccount: state.accountStore.userAccount,
    usersInBusiness: state.loginStore.usersInBusiness,
    businessUserList: state.accountStore.businessUserList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getBusiness,
      getUserById,
      getDomains,
      getBusinesses,
      findUsersInBusiness,
      createNote,
      cancelOrActiveDomain,
      getUserListUsingBusinessId,
      getBusinessDetailsById,
      onClickRemoveTrustSealsSettingsDate,
      updateSidebar,
      goToDashboard: () =>
        push("/", {
          accountAdminView: true,
        }),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ControlButtons);
