import React from 'react';
import { Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TableMalwareContent from '../tableMalwareContents';
import {
  callMalwareSearchByBusinessId,
  callOnMalwareScan,
  callFindMalwareVulnerabilityByReportUrl
} from '../../../../services/index';
import AppContext from '../../../../components/context/appContext';
import MalwareReportButton from './buttons/malwareReportButton';
import ModalMalwareVulnerability from '../modals/modalMalwareVunerabilityInfo';
import MalwareRescanModal from '../../../../components/dashboard/partials/modals/modalRescan';
import { malwareRowStatus } from '../../../../helpers/general';
import momentz from 'moment-timezone';

class MalwareSlidePanelData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      malwarehistories: [],
      malwareInfoData: [],
      malwareVulnerabilityResults: [],
      malwareInfoDataNotClean: [],
      scanFrequency: '',
      malwareFrequency: '',
      domainRows: [],
      business: '',
      selectedMalwareHistoryId: null
    };
  }

  getUserBusinessData = () => this.context.userBusinessData[this.props.index];

  hasMalwareRules() {
    const business = this.getUserBusinessData();
    let hasMalwareRuleActive = false;
    if (business.hasOwnProperty('malwareRules')) {
      if (business.malwareRules.length > 0) {
        hasMalwareRuleActive = true;
      }
    }

    return hasMalwareRuleActive;
  }

  async componentDidMount() {
    const business = this.getUserBusinessData();
    const status = business.lastMalwareScanResult;
    const businessId = business._id;
    const malwareData = await callMalwareSearchByBusinessId(businessId);
    this.setState({
      malwarehistories: malwareData.data,
      scanFrequency: business.scanFrequency,
      malwareFrequency: business.malwareFrequency,
      business: business
    });

    const rows = [];
    const domains = [];
    for (let j = 0; j < malwareData.data.length; j++) {
      rows.push({ selectedRow: null, active: false });
    }
    this.setState({ domainRows: rows });

    //check if malwarehistories has data.
    let malwareVulnerabilityResults = null;
    if (this.state.malwarehistories.length > 0) {
      this.state.malwarehistories.forEach(async malware => {
        if (malware.domains.length > 0) {
          const reportUrl = malware.domains[0].reportUrl;
          await this.getMalwareHistoryInfo(malware._id);
        }
      });
    }
  }

  getMalwareHistoryInfo = async (malwareHistoryID, status = null) => {
    // START MALWARE GET DATA
    const business = this.getUserBusinessData();
    this.setState({ selectedMalwareHistoryId: malwareHistoryID });
    const results = await callFindMalwareVulnerabilityByReportUrl(
      malwareHistoryID,
      this.props.businessId
    );

    let malwareData = results.data;

    if (status !== null) {
      const sortedMalwareData = malwareData.filter(malware => malware.malwareStatus === status);
      malwareData = sortedMalwareData;
    }

    this.setState({ malwareInfoData: malwareData });
    // END MALWARE GET DATA
  };

  showInnerTable = index => {
    this.setState(prevState => ({
      domainRows: prevState.domainRows.map(
        (row, idx) => (idx === index ? { ...row, active: !row.active, selectedRow: index } : row)
      )
    }));
  };

  startMalwareScan = async businessID => {
    let businessId = businessID;
    if (businessID === undefined) {
      const business = this.getUserBusinessData();
      businessId = business._id;
    }
    let createMalwareScan = {
      action: 'create_malware_scan',
      businessID: businessId,
      scanType: 'manual'
    };
    await callOnMalwareScan(createMalwareScan).then(response => {
      window.location.reload();
    });
  };

  render() {
    const {
      malwarehistories,
      scanFrequency,
      malwareFrequency,
      malwareInfoData,
      domainRows,
      selectedMalwareHistoryId,
      business
    } = this.state;
    const { index } = this.props;
    let styleStatus = null;
    let iconStatus = null;
    return (
      <TableMalwareContent>
        {malwarehistories.map((malware, i) => {
          const status = malware.scanStatus;

          const currentStatus =
            status === 'potentially suspicious' || status === 'suspicious' ? 'Suspicious' : status;
          const malwareStatus = malwareRowStatus(status);

          const modalMalwareRescanButton =
            i === 0 ? (
              <MalwareRescanModal
                index={i}
                businessId={malware.businessId}
                rescan={this.startMalwareScan}
                status={status}
              />
            ) : null;

          return (
            <>
              <tr key={i}>
                <td />
                <td>
                  {malware.scanStatus !== 'undef'
                    ? momentz(malware.scanStart)
                        .tz(
                          business.pciScanTimeZone !== undefined &&
                          business.pciScanTimeZone !== null &&
                          business.pciScanTimeZone !== ''
                            ? business.pciScanTimeZone
                            : 'America/Chicago'
                        )
                        .format('MM/DD/YYYY hh:mm a')
                    : 'N/A'}
                </td>
                <td style={{ textTransform: 'capitalize' }}>{malwareFrequency}</td>
                <td>
                  <Route
                    render={({ history }) => (
                      <span
                        style={malwareStatus.styleStatus}
                        data-toggle="modal"
                        data-target={'#malware-modal-' + malware._id}
                        onClick={() => this.getMalwareHistoryInfo(malware._id, status)}>
                        <i class={malwareStatus.iconStatus} /> &nbsp;
                        {currentStatus}
                      </span>
                    )}
                  />
                </td>
                <td>
                  <MalwareReportButton
                    status={malware.scanStatus}
                    targetID={malware._id}
                    getMalwareInfo={this.getMalwareHistoryInfo}
                  />
                </td>
                <td style={{ textAlign: 'center' }}>{malware.domains.length}</td>

                <td style={{ display: 'flex', justifyContent: 'center' }}>
                  {malware.scanStatus === 'running' || malware.scanStatus === 'undef'
                    ? null
                    : modalMalwareRescanButton}
                </td>
              </tr>
              <ModalMalwareVulnerability
                malwareInfoData={malwareInfoData}
                targetID={malware._id}
                sentForReview={malware.sentForReview}
                index={index}
                selectedMalwareHistoryId={selectedMalwareHistoryId}
              />
            </>
          );
        })}
      </TableMalwareContent>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
MalwareSlidePanelData.contextType = AppContext;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MalwareSlidePanelData);
