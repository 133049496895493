import { api } from './api';
import { callApi } from '../helpers/index';
import { validateDomain } from '../helpers/general';

export const callApiGetUserBusinessData = async data => {
  return await callApi().then(token => {
    if (data && data.userId) {
      return api.service('api/user-businesses').get(data.userId);
    } else {
      return null;
    }
  });
};

export const callApiGetBusinessData = async businessId => {
  return callApi().then(token => {
    if (businessId) {
      return api.service('business').get(businessId);
    } else {
      return null;
    }
  });
};

export const callApiGetBusinessDataByPrimaryDomain = async primaryDomain => {
  return callApi().then(token => {
    if (primaryDomain) {
      return api.service('business').find({ query: { primaryDomain: primaryDomain } });
    } else {
      return null;
    }
  });
};

export const callApiGetBusinessStatistics = async data => {
  return callApi().then(token => {
    if (data) {
      return api.service('api/business').get('business-statistics', {
        query: {
          businessId: data.businessId,
          authentication: {
            accessToken: token
          }
        }
      });
    } else {
      return null;
    }
  });
};

export const callApiUpdateBusinessData = async (businessId, data) => {
  return callApi().then(token => {
    if (businessId) {
      return api.service('api/business').patch(businessId, data);
    } else {
      return null;
    }
  });
};

export const callApiDeleteFreeCloneBusinessData = async (businessId, data) => {
  return callApi().then(token => {
    if (businessId) {
      return api.service('api/business').patch(businessId, {
        businessId: businessId,
        purpose: 'delete_free_account'
      });
    } else {
      return null;
    }
  });
};

export const callApiGetBusinessUsersList = async data => {
  return callApi().then(token => {
    if (data && data.hasOwnProperty('businessId') && data.businessId !== null) {
      return api.service('api/business').get('users-list', {
        query: {
          businessId: data.businessId
        }
      });
    } else {
      return null;
    }
  });
};

export const callApiGetBusinessPrimaryUser = async data => {
  return callApi().then(token => {
    if (data && data.hasOwnProperty('businessId') && data.businessId !== null) {
      return api.service('api/business').get('get-business-primary-user', {
        query: {
          businessId: data.businessId
        }
      });
    } else {
      return null;
    }
  });
};

export const callApiGetBusinessSslStatus = async data => {
  return callApi().then(token => {
    if (data) {
      return api.service('api/ssls').get('business-ssl-status', {
        query: {
          businessId: data.businessId
        }
      });
    } else {
      return null;
    }
  });
};

export const callApiGetBusinessSslData = async data => {
  return callApi().then(token => {
    if (data) {
      return api.service('api/ssls').get('business-ssl-data', {
        query: {
          businessId: data.businessId
        }
      });
    } else {
      return null;
    }
  });
};

export const callOnUpdateBusinessReportOptions = data => {
  return callApi().then(token => {
    console.log('data: ', data.availableReports.scanReport);
    const id = data._id;
    const record = {
      hasDarkWebEnabled: data.availableReports.scanReport.darkweb
    };

    return api.service('business').patch(id, record);
  });
};

export const callOnUpdateBusinessFrequency = data => {
  return callApi().then(async token => {
    const id = data._id;
    const business = data.business;
    business['_id'] = id;
    const userId = data.userId;
    const scanTimeZone =
      data.timeZone !== null || data.timeZone !== undefined ? data.timeZone : 'America/Denver';
    const scanTimeHour = data.timeHour !== null || data.timeHour !== undefined ? data.timeHour : 8;

    const record = {
      cloneInfo: business.scanVendor.clone,
      domain: business.primaryDomain,
      scanFrequency: data.scanFrequency,
      pciScanTimeZone: scanTimeZone,
      pciScanTime: scanTimeHour,
      pciNextScheduleScanDate: data.pciNextScheduleScanDate,
      purpose: 'update_scan_time'
    };
    const updateFrequencyAction = await api.service('beyondsecurity').update(1, business);

    await api.service('users').patch(userId, {
      timezone: scanTimeZone,
      pciScanTime: scanTimeHour
    });
    await api.service('api/business').patch(id, record);
    // api.service("business").patch(id, record);
    // if (!updateFrequencyAction.success) {
    //   return updateFrequencyAction;
    // }
  });
};

export const callApiGetAdvanceSearchBusinessData = async businessQuery => {
  return callApi().then(token => {
    if (businessQuery) {
      let tempQuery;

      switch (businessQuery.action) {
        case 'host':
          tempQuery = {
            status: businessQuery.status,
            limit: businessQuery.limit,
            checkInvalid: businessQuery.checkInvalid,
            host: businessQuery.searchText
          };
          break;
        case 'business-id':
          tempQuery = {
            status: businessQuery.status,
            limit: businessQuery.limit,
            checkInvalid: businessQuery.checkInvalid,
            businessId: businessQuery.searchText
          };
          break;
        case 'email':
          tempQuery = {
            status: businessQuery.status,
            limit: businessQuery.limit,
            checkInvalid: businessQuery.checkInvalid,
            email: businessQuery.searchText
          };
          break;
        case 'phone':
          tempQuery = {
            status: businessQuery.status,
            limit: businessQuery.limit,
            checkInvalid: businessQuery.checkInvalid,
            phone: businessQuery.searchText
          };
          break;
        case 'name':
          tempQuery = {
            status: businessQuery.status,
            limit: businessQuery.limit,
            checkInvalid: businessQuery.checkInvalid,
            name: businessQuery.searchText
          };
          break;
        case 'created-at':
          tempQuery = {
            startDate: businessQuery.startDate,
            endDate: businessQuery.endDate,
            status: businessQuery.status,
            limit: businessQuery.limit,
            checkInvalid: businessQuery.checkInvalid,
            createdAt: businessQuery.searchText
          };
          break;
        case 'type':
          tempQuery = {
            status: businessQuery.status,
            limit: businessQuery.limit,
            checkInvalid: businessQuery.checkInvalid,
            accountType: businessQuery.searchText
          };
          break;
        default:
          tempQuery = {
            status: businessQuery.status,
            limit: businessQuery.limit,
            checkInvalid: businessQuery.checkInvalid
          };
          break;
      }
      return api.service('api/business').get(businessQuery.action, {
        query: tempQuery
      });
    } else {
      return null;
    }
  });
};

export const callApiCreateBusinessData = data => {
  return callApi().then(token => {
    return api.service('api/signup').create(data);
  });
};

export const callApiCreateBusinessUserData = (businessId, data) => {
  return callApi().then(token => {
    return api.service('api/business').patch(businessId, data);
  });
};

export const callApiEnableSslRenewButton = (businessId, data) => {
  return callApi().then(token => {
    return api.service('api/business').patch(businessId, data);
  });
};

export const callApiUpdateOnboardingPopupData = (businessDetails, status) => {
  return callApi().then(async token => {
    let newCount = 0,
      newStatus = status;
    for (let i = 0; i < businessDetails.length; i++) {
      let business = await api.service('business').get(businessDetails[i].businessId);

      if (business && !business.hasOwnProperty('onboardingPopup')) {
        newCount = 1;
      } else {
        if (business.onboardingPopup.status === 'view') {
          newCount =
            status === 'clicked' || status === 'closed'
              ? business.onboardingPopup.views
              : business.onboardingPopup.views + 1;
        }
      }

      api.service('business').patch(businessDetails[i].businessId, {
        onboardingPopup: {
          views: newCount,
          status: newStatus
        }
      });

      return true;
    }
  });
};

export const callApiDeleteBusinessUser = (userId, businessId) => {
  return callApi().then(token => {
    let payloadQuery = {
      userId: userId,
      businessId: businessId
    };

    return api.service('api/business').get('remove-business-user', {
      query: payloadQuery
    });
  });
};

export const callApiCreateBusinessNotes = payload => {
  return callApi().then(token => {
    return api.service('api/business').get('create-note', {
      query: payload
    });
  });
};

export const callApiGetAdminEmails = businessId => {
  return callApi().then(token => {
    return api
      .service('email-history')
      .find({ query: { businessId: businessId, fromAdmin: 'yes' } });
  });
};

export const callApiModifyBusinessScanStatus = (id, status, note, userId) => {
  return callApi().then(async token => {
    const query = {
      query: {
        businessId: id,
        scanDate: { $ne: null },
        status: { $nin: ['pending', 'running', 'finalizing'] },
        $sort: { createdAt: -1 },
        $limit: 1
      }
    };

    const scanResult = await api.service('scanhistory').find(query);

    if (scanResult && scanResult.data && scanResult.data.length > 0) {
      await api.service('scanhistory').patch(
        scanResult.data[0]._id,
        {
          status: status
        },
        query
      );
    }

    const user = await api.service('users').get({ _id: userId });

    if (user) {
      const scanStatusDetails = {
        currentStatus: status,
        previousStatus: status === 'pass' ? 'fail' : 'pass',
        notes: note,
        changedByUser: user.firstName + ' ' + user.lastName,
        updatedAt: new Date()
      };

      await api.service('business').patch(id, {
        pciStatus: status,
        lastPciStatus: status,
        $push: { scanDetailsLog: scanStatusDetails }
      });
    }
  });
};

export const callApiUpdateBusinessPrimaryDomain = data => {
  return callApi().then(async token => {
    const id = data._id;

    let domainData = await api.service('domains').find({ query: { businessId: data._id } });

    await api.service('domains').patch(domainData.data[0]._id, { host: data.primaryDomain });

    const params = {
      primaryDomain: data.primaryDomain,
      pciProgressStatus: null
    };

    let newBusinessNameDomain = data.businessName;

    const businessNameIsURL = validateDomain(data.businessName);

    if (businessNameIsURL) {
      newBusinessNameDomain = data.primaryDomain;
      Object.assign(params, { businessName: newBusinessNameDomain });
    }

    return api.service('business').patch(data._id, params);
  });
};

export const callApiCancelOrActivateBusiness = (id, data) => {
  return callApi().then(token => {
    return api.service('business').patch(id, data);
  });
};

export const callApiBusinessBySchedule = data => {
  return callApi().then(token => {
    return api.service('business').find({
      query: {
        pciScanTime: data.pciScanTime,
        pciScanTimeZone: data.pciScanTimeZone,
        status: 'active'
      }
    });
  });
};

export const callApiBusinessUpdateBySchedule = data => {
  return callApi().then(token => {
    const id = data._id;
    const record = {
      purpose: 'update_bs_schedule',
      pciScanTime: data.pciScanTime,
      pciScanTimeZone: data.pciScanTimeZone
    };

    return api.service('api/business').patch(id, record);
  });
};

export const callApiBusinessFrontlineMigration = business => {
  return callApi().then(token => {
    const id = business._id;
    const data = business;
    const record = {
      id: id,
      data: data,
      purpose: 'frontline_migration'
    };

    return api.service('api/business').patch(id, record);
  });
};

export const callApiBusinessUpdateFalsePositive = business => {
  return callApi().then(token => {
    const data = business;
    const record = {
      data: data,
      purpose: 'false_positive_update'
    };

    return api.service('api/business').patch(null, record);
  });
};

export const callApiBusinessAllNotMigrated = data => {
  return callApi().then(token => {
    return api.service('business').find({
      query: {
        migrated: { $exists: false },
        accountType: { $ne: 'free' },
        status: { $ne: 'canceled' },
        $sort: { createdAt: -1 }
      }
    });
  });
};
