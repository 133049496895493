import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

import {
  callApiRefreshTrustSealsData,
  callApiGetBusinessData,
  callApiDeleteFreeCloneBusinessData,
} from "../../services";

import {
  cancelOrActivateBusiness,
  cancelOrActivateSkippedActionByMember,
} from "../../modules/actions";

import AppContext from "../../components/context/appContext";

class ActivateOrCancelBusinessModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      userId: "",
      businessId: "",
      business: {},
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    const { userData, currentBusinessId } = this.context;
    if (prevState.businessId !== currentBusinessId) {
      const businessData = await callApiGetBusinessData(currentBusinessId);

      this.setState({
        userId: userData._id,
        businessId: currentBusinessId,
        business: businessData,
      });
    }
  }

  cancelOrActivateSkippedActionByMember = async (businessId, userId) => {
    this.props.cancelOrActivateSkippedActionByMember(businessId, userId);
  };

  cancelOrActivateBusiness = async (businessId, userId, currentStatus) => {
    this.props.cancelOrActivateBusiness(businessId, userId, currentStatus);
    if (currentStatus === "active") {
      console.log("current status: ", currentStatus);

      await callApiDeleteFreeCloneBusinessData(businessId);
    }
  };

  render() {
    const { userId, businessId, business } = this.state;
    let hideScrollBar = {
      overflowY: "hidden",
    };

    if (business && business.statusLog && business.statusLog.length > 0) {
      hideScrollBar = {
        overflowY: "scroll",
      };
    }

    return (
      <div
        id="activateOrCancelBusinessModal"
        className="modal"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="scrollbar" id="style-1" style={hideScrollBar}>
              <div className="force-overflow">
                <div className="modal-header">
                  <h4 className="modal-title f800">
                    {business
                      ? business.status === "active"
                        ? "Cancel"
                        : "Activate"
                      : ""}{" "}
                    <b>{business ? business.businessName : ""}</b>
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="closeModal"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="col-lg-12 col-md-12 text-left p-0">
                    <p>
                      Are you sure want to{" "}
                      {business
                        ? business.status === "active"
                          ? "cancel"
                          : "activate"
                        : ""}{" "}
                      <b>{business ? business.businessName : ""}</b>
                    </p>
                  </div>
                  <div className="col-lg-12 col-md-12 text-left p-0">
                    <hr />
                  </div>
                  <div className="col-lg-12 col-md-12 text-left p-0">
                    {business &&
                    business.statusLog &&
                    business.statusLog.length > 0 ? (
                      <>
                        <h4>History</h4>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>
                                <label>Current Status</label>
                              </th>
                              <th>
                                <label>New Status</label>
                              </th>
                              <th>
                                <label>Notes</label>
                              </th>
                              <th>
                                <label>User</label>
                              </th>
                              <th>
                                <label>Action Date</label>
                              </th>
                              <th>
                                <label>Skipped Member ID</label>
                              </th>
                              <th>
                                <label>Action</label>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {business
                              ? business.statusLog.length > 0
                                ? business.statusLog.map((v, i) => {
                                    return (
                                      <tr
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        <td>
                                          {v.currentStatus
                                            .charAt(0)
                                            .toUpperCase() +
                                            v.currentStatus.slice(1)}
                                        </td>
                                        <td>
                                          {v.newStatus.charAt(0).toUpperCase() +
                                            v.newStatus.slice(1)}
                                        </td>
                                        <td>{v.notes}</td>
                                        <td>{v.user}</td>
                                        <td>
                                          {moment(v.actionDate).format("LL")}
                                        </td>
                                        <td>
                                          {v.skippedMemberId === null
                                            ? "N/A"
                                            : v.skippedMemberId}
                                        </td>
                                        <td>
                                          {v.currentStatus !== "completed" ? (
                                            <button
                                              className="btn btn-sm btn-danger"
                                              onClick={() =>
                                                this.cancelOrActivateSkippedActionByMember(
                                                  businessId,
                                                  userId
                                                )
                                              }
                                              data-dismiss="modal"
                                              disabled={
                                                v.currentStatus === "pending"
                                                  ? true
                                                  : false
                                              }
                                            >
                                              Cancel
                                            </button>
                                          ) : (
                                            "N/A"
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })
                                : ""
                              : ""}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="modal-footer text-right">
                  <button
                    type="button"
                    className="btn btn-danger btn-140-width btn-md"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="btn-success btn-md pull-right btn-140-width"
                    data-dismiss="modal"
                    onClick={() => {
                      this.cancelOrActivateBusiness(
                        businessId,
                        userId,
                        business.status
                      );
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ActivateOrCancelBusinessModal.contextType = AppContext;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { cancelOrActivateBusiness, cancelOrActivateSkippedActionByMember },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivateOrCancelBusinessModal);
