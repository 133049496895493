import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SlideContext from "../../context/slidePanelContexts";
import AppContext from "../../context/appContext";
import DarkWebScanStatusNotification from "../darkWebScanStatusNotification";

import { callApiGetBusinessData } from "../../../services";

class DarkWebResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      userBusinessData: [],
      business: {},
      darkWebScanLinkClass: "tg-dashboard-passed-link pciscan-toggle",
      darkWebScanStyle: {},
      displayArrowDown: "none",
      displayArrowUp: "none",
      isDarkWebActive: null
    };
  }

  async componentDidMount() {
    this.pciScanDecision();
    this.slideArrowUpDownDecision();
  }

  componentWillReceiveProps(props) {
    const { businessId, index } = this.props;
    const { darkweb } = this.context;

    const darkWebContextBusinessId = darkweb[index].businessId;
    const darkWebContextIsActive = darkweb[index].isDarkWebActive;

    if (darkweb.length === 1) {
      if (darkWebContextIsActive) {
        this.setState({
          isDarkWebActive: darkWebContextIsActive,
          displayArrowDown: "none",
          displayArrowUp: "block"
        });
      } else {
        this.setState({ isDarkWebActive: !darkWebContextIsActive });
      }
    } else {
      if (businessId === darkWebContextBusinessId) {
        this.setState({ isDarkWebActive: darkWebContextIsActive });
      }
    }

    this.setState({ isDarkWebActive: darkWebContextIsActive });
    this.checkDarkWebPanelisActive();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isDarkWebActive !== this.state.isDarkWebActive) {
      this.checkDarkWebPanelisActive();
    }
  }

  checkDarkWebPanelisActive() {
    const { isDarkWebActive } = this.state;

    if (isDarkWebActive && isDarkWebActive !== null) {
      this.setState({
        displayArrowDown: "none",
        displayArrowUp: "block"
      });
    } else {
      this.setState({
        displayArrowDown: "block",
        displayArrowUp: "none"
      });
    }
  }

  async pciScanDecision() {
    const businessData = await callApiGetBusinessData(this.props.businessId);
    this.setState({
      business: businessData
    });

    if (businessData.lastPciStatus === "fail" || businessData.lastPciStatus === "invalid") {
      this.setState({
        darkWebScanLinkClass: "tg-dashboard-failed-link pciscan-toggle",
        darkWebScanStyle: { color: "#C01818" }
      });
    }
  }

  async slideArrowUpDownDecision() {
    // const userBusinessData = await callApiGetUserBusinessData(getUserDecryptedData());
    const { index } = this.props;
    const { isDarkWebActive } = this.state;

    console.log("isDarkWebActive:", isDarkWebActive);

    if (isDarkWebActive) {
      this.setState({
        displayArrowDown: "none",
        displayArrowUp: "block"
      });
    } else {
      this.setState({
        displayArrowDown: "block",
        displayArrowUp: "none"
      });
    }
  }

  render() {
    const {
      isLoading,
      business,
      darkWebScanLinkClass,
      darkWebScanStyle,
      displayArrowUp,
      displayArrowDown
    } = this.state;
    const darkWebContext = this.context;

    const { businessId, index } = this.props;

    return (
      <AppContext.Consumer>
        {value => {
          return (
            <SlideContext.drawerContext.Consumer>
              {drawer => {
                return drawer.darkweb[index] !== undefined ? (
                  <button
                    className="tg-dashboard-passed-link pciscan-toggle"
                    onClick={() => drawer.darkweb[index].openPciPanel(businessId, index)}>
                    <DarkWebScanStatusNotification
                      business={value.userBusinessData[index]}
                      scanning={drawer.darkweb[index].isPciScanning}
                      darkweb
                    />
                    <i
                      className="false pci fas fa-chevron-down hidden-sm hidden-xs"
                      aria-hidden="true"
                      style={{
                        display: displayArrowDown
                      }}
                    />
                    <i
                      className="pci-arrow-ident fas fa-sort-up hidden-sm hidden-xs"
                      aria-hidden="true"
                      style={{
                        display: displayArrowUp
                      }}
                    />
                  </button>
                ) : null;
              }}
            </SlideContext.drawerContext.Consumer>
          );
        }}
      </AppContext.Consumer>
    );
  }
}
const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

DarkWebResult.contextType = SlideContext.drawerContext;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DarkWebResult);
