import timeZoneData from './timeZoneData.json';
import moment from 'moment-timezone';

function convertTimeTo(date, timeZone, format, label = true) {
  moment.tz.add(timeZoneData);
  moment.tz.setDefault('UTC');
  const timezone =
    timeZone !== '' && timeZone !== undefined && timeZone !== null ? timeZone : 'America/Denver';
  const formatDate = format !== undefined && format !== null ? format : 'MM/DD/YYYY hh:mm a';
  if (date !== undefined) {
    const utcDate = moment.utc(date);
    if (utcDate !== undefined || utcDate !== undefined) {
      const displayUserTimeZoneDateFormat = utcDate.clone().tz(timezone);

      return displayUserTimeZoneDateFormat.format(formatDate);
    }
  }
}

function convert24HourFormat(userTime) {
  if (userTime === null) {
    return '9 am';
  }

  let inputTime = userTime;
  let timeMeridiam = 'am';

  if (inputTime !== undefined) {
    const timeArray = inputTime.split(':');
    let hour = parseInt(timeArray[0]);
    let minute = timeArray[1];

    if (hour >= 12) {
      timeMeridiam = 'pm';
      if (hour > 12) {
        hour -= 12;
      }
    } else if (hour === 0) {
      hour = 12;
    }

    return `${hour}:${minute} ${timeMeridiam}`;
  }
}

export { convertTimeTo, convert24HourFormat };
