import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class TrustSealsInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        id="modalSealInfo"
        className="modal"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        data-keyboard="false">
        <div className="modal-dialog " role="document">
          <div className="modal-content col-md-12">
            <div className="modal-header">
              <h4 className="modal-title">Seal Changed!</h4>
              <button type="button" className="close-new" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" style={{ position: 'relative', top: '0px' }}>
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <h5 style={{ fontSize: '16px' }}>
                  WIthin about 1 minute the seal should update on your website (you may need to
                  refresh the page).
                  <br />
                  <br />
                  Because the seal placement has changed it is possible that the seal will no longer
                  be displayed correctly until you repaste the code onto your website. Please check
                  and verify it is correct or repaste the code to your website.
                  <br />
                  <br />
                  Note you can always set it back to the previous type.
                </h5>
              </div>
            </div>
            <div className="modal-footer text-right pt-4">
              <button
                id="btnHideModalSealInfo"
                type="button"
                className="btn-tglight2 btn btn-md mr-2"
                data-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrustSealsInfo);
