import React, { useState } from "react";
import Slide from "../../../../components/animation/slide";
import SlideContext from "../../../../components/context/slidePanelContexts";
function MalwareSlidePanel({ index, businessId, children }) {
	return (
		<SlideContext.drawerContext.Consumer>
			{(value) => {
				let willOpen = false;
				if (value.malware[index] !== undefined) {
					if (value.malware.length === 1) {
						willOpen = value.malware[index].isMalwareActive;
					} else {
						willOpen = value.malware[index].isMalwareActive
							? businessId === value.malware[index].businessId
								? true
								: false
							: false;
					}
				}

				return (
					<Slide open={willOpen}>
						<div
							id="malscan-details"
							style={{
								display: "block",
								position: "relative",
								background: "#ffffff",
							}}
						>
							{children}
						</div>
					</Slide>
				);
			}}
		</SlideContext.drawerContext.Consumer>
	);
}

export default MalwareSlidePanel;
