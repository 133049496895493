import React, { useState, useEffect, useRef, useContext } from "react";
import { callOnGetScanProgress } from "../../services/businessServiceCopy";
import Spinner from "../../components/ui/spinner/spinnerRectScan";
import moment from "moment";
import momentz from "moment-timezone";
import { getUserDecryptedData } from "../../helpers/general.js";
import SlidePanelContext from "../context/slidePanelContexts";
import _ from "lodash";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(
    () => {
      savedCallback.current = callback;
    },
    [callback]
  );

  useEffect(
    () => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    },
    [delay]
  );
}

function spinner(business, owner, status, scanning, data) {
  let iconStatus = <i className="fa fa-clock h1-icon" style={{ color: "rgb(127,127,127)" }} />;
  if (business.pciProgressStatus !== null && business.pciProgressStatus !== undefined) {
    iconStatus =
      business.pciProgressStatus === "invalid" ? (
        <i className="far fa-exclamation-circle h1-icon" style={{ color: "#C01818" }} />
      ) : (
        <i className="fa fa-clock h1-icon" style={{ color: "rgb(127,127,127)" }} />
      );
  } else {
    if (owner !== null && status !== undefined) {
      iconStatus =
        status === "running" || status === "finalizing" ? (
          <>
            <Spinner /> <br />
          </>
        ) : status === "pass" ? (
          <i className="fa fa-check-circle h1-icon" style={{ color: "#33981f" }} />
        ) : status === "fail" ? (
          <i className="fa fa-exclamation-circle h1-icon" style={{ color: "#C01818" }} />
        ) : status === "invalid" ? (
          <i className="far fa-exclamation-circle h1-icon" style={{ color: "#C01818" }} />
        ) : (
          <i className="fa fa-clock h1-icon" style={{ color: "rgb(127,127,127)" }} />
        );
    }
  }

  return iconStatus;
}

function pciTextStatus(
  progress,
  business,
  owner,
  status,
  createdAt,
  scanning,
  scanDate,
  isLoading,
  hasScanHistories
) {
  let pciScanInfo = "Loading...";
  let breakStatus = (
    <>
      <br />
      <br />
    </>
  );
  let styleStatus = {
    color: "rgb(127, 127, 127)",
    fontSize: "12px"
  };
  if (scanning) {
    pciScanInfo = "Creating Scan";
  } else {
    let timeZone = null;
    if (business.hasOwnProperty("pciScanTimeZone")) {
      timeZone = business.pciScanTimeZone !== null ? business.pciScanTimeZone : "America/Chicago";
    } else {
      timeZone = "America/Chicago";
    }

    if (!isLoading) {
      if (hasScanHistories) {
        const userData = getUserDecryptedData();
        if (userData !== undefined) {
          timeZone = userData.hasOwnProperty("timezone") ? userData.timezone : timeZone;
        }
        // console.log("timeZone 127: ", timeZone);

        if (owner !== null) {
          if (business.pciProgressStatus !== null && business.pciProgressStatus !== undefined) {
            if (business.pciProgressStatus === "invalid") {
              pciScanInfo = "Invalid URL";
              styleStatus = {
                color: "#C01818"
              };
            }
          } else {
            // console.log("timeZone 141: ", timeZone);

            if (owner !== null) {
              if (status !== null && status !== undefined) {
                let recentScanDate = "";
                if (status !== "running" && status !== "finalizing") {
                  recentScanDate = momentz(scanDate)
                    .tz(timeZone)
                    .format("MM/DD/YYYY");
                }

                const scanStat = _.upperFirst(status.toString());
                pciScanInfo =
                  status === "running" || status === "finalizing"
                    ? scanStat
                    : status === "pass"
                      ? `${status}ed ${recentScanDate !== undefined ? recentScanDate : ""}`
                      : `${status}ed  ${recentScanDate !== undefined ? recentScanDate : ""}`;

                if (status === "running" || status === "finalizing") {
                  breakStatus = null;
                }

                if (status === "fail") {
                  styleStatus = {
                    color: "#C01818",
                    fontSize: "12px"
                  };
                }

                if (status === "running" || status === "finalizing" || status === "pass") {
                  styleStatus = {
                    color: "#33981f",
                    fontSize: "12px"
                  };
                }

                if (status === "pending") {
                  pciScanInfo = "Scan Pending";
                }

                if (status === "invalid") {
                  pciScanInfo = "Invalid URL";
                  styleStatus = {
                    color: "#C01818",
                    fontSize: "12px"
                  };
                }
              } else {
                pciScanInfo = "Loading...";
              }

              return (
                <>
                  {breakStatus}
                  <span style={styleStatus}>{_.upperFirst(pciScanInfo)}</span>
                </>
              );
            }
          }
        }
      } else {
        pciScanInfo = "Never Scanned";
      }
    } else {
      if (business.pciProgressStatus === "invalid") {
        pciScanInfo = "Invalid URL";
      }
    }
  }

  return (
    <>
      <span
        data-testid="pciScanStatus"
        style={{
          color: "rgb(127, 127, 127)",
          display: "block",
          clear: "both",
          fontSize: "12px",
          fontWeight: "200",
          marginTop: "25px"
        }}>
        {_.upperFirst(pciScanInfo)}
      </span>
    </>
  );
}

function DarkWebScanStatusNotification({ business, scanning }) {
  const [progress, setProgress] = useState(0);
  const [interval, setTimeInterval] = useState(5000);
  const [owner, setOwner] = useState(null);
  const [status, setStatus] = useState(null);
  const [scandata, setData] = useState(0);
  const [createdAt, setDateCreated] = useState(null);
  const [scanDate, setScanDate] = useState(null);
  const [scans, setScans] = useState(false);
  const [isPciScanning, setisPciScanning] = useState(false);
  const [hasScanHistories, setHasScanHistories] = useState(false);
  const [isLoading, setScanLoading] = useState(true);
  const [doneScanning, setDoneScanning] = useState(true);
  const slidePanelContext = useContext(SlidePanelContext);
  useEffect(
    () => {
      async function getScanResult() {
        const scan = await callOnGetScanProgress(business._id, true, true);
        if (scan.owner !== null) {
          setProgress(scan.progressInit);
          setOwner(scan.owner);
          setStatus(scan.status === "ready" ? "running" : scan.status);
          setData(scan.data);
          setDateCreated(scan.createdAt);
          setScanDate(scan.scanDate === null ? scan.createdAt : scan.scanDate);
          setHasScanHistories(true);
        }
        setScanLoading(false);
      }
      setisPciScanning(scanning);
      getScanResult();
    },
    [progress]
  );

  return (
    <>
      {spinner(business, owner, status, scanning, scandata)}
      {pciTextStatus(
        progress,
        business,
        owner,
        status,
        createdAt,
        scanning,
        scanDate,
        isLoading,
        hasScanHistories
      )}
    </>
  );
}
export default DarkWebScanStatusNotification;
