import React from "react";
import DelayFunctionModal from "../modals/delayFunctionModal";
import CustomerInfoNoteModal from "../modals/customerInfoNoteModal";
import DeleteTrustsealsModal from "../modals/deleteTrustsealsModal";
import EnableBuyerProtectionModal from "../modals/enableBuyerProtectionModal";
import AdminRefreshSealsModal from "../modals/adminRefreshSealsModal";
import RetriveMissingScansModal from "../modals/retriveMissingScansModal";
import ModifyScanDetailsModal from "../modals/modifyScanDetailsModal";
import PrimaryDomainUpdateModal from "../modals/primaryDomainUpdateModal";
import InsuranceDocumentsModal from "../modals/insuranceDocumentsModal";
import ActivateInsuranceModal from "../modals/activateInsuranceModal";
import ConfirmFrontlineMigrationModal from "../modals/confirmFrontlineMigrationModal";
import ActivateOrCancelBusinessModal from "../modals/activateOrCancelBusinessModal";
import DeleteBusinessAccountModal from "../modals/deleteBusinessAccountModal";
import AddSealUrlModal from "../modals/addSealUrlModal";
import AccountSecurityModal from "../modals/accountSecurityModal";
import RstPasCnfMdl from "../modals/rstPasCnfMdl";
import LckUlckUsrCnfMdl from "../modals/lckUlckUsrCnfMdl";
import SntEmlMdl from "../modals/sntEmlMdl";
import AdminEnableSslAutoRenewButtonModal from "../modals/adminEnableSslAutoRenewButtonModal";
import RescanConformationModal from "../modals/rescanConformationModal";
import ActivateDarkWebModal from "../modals/activateDarkWebModal";
import DeActivateDarkWebModal from "../modals/deActivateDarkWebModal";

class ControlPanelModals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resetPasswordEmail: "",
      userData: {
        userId: null,
        userStatus: "",
        email: ""
      },
      rePopulateUserData: false
    };
  }

  setEmailForResettingPassword = email => {
    this.setState({
      resetPasswordEmail: email
    });
  };

  setUserData = payload => {
    this.setState({
      rePopulateUserData: false,
      userData: {
        userId: payload.userId,
        userStatus: payload.userStatus,
        email: payload.email
      }
    });
  };

  rePopulateUserData = () => {
    this.setState({
      rePopulateUserData: true
    });
  };

  setUserForEmailList = userId => {
    this.setState({
      userData: {
        userId: userId
      }
    });
  };

  render() {
    const { resetPasswordEmail, userData, rePopulateUserData } = this.state;

    return (
      <>
        <RescanConformationModal />
        <DelayFunctionModal />
        <CustomerInfoNoteModal />
        <DeleteTrustsealsModal />
        <EnableBuyerProtectionModal />
        <AdminRefreshSealsModal />
        <RetriveMissingScansModal />
        <ModifyScanDetailsModal />
        <PrimaryDomainUpdateModal />
        <InsuranceDocumentsModal />
        <ActivateInsuranceModal />
        <ActivateDarkWebModal />
        <DeActivateDarkWebModal />
        <ActivateOrCancelBusinessModal />
        <DeleteBusinessAccountModal />
        <AddSealUrlModal />
        <AdminEnableSslAutoRenewButtonModal />
        <AccountSecurityModal
          setEmailForResettingPassword={this.setEmailForResettingPassword}
          setUserData={this.setUserData}
          rePopulateUserData={rePopulateUserData}
          setUserForEmailList={this.setUserForEmailList}
        />
        <SntEmlMdl userId={userData.userId} />
        <RstPasCnfMdl resetPasswordEmail={resetPasswordEmail} />
        <LckUlckUsrCnfMdl userData={userData} rePopulateUserData={this.rePopulateUserData} />

        <button
          id="btnOpnAccScrtMdl"
          style={{ display: "none" }}
          data-toggle="modal"
          data-target="#accountSecurityModal"
        />

        <button
          id="btnOpnRstPasCnfMdl"
          style={{ display: "none" }}
          data-toggle="modal"
          data-target="#rstPasCnfMdl"
        />

        <button
          id="btnOpnLckUlckUsrCnfMdl"
          style={{ display: "none" }}
          data-toggle="modal"
          data-target="#lckUlckUsrCnfMdl"
        />

        <button
          id="btnOpnSntEmlMdl"
          style={{ display: "none" }}
          data-toggle="modal"
          data-target="#sntEmlMdl"
        />
      </>
    );
  }
}

export default ControlPanelModals;
