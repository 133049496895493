import { api } from './api';
import { callApi } from '../helpers/index';

function callOnPaymentApi(payload) {
  return callApi().then(token => {
    return api.service('api/payment').create(payload);
  });
}

export { callOnPaymentApi };
