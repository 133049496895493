import React from "react";

const drawerContext = React.createContext({
  slidePanelsOption: {
    scanOptions: [
      {
        isOptionActive: false,
        domains: [],
        openScanOptionsPanel: businessId => {}
      }
    ],
    pci: [
      {
        isPciActive: false,
        isPciScanning: false,
        businessId: null,
        openPciPanel: businessId => {},
        startPCIscan: index => {},
        goToVulnerabiltyDetails: () => {}
      }
    ],
    malware: [
      {
        isMalwareActive: false,
        businessId: null,
        openMalwarePanel: businessId => {}
      }
    ],
    ssl: [
      {
        isSslActive: false,
        businessId: null,
        openSslPanel: businessId => {}
      }
    ],
    darkweb: [
      {
        isDarkWebActive: false,
        isDarkWebScanning: false,
        businessId: null,
        openPciPanel: businessId => {},
        startDarkWebScan: index => {},
        goToVulnerabiltyDetails: () => {}
      }
    ]
  }
});

const contexts = {
  drawerContext
};

export default contexts;
