import React from "react";
import Aux from "../../components/hoc/containerAux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import config from "../../helpers/config";
import { setStorageItem } from "../../helpers/storage";
import { viewUserDashboard } from "../../helpers/general";
import { convertTimeTo } from "../../helpers/timeZone";
import { callApiGetBusinessesDeletedSeals } from "../../services/index";
import ControlButtons from "./controlButtons";
import AppContext from "../../components/context/appContext";
import { uniqueId } from "lodash";

const Cryptr = require("cryptr");
const cryptr = new Cryptr("trustguardapiapp");

class DeletedSealsReport extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      display: "none",
      businesses: [],
    };
  }

  getSealReports = (display) => {
    this.setState(
      {
        display: display === "block" ? "none" : "block",
      },
      async () => {
        if (display === "none") {
          const tempBusinesses = await callApiGetBusinessesDeletedSeals();

          if (tempBusinesses && tempBusinesses.success && tempBusinesses.data && tempBusinesses.data.length > 0) {
            this.setState({
              businesses: tempBusinesses.data,
            });
          }
        }
      }
    );
  };

  render() {
    const { display, businesses } = this.state;

    return (
      <>
        <div className="reportButton">
          <button
            className="btn-success btn-sm"
            onClick={() => {
              this.getSealReports(display);
            }}
          >
            Deleted Seals
          </button>
        </div>
        <p>Deleted seals.</p>

        <hr />

        <div className="table-responsive" style={{ display: display }}>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Primary Domain</th>
                <th>Seals</th>
              </tr>
            </thead>
            <tbody className="scan-status-list">
              {businesses && businesses.length > 0 ? (
                businesses.map((business, businessIndex) => {
                  return (
                    <tr key={businessIndex}>
                      <td>
                        <button
                          className="tg-link"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => viewUserDashboard(business._id)}
                        >
                          {business.primaryDomain}
                        </button>
                      </td>
                      <td>
                        {business.seals.map((v, i) => {
                          return (
                            <p>
                              <a href={v} target="_blank">
                                {v}
                              </a>
                            </p>
                          );
                        })}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="6">No records.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

DeletedSealsReport.contextType = AppContext;

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeletedSealsReport);
