import React from 'react';
import _ from 'lodash';
import { Alert } from 'react-bootstrap';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../header';
import AppContext from '../../components/context/appContext';
import AccountBusinessData from './accountBusinessData';
import AccountUserData from './accountUserData';

class AccountSettings extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    return (
      <>
        <Header titlePage="Account Settings" bgColor="#F5F6F8" />

        <ToastContainer />
        <div id="mcontent-wrapper">
          <AccountBusinessData />
          <AccountUserData />
        </div>
      </>
    );
  }
}

AccountSettings.contextType = AppContext;

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSettings);
