import React from 'react';
import ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import Switch from 'react-switch';
import ContentLoader from 'react-content-loader';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';

import config from '../../helpers/config';
import { images } from '../../helpers/images';
import { getStorageItem } from '../../helpers/storage';
import Aux from '../../components/hoc/containerAux';
import Spinner from '../../components/ui/spinner/spinnerRectScan';
import AppContext from '../../components/context/appContext';
import TrustSealsInfo from '../modals/trustSealsInfo';
import TrustSealsCreatePreset from '../modals/trustSealsCreatePreset';
import ColorPicker from '../../components/color/colorPicker';
import SealGoogleTagManagerCnfModal from '../modals/sealGoogleTagManagerCnfModal';

import {
  callApiGetBusinessData,
  callApiGetTrustSealsData,
  callApiCreateTrustSealsData,
  callApiUpdateTrustSealsData,
  callApiPatchTrustSealsData,
  callApiCreateMissingTrustSeals,
  callApiGetCssJsHashList
} from '../../services';

const Cryptr = require('cryptr');
const cryptr = new Cryptr('trustguardapiapp');

const sliderStyle = {
  position: 'relative',
  width: '95%',
  height: 80,
  border: 'none'
};

const railStyle = {
  position: 'absolute',
  width: '100%',
  height: 8,
  marginTop: 35,
  borderRadius: 2,
  backgroundColor: '#fff'
};

export function Handle({ handle: { id, value, percent }, getHandleProps, sealType = null }) {
  return (
    <div
      style={{
        left: `${percent}%`,
        position: 'absolute',
        marginLeft: 0,
        marginTop: 30,
        zIndex: 2,
        width: 18,
        height: 18,
        border: 0,
        textAlign: 'center',
        cursor: 'pointer',
        borderRadius: '50%',
        backgroundColor: '#28a745',
        color: '#333'
      }}
      {...getHandleProps(id)}>
      <div style={{ fontFamily: 'Roboto', fontSize: 11, marginTop: -35 }}>
        {(sealType === 'textShield' &&
          ((value === 0 && 'Small') || (value === 2 && 'Medium') || (value === 4 && 'Large'))) ||
          value + 'px'}
      </div>
    </div>
  );
}

function Track({ source, target, getTrackProps }) {
  return (
    <div
      style={{
        position: 'absolute',
        height: 10,
        zIndex: 1,
        marginTop: 35,
        backgroundColor: '#28a745',
        borderRadius: 5,
        cursor: 'pointer',
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`
      }}
      {...getTrackProps()}
    />
  );
}

class SealComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      businessId: null,
      codeGenerated: false,
      businessData: '',
      certificateURL: 'CERTIFICATE_PATH/certificate/PRIMARY_DOMAIN',
      sealCode: {
        /*eslint-disable*/
        fixed:
          '_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART <div _ID class="_TG_SEALCODE_CLASS _ONE"_POSITION>_STANDALONE_ANCHOR_START<img alt="Trust Guard Security Scanned" _IMG_ID class="tgfloat-inner" src="_THREE" style="border: 0; _SIZE " _ONCONTEXTMENU />_STANDALONE_ANCHOR_END</div> _EXTERNAL_STYLE_AND_SCRIPT_REAR_PART',

        custom:
          '<a name="trustlink" href="_CERTIFICATE_URL" rel="nofollow" target="_blank" onclick="var nonwin=navigator.appName!=\'Microsoft Internet Explorer\'?\'yes\':\'no\'; window.open(this.href,\'welcome\',\'location=\'+nonwin+\',scrollbars=yes,width=517,height=\'+screen.availHeight+\',menubar=no,toolbar=no\'); return false;" oncontextmenu="var d = new Date(); alert(\'Copying Prohibited by Law - This image and all included logos are copyrighted by trust-guard \\251 \'+d.getFullYear()+\'.\'); return false;" > <img name="trustseal" alt="Security Seals" style="border: 0;" src="_BUSINESS_SEAL" /></a>',
        /*eslint-enable*/
        textboxCode: ''
      },
      trustSealsData: null,
      presetType: null,
      presetName: '',
      label: '',
      color: 'green',
      sealType: 'outer',
      floatSide: 'left',
      width: '225',
      distanceFromEdge: '0',
      colorSettings: {
        green: {
          placeholderShieldOuter: '#333333',
          placeholderShieldInner: '#FFFFFF',
          placeholderShieldLeft: '#66CC3E',
          placeholderShieldRight: '#33981F',
          placeholderBodyLeft: '#33981f',
          placeholderBodyRight: '#43b12a',
          placeholderStatusColor: '#ffffff',
          placeholderDateColor: '#ffffff',
          placeholderLabelColor: '#ffffff',
          placeholderKeyHole: '#ffffff',
          placeholderTrustGuardText: '#ffffff',
          placeholderSecuredText: '#ffffff',
          placeholderShieldBg: '#33981F',
          placeholderShieldTop: '#33981F',
          placeholderPrimaryLayer: '#ffffff',
          placeholderSecondaryLayer: '#ffffff',
          placeholderCheckoutSeparator: '#e6e6e5',
          placeholderShieldInnerBorder: '#ffffff',
          placeholderSealBorder: '#33981F',

          placeholderInsuranceBorder: '#e5e5e5',
          placeholderInsuredText: '#25aae1',
          placeholderGeneralText: '#333333',
          placeholderActiveText: '#404041',
          placeholderInsuranceSeparator: '#e5e5e5',
          placeholderInsuranceInfoBorder: '#25aae1',
          placeholderInnerShieldLeftTop: '#98d6f0',
          placeholderInnerShieldLeftBottom: '#7fcced',
          placeholderInnerShieldRightBottom: '#29aae1',
          placeholderInnerShieldRightTop: '#0c6d94',
          placeholderInnerShieldMiddleOne: '#84de65',
          placeholderInnerShieldMiddleTwo: '#7fcced'
        },
        black: {
          placeholderShieldOuter: '#ffffff',
          placeholderShieldInner: '#333333',
          placeholderShieldLeft: '#66CC3E',
          placeholderShieldRight: '#33981F',
          placeholderBodyLeft: '#333333',
          placeholderBodyRight: '#434343',
          placeholderStatusColor: '#ffffff',
          placeholderDateColor: '#ffffff',
          placeholderLabelColor: '#ffffff',
          placeholderKeyHole: '#ffffff',
          placeholderTrustGuardText: '#ffffff',
          placeholderSecuredText: '#ffffff',
          placeholderShieldBg: '#333333',
          placeholderShieldTop: '#333333',
          placeholderPrimaryLayer: '#ffffff',
          placeholderSecondaryLayer: '#ffffff',
          placeholderCheckoutSeparator: '#e6e6e5',
          placeholderShieldInnerBorder: '#333333',
          placeholderSealBorder: '#333333',

          placeholderInsuranceBorder: '#e5e5e5',
          placeholderInsuredText: '#25aae1',
          placeholderGeneralText: '#333333',
          placeholderActiveText: '#404041',
          placeholderInsuranceSeparator: '#e5e5e5',
          placeholderInsuranceInfoBorder: '#25aae1',
          placeholderInnerShieldLeftTop: '#98d6f0',
          placeholderInnerShieldLeftBottom: '#7fcced',
          placeholderInnerShieldRightBottom: '#29aae1',
          placeholderInnerShieldRightTop: '#0c6d94',
          placeholderInnerShieldMiddleOne: '#84de65',
          placeholderInnerShieldMiddleTwo: '#7fcced'
        },
        white: {
          placeholderShieldOuter: '#333333',
          placeholderShieldInner: '#FFFFFF',
          placeholderShieldLeft: '#66CC3E',
          placeholderShieldRight: '#33981F',
          placeholderBodyLeft: '#f2f2f2',
          placeholderBodyRight: '#ffffff',
          placeholderStatusColor: '#333333',
          placeholderDateColor: '#333333',
          placeholderLabelColor: '#333333',
          placeholderKeyHole: '#ffffff',
          placeholderTrustGuardText: '#333333',
          placeholderSecuredText: '#33981f',
          placeholderShieldBg: '#FFFFFF',
          placeholderShieldTop: '#FFFFFF',
          placeholderPrimaryLayer: '#333333',
          placeholderSecondaryLayer: '#333333',
          placeholderCheckoutSeparator: '#e6e6e5',
          placeholderShieldInnerBorder: '#ffffff',
          placeholderSealBorder: '#f5f5f5',

          placeholderInsuranceBorder: '#e5e5e5',
          placeholderInsuredText: '#25aae1',
          placeholderGeneralText: '#333333',
          placeholderActiveText: '#404041',
          placeholderInsuranceSeparator: '#e5e5e5',
          placeholderInsuranceInfoBorder: '#25aae1',
          placeholderInnerShieldLeftTop: '#98d6f0',
          placeholderInnerShieldLeftBottom: '#7fcced',
          placeholderInnerShieldRightBottom: '#29aae1',
          placeholderInnerShieldRightTop: '#0c6d94',
          placeholderInnerShieldMiddleOne: '#84de65',
          placeholderInnerShieldMiddleTwo: '#7fcced'
        },
        grey: {
          placeholderShieldOuter: '#333333',
          placeholderShieldInner: '#FFFFFF',
          placeholderShieldLeft: '#66CC3E',
          placeholderShieldRight: '#33981F',
          placeholderBodyLeft: '#f2f2f2',
          placeholderBodyRight: '#ffffff',
          placeholderStatusColor: '#333333',
          placeholderDateColor: '#333333',
          placeholderLabelColor: '#333333',
          placeholderKeyHole: '#ffffff',
          placeholderTrustGuardText: '#333333',
          placeholderSecuredText: '#333333',
          placeholderShieldBg: '#FFFFFF',
          placeholderShieldTop: '#FFFFFF',
          placeholderPrimaryLayer: '#333333',
          placeholderSecondaryLayer: '#333333',
          placeholderCheckoutSeparator: '#e6e6e5',
          placeholderShieldInnerBorder: '#ffffff',
          placeholderSealBorder: '#f5f5f5',

          placeholderInsuranceBorder: '#e5e5e5',
          placeholderInsuredText: '#25aae1',
          placeholderGeneralText: '#333333',
          placeholderActiveText: '#404041',
          placeholderInsuranceSeparator: '#e5e5e5',
          placeholderInsuranceInfoBorder: '#25aae1',
          placeholderInnerShieldLeftTop: '#98d6f0',
          placeholderInnerShieldLeftBottom: '#7fcced',
          placeholderInnerShieldRightBottom: '#29aae1',
          placeholderInnerShieldRightTop: '#0c6d94',
          placeholderInnerShieldMiddleOne: '#84de65',
          placeholderInnerShieldMiddleTwo: '#7fcced'
        }
      },
      selectedPresetId: null,
      newPresetName: '',
      isSealSaved: false,
      trustSealPath: null,
      demoTrustSealPath: null,
      trustSealPathForWebsiteCode: null,
      isTextElementChanged: false,
      isCopied: false,
      trustsealHost: config.seals.sealsHost,
      displayBlock: 'none',
      changingSealParameter: '',
      conditionalNotificationParameters: ['sealType', 'floatSide', 'distanceFromEdge'],
      sealScriptsCss: '',
      sealScriptsJs: '',
      disableFloatSide: false,
      displayOnChangeSealModal: true,
      sealMaxWidth: 175,
      sealMaxWidthOnMobile: 145,
      showMobileSeal: false,
      userInfoText: null,
      sealFontFamily: 'roboto',
      sealFontSize: '16',
      includeGoogleRobotoFont: true,
      sealFontColor: '#0066C0',
      isGoogleTagManagerEnabled: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.saveSealFontColor = this.saveSealFontColor.bind(this);

    this.handleCustomSelect = this.handleCustomSelect.bind(this);
  }

  async componentDidMount() {
    await this.getTrustsealsData();

    ReactDOM.findDOMNode(this).addEventListener('DOMContentLoaded', this.handleCustomSelect);
  }

  async componentDidUpdate(prevProps, prevState) {
    const { currentBusinessId } = this.context;
    let currentUrl = window.location.href;
    let targetPage = currentUrl.split('/');

    if (targetPage[targetPage.length - 1] !== 'trust-seals') {
      if (currentBusinessId !== null && prevState.businessId !== currentBusinessId) {
        await this.getTrustsealsData();
      }
    } else {
    }
  }

  getTrustsealsData = async () => {
    // console.log("calling getTrustsealsData");
    let businessId = null;
    const { currentBusinessId } = this.context;

    if (currentBusinessId) {
      businessId = currentBusinessId;
    } else {
      businessId = getStorageItem('businessId')
        ? cryptr.decrypt(getStorageItem('businessId'))
        : null;
    }

    if (businessId === null || businessId === undefined) {
      this.props.goToDashboard();
    }

    if (businessId) {
      const businessData = await callApiGetBusinessData(businessId);

      if (
        businessData &&
        (businessData.accountType !== 'free' && businessData.accountType !== 'monthly-scan')
      ) {
        this.setState({
          businessId: businessId,
          businessData: businessData
        });

        let trustSealsData = await callApiGetTrustSealsData(businessId);

        if (trustSealsData.data === null) {
          const sealData = this.populateInitialSealsData();
          trustSealsData = await callApiCreateTrustSealsData({
            businessId,
            sealData
          });

          toast.success('Preset created successfully', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500
          });
        }

        const cssJsHashList = await callApiGetCssJsHashList();

        if (cssJsHashList && cssJsHashList.data && cssJsHashList.data.length > 0) {
          let cssData = cssJsHashList.total > 0 ? cssJsHashList.data[0].css : null;
          let jsData = cssJsHashList.total > 0 ? cssJsHashList.data[0].js : null;

          this.setState({
            sealScriptsCss: cssData[cssData.length - 1],
            sealScriptsJs: jsData[jsData.length - 1]
          });
        }

        this.setTrustSealsUiData(trustSealsData);
      } else {
        this.props.goToDashboard();
      }
    }
  };

  setTrustSealsUiData = trustSealsData => {
    // console.log("setTrustSealsUiData", trustSealsData);
    let certificatePathUrl = config.seals.certificateHost;
    let targetIndex = null;
    let selectedPresetId = null;

    targetIndex = trustSealsData ? trustSealsData.data.sealSettings.length - 1 : 0;
    selectedPresetId = trustSealsData ? trustSealsData.data.sealSettings[targetIndex]._id : null;

    if (this.state.selectedPresetId) {
      selectedPresetId = this.state.selectedPresetId;

      if (trustSealsData && trustSealsData.data.sealSettings.length) {
        for (let i = 0; i < trustSealsData.data.sealSettings.length; i++) {
          if (trustSealsData.data.sealSettings[i]._id === this.state.selectedPresetId) {
            targetIndex = i;
          }
        }
      }
    } else {
      /* console.log(
        "Else selectedPresetId",
        selectedPresetId,
        trustSealsData.data.sealSettings[targetIndex].sealFontFamily
      ); */
    }

    /* console.log(
      "trustSealsData.data.sealSettings[targetIndex].sealFontColor",
      trustSealsData.data.sealSettings[targetIndex].sealFontColor
    ); */

    // console.log("trustSealsData", trustSealsData);

    /* if (
      trustSealsData.data.sealWidth &&
      trustSealsData.data.sealWidth.desktopWidth
    ) {
      console.log(
        "trustSealsData.data.sealWidth.desktopWidth",
        trustSealsData.data.sealWidth.desktopWidth
      );
    } else {
      console.log("ELSE", trustSealsData.data.sealSettings[targetIndex]);
    } */

    this.setState(
      {
        trustSealsData: trustSealsData.data,
        selectedPresetId: selectedPresetId,
        trustSealPath: null,
        demoTrustSealPath: null,
        presetType: trustSealsData.data.sealSettings[targetIndex].presetType,
        presetName: trustSealsData.data.sealSettings[targetIndex].presetName,
        label: trustSealsData.data.sealSettings[targetIndex].label,
        color: trustSealsData.data.sealSettings[targetIndex].color.title,
        sealType: trustSealsData.data.sealSettings[targetIndex].sealType,
        floatSide: trustSealsData.data.sealSettings[targetIndex].floatSide,
        width: trustSealsData.data.sealSettings[targetIndex].width,
        distanceFromEdge: trustSealsData.data.sealSettings[targetIndex].distanceFromEdge,
        isSealSaved: false,
        certificateURL: this.state.certificateURL.replace(
          'PRIMARY_DOMAIN',
          this.state.businessData.primaryDomain
        ),
        sealMaxWidth:
          trustSealsData.data.sealSettings[targetIndex].sealWidth &&
          trustSealsData.data.sealSettings[targetIndex].sealWidth.desktopWidth
            ? trustSealsData.data.sealSettings[targetIndex].sealWidth.desktopWidth
            : (trustSealsData.data.sealSettings[targetIndex].sealType === 'textShield' && 0) || 175,
        sealMaxWidthOnMobile:
          trustSealsData.data.sealSettings[targetIndex].sealWidth &&
          trustSealsData.data.sealSettings[targetIndex].sealWidth.mobileWidth
            ? trustSealsData.data.sealSettings[targetIndex].sealWidth.mobileWidth
            : 145,
        sealFontFamily:
          (trustSealsData.data.sealSettings[targetIndex].sealFontFamily &&
            trustSealsData.data.sealSettings[targetIndex].sealFontFamily) ||
          '',
        sealFontSize:
          (trustSealsData.data.sealSettings[targetIndex].sealFontSize &&
            trustSealsData.data.sealSettings[targetIndex].sealFontSize) ||
          '16',
        sealFontColor:
          (trustSealsData.data.sealSettings[targetIndex].sealFontColor &&
            trustSealsData.data.sealSettings[targetIndex].sealFontColor) ||
          '#0066C0',
        includeGoogleRobotoFont:
          ((trustSealsData.data.sealSettings[targetIndex].includeGoogleRobotoFont &&
            trustSealsData.data.sealSettings[targetIndex].includeGoogleRobotoFont) === true &&
            true) ||
          null
      },
      () => {
        // console.log("state ...", this.state);
      }
    );

    setTimeout(() => {
      let tempPresetSealImage =
        trustSealsData.data.sealSettings[targetIndex]._id + '.svg?v=' + new Date().getTime();

      let tempPresetSealImageForWebsiteCode =
        trustSealsData.data.sealSettings[targetIndex]._id + '.svg';

      this.setState(
        {
          trustSealPath:
            this.state.trustsealHost +
            '/sites/' +
            this.state.businessData.primaryDomain +
            '/' +
            tempPresetSealImage,
          trustSealPathForWebsiteCode:
            this.state.trustsealHost +
            '/sites/' +
            this.state.businessData.primaryDomain +
            '/' +
            tempPresetSealImageForWebsiteCode,
          demoTrustSealPath:
            this.state.trustsealHost +
            '/sites/' +
            this.state.businessData.primaryDomain +
            '/' +
            'demo-' +
            tempPresetSealImage,
          certificateURL: this.state.certificateURL.replace('CERTIFICATE_PATH', certificatePathUrl)
        },
        () => {
          this.generateSealWebsiteCode();
        }
      );
    }, 2500);
  };

  handleSwitchChange(event) {
    this.setState({ showMobileSeal: !this.state.showMobileSeal });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    /* if (name === "includeGoogleRobotoFont") {
      console.log("includeGoogleRobotoFont");
      console.log("value", value);
      console.log("name", name);
    } */

    // console.log('name', name, value, value.length, value.slice(0, 20));

    // character limit for website name on seal
    if (value.length > 20) {
      // console.log(5555);

      toast.error('Website name can not be greater than 20 characters.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500,
        toastId: 'customId'
      });

      value = value.slice(0, 20);
    }

    let thisUserInfoText = '';

    if (name === 'sealType') {
      this.setState({
        sealMaxWidth: (this.state.sealType === 'textShield' && 0) || 175
      });
    }

    if (name === 'sealType' && (value === 'checkout' || value === 'textShield')) {
      this.setState({
        floatSide: 'right'
      });
    }

    if (name === 'sealType' && value === 'insurance') {
      this.setState(
        {
          disableFloatSide: true,
          floatSide: 'none',
          sealMaxWidth: 225
        },
        () => {
          // console.log("textShield", this.state.floatSide);
        }
      );
    } else {
      this.setState({
        disableFloatSide: false
      });
    }

    if (this.state.conditionalNotificationParameters.indexOf(name) !== -1) {
      thisUserInfoText =
        'Your seal has been updated. Please note you must update the code on your website for the seal to display properly.';
    }

    if (name === 'color' && (value === 'black' || value === 'white')) {
      this.setState({
        sealFontColor: '#ffffff'
      });
    } else {
      this.setState({
        sealFontColor: '#0066C0'
      });
    }

    this.setState(
      {
        [name]: value,
        userInfoText: thisUserInfoText,
        isTextElementChanged: true,
        changingSealParameter: name
      },
      () => {
        // console.log("this.state", this.state);
        switch (name) {
          case 'color':
          case 'sealType':
          case 'sealFontFamily':
          case 'sealFontSize':
          case 'floatSide':
            this.saveSeal();
            break;
          default:
            break;
        }

        if (name === 'sealType' || name === 'floatSide') {
          document.getElementById('btnDisplayModalSealInfo').click();
        }
      }
    );
  }

  showActivePresetContent = sealSettings => {
    // console.log("showActivePresetContent sealSettings", sealSettings);
    var ul = document.getElementById('sealPresetUl');
    var items = ul.getElementsByTagName('li');

    for (var i = 0; i < items.length; ++i) {
      items[i].classList.remove('active');
    }

    this.setState(
      {
        presetType: sealSettings.presetType,
        presetName: sealSettings.presetName,
        trustSealPath:
          this.state.trustsealHost +
          '/sites/' +
          this.state.businessData.primaryDomain +
          '/' +
          sealSettings._id +
          '.svg?v=' +
          new Date().getTime(),
        trustSealPathForWebsiteCode:
          this.state.trustsealHost +
          '/sites/' +
          this.state.businessData.primaryDomain +
          '/' +
          sealSettings._id +
          '.svg',
        demoTrustSealPath:
          this.state.trustsealHost +
          '/sites/' +
          this.state.businessData.primaryDomain +
          '/' +
          'demo-' +
          sealSettings._id +
          '.svg',
        selectedPresetId: sealSettings._id,
        label: sealSettings.label,
        color: sealSettings.color.title,
        sealType: sealSettings.sealType,
        floatSide: sealSettings.floatSide,
        width: sealSettings.width,
        distanceFromEdge: sealSettings.distanceFromEdge,
        // SET SEAL MAX WIDTH
        sealMaxWidth: sealSettings.sealWidth && sealSettings.sealWidth.desktopWidth,
        sealFontFamily: (sealSettings.sealFontFamily && sealSettings.sealFontFamily) || 'arial',
        sealFontSize: (sealSettings.sealFontSize && sealSettings.sealFontSize) || '16',
        sealFontColor: (sealSettings.sealFontColor && sealSettings.sealFontColor) || '#0066C0',
        includeGoogleRobotoFont:
          (sealSettings.includeGoogleRobotoFont && sealSettings.includeGoogleRobotoFont) || false
      },
      () => {
        this.generateSealWebsiteCode();
      }
    );

    document.getElementById(sealSettings._id).classList.add('active');
  };

  generateSealWebsiteCode = () => {
    /* console.log(
      "generateSealWebsiteCode this.state.sealMaxWidth",
      this.state.sealMaxWidth
    ); */
    let websiteSealCode = null;

    switch ('fixed') {
      case 'fixed':
        websiteSealCode = this.state.sealCode.fixed;
        let sealOffset = '';
        let tgScript = 'tgscript-v' + this.state.sealScriptsJs.version;
        let tgScriptHash = 'sha384-' + this.state.sealScriptsJs.hash;

        let tgCss = config.seals.seal_css_version.replace(
          '_STYLE',
          'cert-style-v' + this.state.sealScriptsCss.version + '.css'
        );
        let tgCssHash = 'sha384-' + this.state.sealScriptsCss.hash;

        let sealPlacement = '';

        /* console.log("====================================");
        console.log(this.state.floatSide);
        console.log("===================================="); */

        if (
          (this.state.floatSide === 'left' || this.state.floatSide === 'right') &&
          this.state.floatSide !== 'standAlone' &&
          this.state.sealType !== 'textShield'
        ) {
          //console.log("IF");
          /* if (this.state.sealType !== "checkout") { */
          /*eslint-disable*/
          websiteSealCode = websiteSealCode.replace(
            '_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART',
            '<link rel="stylesheet" rel="preload" href="_TG_CSS" _INTEGRITY_CSS crossorigin="anonymous"><style type="text/css">@media (max-_SIZE_PARAM: 500px) { .tgfloat img { _SIZE_PARAM:_SEAL_WIDTH_FOR_MOBILEpx !important; }}</style>'
          );
          /* } */
          websiteSealCode = websiteSealCode.replace(
            '_EXTERNAL_STYLE_AND_SCRIPT_REAR_PART',
            '<div id="tgSlideContainer"></div><script src="https://tgscript.s3.amazonaws.com/_TG_SCRIPT" _INTEGRITY_SCRIPT crossorigin="anonymous"></script><script>var tg_div = document.createElement("div");tg_div.innerHTML = _TG_SCRIPT_FOR_DOCUMENT_WRITE;document.body.appendChild(tg_div);tg_load_animations("_BUSINESS_ID", "_PLACEMENT_TYPE");</script>'
          );
          /*eslint-enable*/

          websiteSealCode = websiteSealCode.replace('_TG_SCRIPT', tgScript + '.js');
          /* websiteSealCode = websiteSealCode.replace(
            "_TG_SCRIPT_HASH",
            tgScriptHash
          ); */
          websiteSealCode = websiteSealCode.replace('_BUSINESS_ID', this.state.businessId);

          websiteSealCode = websiteSealCode.replace('_TG_CSS', tgCss);
          //websiteSealCode = websiteSealCode.replace("_TG_CSS_HASH", tgCssHash);
          websiteSealCode = websiteSealCode.replace('_CERTIFICATE_URL', '');

          let floatSideType = this.state.floatSide;
          let documentScript = '';
          let placementType = '';

          switch (this.state.sealType) {
            case 'outer':
              if (this.state.floatSide === 'left') {
                floatSideType = 'bleft';
                sealPlacement = 'tgBleft';
                documentScript = 'tg_script_bottom_left';
                placementType = 'bottom_left';
              } else if (this.state.floatSide === 'right') {
                floatSideType = 'bright';
                sealPlacement = 'tgBright';
                documentScript = 'tg_script_bottom_right';
                placementType = 'bottom_right';
              }

              break;
            case 'inner':
              if (this.state.floatSide === 'left') {
                floatSideType = 'bleft';
                sealPlacement = 'tgBleft';
                documentScript = 'tg_script_bottom_left';
                placementType = 'bottom_left';
              } else if (this.state.floatSide === 'right') {
                floatSideType = 'bright';
                sealPlacement = 'tgBright';
                documentScript = 'tg_script_bottom_right';
                placementType = 'bottom_right';
              }

              break;

            case 'side':
              if (this.state.floatSide === 'left') {
                floatSideType = 'left';
                sealPlacement = 'tgLeft';
                documentScript = 'tg_script_side_left';
                placementType = 'side_left';
              } else if (this.state.floatSide === 'right') {
                floatSideType = 'right';
                sealPlacement = 'tgRight';
                documentScript = 'tg_script_side_right';
                placementType = 'side_right';
              }

              websiteSealCode = websiteSealCode.replace(
                '_IMG_ID',
                'id="tg_1-' + floatSideType + '"'
              );

              break;

            default:
              break;
          }

          websiteSealCode = websiteSealCode.replace('_PLACEMENT_TYPE', placementType);
          /*eslint-disable*/

          /* websiteSealCode = websiteSealCode.replace(
            "_IMG_ID",
            'id="tg_1-' + floatSideType + '"'
          ); */

          websiteSealCode = websiteSealCode.replace('_IMG_ID', '');

          if (this.state.sealType !== 'side') {
            websiteSealCode = websiteSealCode.replace(
              'class="tgfloat-inner"',
              'class="tgfloat-inner ' + 'tg_1-' + floatSideType + '"'
            );
          }
          /*eslint-enable*/

          websiteSealCode = websiteSealCode.replace(
            '_TG_SCRIPT_FOR_DOCUMENT_WRITE',
            documentScript
          );
        } else {
          //console.log("ELSE");
          if (this.state.sealType !== 'checkout' && this.state.sealType !== 'textShield') {
            // console.log("INSIDE TEXT SHIELD 1");
            /*eslint-disable*/
            websiteSealCode = websiteSealCode.replace(
              '_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART ',
              '<style type="text/css">@media (max-_SIZE_PARAM: 500px) { .tgfloat img { _SIZE_PARAM:_SEAL_WIDTH_FOR_MOBILEpx; }}</style>'
            );
            /*eslint-enable*/
          } else {
            if (this.state.sealType === 'checkout' || this.state.sealType === 'textShield') {
              // console.log("INSIDE TEXT SHIELD ****");

              /*eslint-disable*/
              // <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto">
              if (this.state.sealType !== 'checkout' && this.state.includeGoogleRobotoFont) {
                // console.log("Inside includeGoogleRobotoFont includeGoogleRobotoFont");
                websiteSealCode = websiteSealCode.replace(
                  '_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART',
                  '<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=' +
                    this.state.sealFontFamily.charAt(0).toUpperCase() +
                    this.state.sealFontFamily.slice(1) +
                    '"><link rel="stylesheet" rel="preload" href="_TG_CSS" _INTEGRITY_CSS crossorigin="anonymous">'
                );
              } else {
                websiteSealCode = websiteSealCode.replace(
                  '_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART',
                  '<link rel="stylesheet" rel="preload" href="_TG_CSS" _INTEGRITY_CSS crossorigin="anonymous">'
                );
              }

              websiteSealCode = websiteSealCode.replace(
                '_EXTERNAL_STYLE_AND_SCRIPT_REAR_PART',
                '<div id="tgSlideContainer"></div><script src="https://tgscript.s3.amazonaws.com/_TG_SCRIPT" _INTEGRITY_SCRIPT crossorigin="anonymous"></script><script>var tg_div = document.createElement("div");tg_div.innerHTML = _TG_SCRIPT_FOR_DOCUMENT_WRITE;document.body.appendChild(tg_div);tg_load_animations("_BUSINESS_ID", "_PLACEMENT_TYPE");</script>'
              );

              websiteSealCode = websiteSealCode.replace(' _IMG_ID', ' id="tgfloat-img"');
              /*eslint-enable*/
            } else {
              websiteSealCode = websiteSealCode.replace(
                '_EXTERNAL_STYLE_AND_SCRIPT_FRONT_PART ',
                ''
              );
            }
          }

          websiteSealCode = websiteSealCode.replace(' _EXTERNAL_STYLE_AND_SCRIPT_REAR_PART', '');

          websiteSealCode = websiteSealCode.replace('_CERTIFICATE_URL', this.state.certificateURL);

          websiteSealCode = websiteSealCode.replace(' _IMG_ID', '');
        }

        if (this.state.sealType !== 'checkout') {
          websiteSealCode = websiteSealCode.replace(
            '_SEAL_WIDTH_FOR_MOBILE',
            this.state.sealMaxWidthOnMobile
          );
        }

        if (this.state.sealType !== 'side') {
          websiteSealCode = websiteSealCode.replace(/_SIZE_PARAM/g, 'width');
        } else {
          websiteSealCode = websiteSealCode.replace(/_SIZE_PARAM/g, 'height');
        }

        websiteSealCode = websiteSealCode.replace('_ONE', sealPlacement);

        if (this.state.floatSide === 'left') {
          websiteSealCode = websiteSealCode.replace('_TWO', 'left');
          websiteSealCode = websiteSealCode.replace(
            '_THREE',
            this.state.trustSealPathForWebsiteCode
          );
          websiteSealCode = websiteSealCode.replace('_FOUR', 'left:');
          websiteSealCode = websiteSealCode.replace('_FIVE', this.state.distanceFromEdge + 'px;');
          websiteSealCode = websiteSealCode.replace('_SIX', this.state.width + 'px;');

          sealOffset = 'left:' + this.state.distanceFromEdge + 'px;';
        } else {
          websiteSealCode = websiteSealCode.replace('_TWO', 'right');
          websiteSealCode = websiteSealCode.replace(
            '_THREE',
            this.state.trustSealPathForWebsiteCode
          );
          websiteSealCode = websiteSealCode.replace('_FOUR', 'right:');
          websiteSealCode = websiteSealCode.replace('_FIVE', this.state.distanceFromEdge + 'px;');
          websiteSealCode = websiteSealCode.replace('_SIX', this.state.width + 'px;');

          sealOffset = 'right:' + this.state.distanceFromEdge + 'px;';
        }

        if (
          (this.state.sealType === 'outer' || this.state.sealType === 'inner') &&
          this.state.floatSide === 'standAlone'
        ) {
          websiteSealCode = websiteSealCode.replace(
            '_SIZE',
            'width: ' + this.state.sealMaxWidth + 'px;'
          );
        }

        if (
          (this.state.sealType === 'outer' ||
            this.state.sealType === 'inner' ||
            this.state.sealType === 'checkout') &&
          this.state.floatSide !== 'standAlone'
        ) {
          websiteSealCode = websiteSealCode.replace('_STANDALONE_ANCHOR_START', '');
          websiteSealCode = websiteSealCode.replace('_STANDALONE_ANCHOR_END', '');
          websiteSealCode = websiteSealCode.replace(
            '_SIZE',
            'width: ' + this.state.sealMaxWidth + 'px;'
          );

          if (this.state.sealType !== 'checkout') {
            /*eslint-disable*/
            websiteSealCode = websiteSealCode.replace(
              '_POSITION',
              ' style="position:fixed;bottom:0px;z-index: 999999;text-align:left;' +
                sealOffset +
                '"'
            );
            /*eslint-enable*/
          }
        }

        if (this.state.sealType === 'side' && this.state.floatSide !== 'standAlone') {
          websiteSealCode = websiteSealCode.replace(
            '_SIZE',
            'height: ' + this.state.sealMaxWidth + 'px'
          );
          /*eslint-disable*/
          websiteSealCode = websiteSealCode.replace(
            '_POSITION',
            ' style="position:fixed;top:150px;z-index:999999;text-align:left;' + sealOffset + '"'
          );
          /*eslint-enable*/
          websiteSealCode = websiteSealCode.replace('_STANDALONE_ANCHOR_START', '');
          websiteSealCode = websiteSealCode.replace('_STANDALONE_ANCHOR_END', '');
        }

        if (this.state.sealType === 'side' && this.state.floatSide === 'standAlone') {
          websiteSealCode = websiteSealCode.replace(
            '_SIZE',
            'height: ' + this.state.sealMaxWidth + 'px'
          );
        }

        if (this.state.floatSide === 'standAlone') {
          /*eslint-disable*/
          websiteSealCode = websiteSealCode.replace(
            '_STANDALONE_ANCHOR_START',
            '<a href="' + this.state.certificateURL + '" target="_blank">'
          );

          websiteSealCode = websiteSealCode.replace('_TG_SEALCODE_CLASS', 'tgbanner');
          /*eslint-enable*/
          websiteSealCode = websiteSealCode.replace('_STANDALONE_ANCHOR_END', '</a>');
          websiteSealCode = websiteSealCode.replace('_POSITION', '');
          websiteSealCode = websiteSealCode.replace('_SIZE ', '');
        } else {
          /* if (this.state.sealType === "textShield") {
            console.log("Changing class textShield", this.state.floatSide);
            websiteSealCode = websiteSealCode.replace(
              "_TG_SEALCODE_CLASS",
              "tgfloat tgStackSeal tgB" + this.state.floatSide
            );

            // TODO - replace font family and font size
            websiteSealCode = websiteSealCode.replace("_POSITION", "");
          } else {
            websiteSealCode = websiteSealCode.replace(
              "_TG_SEALCODE_CLASS",
              "tgfloat"
            );
          } */
        }

        // console.log("BEFORE", websiteSealCode);

        if (this.state.sealType === 'checkout' || this.state.sealType === 'textShield') {
          // console.log( "INSIDE textShield",this.state.sealType,this.state.floatSide);

          // console.log("Changing class textShield", this.state.floatSide);

          let fSide;

          if (this.state.floatSide !== 'none') {
            fSide = this.state.floatSide;
          } else {
            fSide = 'right';
          }

          websiteSealCode = websiteSealCode.replace(
            '_TG_SEALCODE_CLASS',

            'tgfloat tgStackSeal tgB' + fSide + ' tg_1-b' + fSide
          );

          //let testShieldID = "id=" + '"tg_1-b' + this.state.floatSide + '"';
          // console.log("testShieldID", testShieldID);

          /* websiteSealCode = websiteSealCode.replace(
            "_ID",
            testShieldID)
          ); */
          websiteSealCode = websiteSealCode.replace('_ID', '');
          websiteSealCode = websiteSealCode.replace(
            '_STANDALONE_ANCHOR_START',
            '<table class="tgBlockText" cellspacing="0" cellpadding="0" style="display:none;"><tr><td class="tgBlockImg">'
          );

          websiteSealCode = websiteSealCode.replace('_TG_CSS', tgCss);
          // websiteSealCode = websiteSealCode.replace("_TG_CSS_HASH", tgCssHash);

          websiteSealCode = websiteSealCode.replace('_TG_SCRIPT', tgScript + '.js');
          // websiteSealCode = websiteSealCode.replace( "_TG_SCRIPT_HASH", tgScriptHash);

          websiteSealCode = websiteSealCode.replace('_BUSINESS_ID', this.state.businessId);

          websiteSealCode = websiteSealCode.replace(
            '_TG_SCRIPT_FOR_DOCUMENT_WRITE',
            'tg_script_bottom_' + fSide
          );

          websiteSealCode = websiteSealCode.replace(
            '_PLACEMENT_TYPE")',
            'bottom_' +
              fSide +
              '");tgCheckSeal' +
              fSide.charAt(0).toUpperCase() +
              fSide.slice(1) +
              '()'
          );

          if (this.state.sealType !== 'checkout') {
            websiteSealCode = websiteSealCode.replace(
              '_STANDALONE_ANCHOR_END',
              '</td><td class="tgBlockSecure" style="font-family: ' +
                this.state.sealFontFamily +
                '; font-size:' +
                this.state.sealFontSize +
                'px; color:' +
                this.state.sealFontColor +
                '">Secure Transaction </td></tr/></table>'
            );

            websiteSealCode = websiteSealCode.replace(
              '_SIZE',
              'max-width: ' +
                parseInt(parseInt(this.state.sealMaxWidth) + parseInt(this.state.sealFontSize)) +
                'px;'
            );
          }

          websiteSealCode = websiteSealCode.replace('_POSITION', '');
        } else {
          websiteSealCode = websiteSealCode.replace('_TG_SEALCODE_CLASS', 'tgfloat');
          websiteSealCode = websiteSealCode.replace('_ID', '');
        }

        if (
          /* this.state.sealType === "checkout" || */
          this.state.sealType === 'insurance'
        ) {
          /*eslint-disable*/
          websiteSealCode = websiteSealCode.replace(
            '_STANDALONE_ANCHOR_START',
            '<a href="' + this.state.certificateURL + '" target="_blank">'
          );
          /*eslint-enable*/
          websiteSealCode = websiteSealCode.replace('_STANDALONE_ANCHOR_END', '</a>');
          websiteSealCode = websiteSealCode.replace('_POSITION', '');
          websiteSealCode = websiteSealCode.replace(
            '_SIZE',
            'width: ' + this.state.sealMaxWidth + 'px;'
          );
        }

        if (this.state.isGoogleTagManagerEnabled === true) {
          websiteSealCode = websiteSealCode.replace('_ONCONTEXTMENU', '');
          websiteSealCode = websiteSealCode.replace('_INTEGRITY_CSS', '');
          websiteSealCode = websiteSealCode.replace('_INTEGRITY_SCRIPT', '');
        } else {
          websiteSealCode = websiteSealCode.replace('_INTEGRITY_CSS', 'integrity="_TG_CSS_HASH"');
          websiteSealCode = websiteSealCode.replace('_TG_CSS_HASH', tgCssHash);

          websiteSealCode = websiteSealCode.replace(
            '_INTEGRITY_SCRIPT',
            'integrity="_TG_SCRIPT_HASH"'
          );
          websiteSealCode = websiteSealCode.replace('_TG_SCRIPT_HASH', tgScriptHash);

          websiteSealCode = websiteSealCode.replace(
            '_ONCONTEXTMENU',
            "oncontextmenu=\"var d = new Date(); alert('Copying Prohibited by Law - This image and all included logos are copyrighted by Trust Guard '+d.getFullYear()+'.'); return false;\""
          );
        }

        this.setState({
          sealCode: { ...this.state.sealCode, textboxCode: websiteSealCode }
        });

        break;
      case 'custom':
        break;

      default:
        break;
    }
  };

  generateSealCode = async () => {
    await callApiPatchTrustSealsData(this.state.trustSealsData._id, {
      lastGeneratedDate: Date.now(),
      businessId: this.state.businessId
    });

    this.setState({
      codeGenerated: true
    });

    toast.success('TrustSeal code generated successfully.', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2500
    });
  };

  createSealPreset = async newPresetName => {
    if (newPresetName && typeof newPresetName !== 'undefined') {
      const newSealsData = this.populateSealsData(newPresetName, 'create');
      document.getElementById('hideModalOnSuccess').click();
      this.setState({
        selectedPresetId: ''
      });

      const updatednewSealsData = await callApiUpdateTrustSealsData(this.state.trustSealsData._id, {
        businessId: this.state.businessId,
        sealData: newSealsData
      });

      toast.success('Preset created successfully.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500
      });

      this.setTrustSealsUiData(updatednewSealsData);
    } else {
      toast.error('Please enter preset name.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500
      });
    }
  };

  saveSeal = async () => {
    this.setState({
      isSealSaved: true,
      trustSealPath: null
    });

    const updatableSealData = this.populateSealsData(
      this.state.presetName,
      'update',
      this.state.selectedPresetId
    );

    const updatedSealsData = await callApiUpdateTrustSealsData(this.state.trustSealsData._id, {
      businessId: this.state.businessId,
      sealData: updatableSealData
    });

    toast.success('Preset updated successfully.', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2500
    });

    this.setTrustSealsUiData(updatedSealsData);
  };

  removeSeal = async () => {
    let confirm = window.confirm('Are you sure to remove this preset?');

    if (confirm) {
      const removableSealData = this.populateSealsData(
        this.state.presetName,
        'remove',
        this.state.selectedPresetId
      );

      const currentSealData = await callApiUpdateTrustSealsData(this.state.trustSealsData._id, {
        businessId: this.state.businessId,
        sealData: removableSealData,
        removableSealSettingsId: this.state.selectedPresetId
      });

      toast.success('Preset removed successfully.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500
      });

      this.setTrustSealsUiData(currentSealData);
    }
  };

  populateInitialSealsData() {
    let trustSealId = null;
    let sealData = null;
    let presetSettings = [
      {
        presetType: null,
        presetName: 'Secure Transaction Seal',
        label: this.state.businessData ? this.state.businessData.primaryDomain.toUpperCase() : '',
        sealType: 'textShield',
        floatSide: 'left',
        width: '225',
        distanceFromEdge: 0,
        color: {
          title: 'green',
          placeholderShieldOuter: '#333333',
          placeholderShieldInner: '#FFFFFF',
          placeholderShieldLeft: '#66CC3E',
          placeholderShieldRight: '#33981F',
          placeholderBodyLeft: '#33981f',
          placeholderBodyRight: '#43b12a',
          placeholderStatusColor: '#ffffff',
          placeholderDateColor: '#ffffff',
          placeholderLabelColor: '#ffffff',
          placeholderKeyHole: '#ffffff',
          placeholderTrustGuardText: '#ffffff',
          placeholderSecuredText: '#ffffff',
          placeholderShieldBg: '#33981F',
          placeholderShieldTop: '#33981F',
          placeholderPrimaryLayer: '#ffffff',
          placeholderSecondaryLayer: '#ffffff',
          placeholderCheckoutSeparator: '#e6e6e5',
          placeholderShieldInnerBorder: '#ffffff',
          placeholderSealBorder: '#33981F',

          placeholderInsuranceBorder: '#e5e5e5',
          placeholderInsuredText: '#25aae1',
          placeholderGeneralText: '#333333',
          placeholderActiveText: '#404041',
          placeholderInsuranceSeparator: '#e5e5e5',
          placeholderInsuranceInfoBorder: '#25aae1',
          placeholderInnerShieldLeftTop: '#98d6f0',
          placeholderInnerShieldLeftBottom: '#7fcced',
          placeholderInnerShieldRightBottom: '#29aae1',
          placeholderInnerShieldRightTop: '#0c6d94',
          placeholderInnerShieldMiddleOne: '#84de65',
          placeholderInnerShieldMiddleTwo: '#7fcced'
        },
        sealWidth: {
          desktopWidth: this.state.sealMaxWidth,
          mobileWidth: this.state.sealMaxWidthOnMobile
        },
        sealFontFamily: this.state.sealFontFamily,
        sealFontSize: this.state.sealFontSize,
        sealFontColor: '#0066C0',
        includeGoogleRobotoFont: this.state.includeGoogleRobotoFont,
        updateDate: new Date().toISOString
      },
      {
        presetType: null,
        presetName: 'Checkout Banner',
        label: this.state.businessData ? this.state.businessData.primaryDomain.toUpperCase() : '',
        sealType: 'checkout',
        floatSide: 'none',
        width: '225',
        distanceFromEdge: 0,
        color: {
          title: 'green',
          placeholderShieldOuter: '#333333',
          placeholderShieldInner: '#FFFFFF',
          placeholderShieldLeft: '#66CC3E',
          placeholderShieldRight: '#33981F',
          placeholderBodyLeft: '#33981f',
          placeholderBodyRight: '#43b12a',
          placeholderStatusColor: '#ffffff',
          placeholderDateColor: '#ffffff',
          placeholderLabelColor: '#ffffff',
          placeholderKeyHole: '#ffffff',
          placeholderTrustGuardText: '#ffffff',
          placeholderSecuredText: '#ffffff',
          placeholderShieldBg: '#33981F',
          placeholderShieldTop: '#33981F',
          placeholderPrimaryLayer: '#ffffff',
          placeholderSecondaryLayer: '#ffffff',
          placeholderCheckoutSeparator: '#e6e6e5',
          placeholderShieldInnerBorder: '#ffffff',
          placeholderSealBorder: '#33981F',

          placeholderInsuranceBorder: '#e5e5e5',
          placeholderInsuredText: '#25aae1',
          placeholderGeneralText: '#333333',
          placeholderActiveText: '#404041',
          placeholderInsuranceSeparator: '#e5e5e5',
          placeholderInsuranceInfoBorder: '#25aae1',
          placeholderInnerShieldLeftTop: '#98d6f0',
          placeholderInnerShieldLeftBottom: '#7fcced',
          placeholderInnerShieldRightBottom: '#29aae1',
          placeholderInnerShieldRightTop: '#0c6d94',
          placeholderInnerShieldMiddleOne: '#84de65',
          placeholderInnerShieldMiddleTwo: '#7fcced'
        },
        sealWidth: {
          desktopWidth: this.state.sealMaxWidth,
          mobileWidth: this.state.sealMaxWidthOnMobile
        },
        sealFontFamily: this.state.sealFontFamily,
        sealFontSize: this.state.sealFontSize,
        sealFontColor: '#0066C0',
        includeGoogleRobotoFont: this.state.includeGoogleRobotoFont,
        updateDate: new Date().toISOString
      },
      {
        presetType: null,
        presetName: ' Standard Seal',
        label: this.state.businessData ? this.state.businessData.primaryDomain.toUpperCase() : '',
        sealType: 'inner',
        floatSide: 'standAlone',
        width: '225',
        distanceFromEdge: 0,
        color: {
          title: 'green',
          placeholderShieldOuter: '#333333',
          placeholderShieldInner: '#FFFFFF',
          placeholderShieldLeft: '#66CC3E',
          placeholderShieldRight: '#33981F',
          placeholderBodyLeft: '#33981f',
          placeholderBodyRight: '#43b12a',
          placeholderStatusColor: '#ffffff',
          placeholderDateColor: '#ffffff',
          placeholderLabelColor: '#ffffff',
          placeholderKeyHole: '#ffffff',
          placeholderTrustGuardText: '#ffffff',
          placeholderSecuredText: '#ffffff',
          placeholderShieldBg: '#33981F',
          placeholderShieldTop: '#33981F',
          placeholderPrimaryLayer: '#ffffff',
          placeholderSecondaryLayer: '#ffffff',
          placeholderCheckoutSeparator: '#e6e6e5',
          placeholderShieldInnerBorder: '#ffffff',
          placeholderSealBorder: '#33981F',

          placeholderInsuranceBorder: '#e5e5e5',
          placeholderInsuredText: '#25aae1',
          placeholderGeneralText: '#333333',
          placeholderActiveText: '#404041',
          placeholderInsuranceSeparator: '#e5e5e5',
          placeholderInsuranceInfoBorder: '#25aae1',
          placeholderInnerShieldLeftTop: '#98d6f0',
          placeholderInnerShieldLeftBottom: '#7fcced',
          placeholderInnerShieldRightBottom: '#29aae1',
          placeholderInnerShieldRightTop: '#0c6d94',
          placeholderInnerShieldMiddleOne: '#84de65',
          placeholderInnerShieldMiddleTwo: '#7fcced'
        },
        sealWidth: {
          desktopWidth: this.state.sealMaxWidth,
          mobileWidth: this.state.sealMaxWidthOnMobile
        },
        sealFontFamily: this.state.sealFontFamily,
        sealFontSize: this.state.sealFontSize,
        sealFontColor: '#0066C0',
        includeGoogleRobotoFont: this.state.includeGoogleRobotoFont,
        updateDate: new Date().toISOString
      },
      {
        presetType: null,
        presetName: 'Floating Seal',
        label: this.state.businessData ? this.state.businessData.primaryDomain.toUpperCase() : '',
        sealType: 'outer',
        floatSide: 'left',
        width: '225',
        distanceFromEdge: 0,
        color: {
          title: 'green',
          placeholderShieldOuter: '#333333',
          placeholderShieldInner: '#FFFFFF',
          placeholderShieldLeft: '#66CC3E',
          placeholderShieldRight: '#33981F',
          placeholderBodyLeft: '#33981f',
          placeholderBodyRight: '#43b12a',
          placeholderStatusColor: '#ffffff',
          placeholderDateColor: '#ffffff',
          placeholderLabelColor: '#ffffff',
          placeholderKeyHole: '#ffffff',
          placeholderTrustGuardText: '#ffffff',
          placeholderSecuredText: '#ffffff',
          placeholderShieldBg: '#33981F',
          placeholderShieldTop: '#33981F',
          placeholderPrimaryLayer: '#ffffff',
          placeholderSecondaryLayer: '#ffffff',
          placeholderCheckoutSeparator: '#e6e6e5',
          placeholderShieldInnerBorder: '#ffffff',
          placeholderSealBorder: '#33981F',

          placeholderInsuranceBorder: '#e5e5e5',
          placeholderInsuredText: '#25aae1',
          placeholderGeneralText: '#333333',
          placeholderActiveText: '#404041',
          placeholderInsuranceSeparator: '#e5e5e5',
          placeholderInsuranceInfoBorder: '#25aae1',
          placeholderInnerShieldLeftTop: '#98d6f0',
          placeholderInnerShieldLeftBottom: '#7fcced',
          placeholderInnerShieldRightBottom: '#29aae1',
          placeholderInnerShieldRightTop: '#0c6d94',
          placeholderInnerShieldMiddleOne: '#84de65',
          placeholderInnerShieldMiddleTwo: '#7fcced'
        },
        sealWidth: {
          desktopWidth: this.state.sealMaxWidth,
          mobileWidth: this.state.sealMaxWidthOnMobile
        },
        sealFontFamily: this.state.sealFontFamily,
        sealFontSize: this.state.sealFontSize,
        sealFontColor: '#0066C0',
        includeGoogleRobotoFont: this.state.includeGoogleRobotoFont,
        updateDate: new Date().toISOString
      }
    ];

    sealData = {
      businessId: this.state.businessId,
      lastGeneratedDate: null,
      nextGenerateDate: null,
      status: 'active',
      sealSettings: presetSettings
    };

    // console.log("Seal Data", sealData);
    return sealData;
  }

  populateSealsData(presetName = null, purpose = null, sealSettingsId = null) {
    // console.log("populateSealsData", presetName, purpose, sealSettingsId);
    let trustSealId = null;
    let sealData = null;
    let presetSettings = {
      presetType: null,
      presetName: 'Default',
      label: this.state.businessData ? this.state.businessData.primaryDomain.toUpperCase() : '',
      sealType: 'outer',
      floatSide: 'left',
      width: '225',
      distanceFromEdge: 0,
      color: {
        title: 'green',
        placeholderShieldOuter: '#333333',
        placeholderShieldInner: '#FFFFFF',
        placeholderShieldLeft: '#66CC3E',
        placeholderShieldRight: '#33981F',
        placeholderBodyLeft: '#33981f',
        placeholderBodyRight: '#43b12a',
        placeholderStatusColor: '#ffffff',
        placeholderDateColor: '#ffffff',
        placeholderLabelColor: '#ffffff',
        placeholderKeyHole: '#ffffff',
        placeholderTrustGuardText: '#ffffff',
        placeholderSecuredText: '#ffffff',
        placeholderShieldBg: '#33981F',
        placeholderShieldTop: '#33981F',
        placeholderPrimaryLayer: '#ffffff',
        placeholderSecondaryLayer: '#ffffff',
        placeholderCheckoutSeparator: '#e6e6e5',
        placeholderShieldInnerBorder: '#ffffff',
        placeholderSealBorder: '#33981F',

        placeholderInsuranceBorder: '#e5e5e5',
        placeholderInsuredText: '#25aae1',
        placeholderGeneralText: '#333333',
        placeholderActiveText: '#404041',
        placeholderInsuranceSeparator: '#e5e5e5',
        placeholderInsuranceInfoBorder: '#25aae1',
        placeholderInnerShieldLeftTop: '#98d6f0',
        placeholderInnerShieldLeftBottom: '#7fcced',
        placeholderInnerShieldRightBottom: '#29aae1',
        placeholderInnerShieldRightTop: '#0c6d94',
        placeholderInnerShieldMiddleOne: '#84de65',
        placeholderInnerShieldMiddleTwo: '#7fcced'
      },
      sealWidth: {
        desktopWidth: this.state.sealMaxWidth,
        mobileWidth: this.state.sealMaxWidthOnMobile
      },
      sealFontFamily: this.state.sealFontFamily,
      sealFontSize: this.state.sealFontSize,
      sealFontColor: '#0066C0',
      includeGoogleRobotoFont: this.state.includeGoogleRobotoFont,
      updateDate: new Date().toISOString
    };

    let sealSettingsData = null;

    if (this.state.sealType === 'checkout') {
      this.setState({
        floatSide: 'none'
      });
    }

    if (this.state.sealType === 'insurance') {
      this.setState({
        floatSide: 'none'
      });
    }

    if (this.state.sealType === 'textShield') {
      console.log('populateSealsData floatside', this.state.floatSide);
      this.setState({
        floatSide: this.state.floatSide
      });
    }

    // console.log("populateSealsData purpose", purpose);

    switch (purpose) {
      case 'create':
        trustSealId = this.state.trustSealsData._id;
        presetSettings.presetName = presetName;
        sealSettingsData = [...this.state.trustSealsData.sealSettings, presetSettings];
        break;

      case 'update':
        let sealLabel = this.state.label;
        if (sealLabel === '') {
          sealLabel = this.state.businessData.primaryDomain;
        }

        let tgSealOuterShieldColor = null;
        if (
          this.state.floatSide === 'right' &&
          this.state.sealType === 'side' &&
          (this.state.color === 'black' || this.state.color === 'whiteAlt')
        ) {
          tgSealOuterShieldColor = '#dddddd';
        } else {
          tgSealOuterShieldColor = this.state.colorSettings[this.state.color]
            .placeholderShieldOuter;
        }

        trustSealId = this.state.trustSealsData ? this.state.trustSealsData._id : null;
        sealSettingsData =
          this.state.trustSealsData && this.state.trustSealsData.sealSettings
            ? this.state.trustSealsData.sealSettings.map((v, i) => {
                if (v._id === sealSettingsId) {
                  v.presetName = this.state.presetName;
                  v.label = sealLabel.toUpperCase();
                  v.sealType = this.state.sealType;
                  v.floatSide =
                    this.state.sealType !== 'checkout' && this.state.sealType !== 'insurance'
                      ? this.state.floatSide !== 'none'
                        ? this.state.floatSide
                        : 'left'
                      : 'none';
                  v.width = '225';
                  v.distanceFromEdge = this.state.distanceFromEdge;
                  v.color = {
                    title: this.state.color,
                    placeholderShieldOuter: tgSealOuterShieldColor,
                    placeholderShieldInner: this.state.colorSettings[this.state.color]
                      .placeholderShieldInner,
                    placeholderShieldLeft: this.state.colorSettings[this.state.color]
                      .placeholderShieldLeft,
                    placeholderShieldRight: this.state.colorSettings[this.state.color]
                      .placeholderShieldRight,
                    placeholderBodyLeft: this.state.colorSettings[this.state.color]
                      .placeholderBodyLeft,
                    placeholderBodyRight: this.state.colorSettings[this.state.color]
                      .placeholderBodyRight,
                    placeholderStatusColor: this.state.colorSettings[this.state.color]
                      .placeholderStatusColor,
                    placeholderDateColor: this.state.colorSettings[this.state.color]
                      .placeholderDateColor,
                    placeholderLabelColor: this.state.colorSettings[this.state.color]
                      .placeholderLabelColor,
                    placeholderKeyHole: this.state.colorSettings[this.state.color]
                      .placeholderKeyHole,
                    placeholderTrustGuardText: this.state.colorSettings[this.state.color]
                      .placeholderTrustGuardText,
                    placeholderSecuredText: this.state.colorSettings[this.state.color]
                      .placeholderSecuredText,

                    placeholderShieldBg: this.state.colorSettings[this.state.color]
                      .placeholderShieldBg,

                    placeholderShieldTop: this.state.colorSettings[this.state.color]
                      .placeholderShieldTop,

                    placeholderPrimaryLayer: this.state.colorSettings[this.state.color]
                      .placeholderPrimaryLayer,

                    placeholderSecondaryLayer: this.state.colorSettings[this.state.color]
                      .placeholderSecondaryLayer,
                    placeholderCheckoutSeparator: this.state.colorSettings[this.state.color]
                      .placeholderCheckoutSeparator,
                    placeholderShieldInnerBorder: this.state.colorSettings[this.state.color]
                      .placeholderShieldInnerBorder,
                    placeholderSealBorder: this.state.colorSettings[this.state.color]
                      .placeholderSealBorder,

                    placeholderInsuranceBorder: this.state.colorSettings[this.state.color]
                      .placeholderInsuranceBorder,

                    placeholderInsuredText: this.state.colorSettings[this.state.color]
                      .placeholderInsuredText,

                    placeholderGeneralText: this.state.colorSettings[this.state.color]
                      .placeholderGeneralText,

                    placeholderActiveText: this.state.colorSettings[this.state.color]
                      .placeholderActiveText,

                    placeholderInsuranceSeparator: this.state.colorSettings[this.state.color]
                      .placeholderInsuranceSeparator,

                    placeholderInsuranceInfoBorder: this.state.colorSettings[this.state.color]
                      .placeholderInsuranceInfoBorder,

                    placeholderInnerShieldLeftTop: this.state.colorSettings[this.state.color]
                      .placeholderInnerShieldLeftTop,

                    placeholderInnerShieldLeftBottom: this.state.colorSettings[this.state.color]
                      .placeholderInnerShieldLeftBottom,

                    placeholderInnerShieldRightBottom: this.state.colorSettings[this.state.color]
                      .placeholderInnerShieldRightBottom,

                    placeholderInnerShieldRightTop: this.state.colorSettings[this.state.color]
                      .placeholderInnerShieldRightTop,

                    placeholderInnerShieldMiddleOne: this.state.colorSettings[this.state.color]
                      .placeholderInnerShieldMiddleOne,

                    placeholderInnerShieldMiddleTwo: this.state.colorSettings[this.state.color]
                      .placeholderInnerShieldMiddleTwo
                  };
                  v.sealWidth = {
                    desktopWidth: this.state.sealMaxWidth,
                    mobileWidth: this.state.sealMaxWidthOnMobile
                  };
                  v.includeGoogleRobotoFont = this.state.includeGoogleRobotoFont;
                  v.sealFontFamily = this.state.sealFontFamily;
                  v.sealFontSize = this.state.sealFontSize;
                  v.sealFontColor = this.state.sealFontColor;
                  v.updateDate = new Date().toISOString();
                }

                // console.log("v", v);

                return v;
              })
            : null;
        break;

      case 'remove':
        trustSealId = this.state.trustSealsData ? this.state.trustSealsData._id : null;
        sealSettingsData = this.state.trustSealsData
          ? this.state.trustSealsData.sealSettings.filter(eachData => {
              return eachData._id !== sealSettingsId;
            })
          : null;
        break;

      default:
        // console.log("populateSealsData default switch", presetSettings);
        presetSettings.presetType = 'Default';
        sealSettingsData = presetSettings;
        break;
    }

    sealData = {
      businessId: this.state.businessId,
      lastGeneratedDate: null,
      nextGenerateDate: null,
      status: 'active',
      sealSettings: sealSettingsData
    };

    // console.log("Seal Data", sealData);
    return sealData;
  }

  onUpdate = values => {
    // console.log("Updating...");
    this.setState(
      {
        sealMaxWidth: values[0]
      },
      () => {
        this.generateSealWebsiteCode();
        this.onSealSizeUpdate();
      }
    );
  };

  onUpdateMobile = values => {
    this.setState(
      {
        sealMaxWidthOnMobile: values[0]
      },
      () => {
        this.generateSealWebsiteCode();
        this.onSealSizeUpdate();
      }
    );
  };

  onSealSizeUpdate = async prevState => {
    const onUpdateSealData = this.populateSealsData(
      this.state.presetName,
      'update',
      this.state.selectedPresetId
    );

    const updatedSealsData = await callApiUpdateTrustSealsData(
      this.state.trustSealsData ? this.state.trustSealsData._id : null,
      {
        businessId: this.state.businessId,
        sealData: onUpdateSealData
      }
    );
  };

  saveSealFontColor = async color => {
    // console.log("saveSealFontColor color", color);

    this.setState({
      sealFontColor: color,
      isSealSaved: true,
      trustSealPath: null
    });

    const updatableSealData = this.populateSealsData(
      this.state.presetName,
      'update',
      this.state.selectedPresetId
    );

    const updatedSealsData = await callApiUpdateTrustSealsData(this.state.trustSealsData._id, {
      businessId: this.state.businessId,
      sealData: updatableSealData
    });

    this.setTrustSealsUiData(updatedSealsData);
  };

  toggleGoogleTagManager = async status => {
    this.setState(
      {
        isGoogleTagManagerEnabled: status
      },
      () => {
        this.generateSealWebsiteCode();
      }
    );
  };

  handleCustomSelect = event => {
    const customSelects = document.querySelectorAll('.custom-select');
    customSelects.forEach(customSelect => {
      const selectButton = customSelect.querySelector('.select-button');
      const dropdown = customSelect.querySelector('.select-dropdown');
      const toggleDropdown = (expand = null) => {
        const isOpen = expand !== null ? expand : dropdown.classList.contains('hidden');
        dropdown.classList.toggle('hidden', !isOpen);
        selectButton.setAttribute('aria-expanded', isOpen);
      };
      selectButton.addEventListener('click', () => {
        //toggleDropdown();
        alert(0);
      });
    });
  };

  render() {
    const {
      businessId,
      codeGenerated,
      businessData,
      certificateURL,
      sealCode,
      trustSealsData,
      presetType,
      presetName,
      label,
      color,
      sealType,
      floatSide,
      width,
      distanceFromEdge,
      colorSettings,
      selectedPresetId,
      newPresetName,
      isSealSaved,
      trustSealPath,
      demoTrustSealPath,
      trustSealPathForWebsiteCode,
      isTextElementChanged,
      isCopied,
      trustsealHost,
      displayBlock,
      changingSealParameter,
      conditionalNotificationParameters,
      sealScriptsCss,
      sealScriptsJs,
      disableFloatSide,
      displayOnChangeSealModal,
      sealMaxWidth,
      sealMaxWidthOnMobile,
      showMobileSeal,
      userInfoText,
      sealFontFamily,
      sealFontSize,
      includeGoogleRobotoFont,
      sealFontColor,
      isGoogleTagManagerEnabled
    } = this.state;

    let businessPciStatus = null;

    switch (businessData.pciStatus) {
      case 'pass':
        businessPciStatus = businessData.pciStatus;
        break;
      case 'fail':
        businessPciStatus = businessData.pciStatus;
        break;
      default:
        businessPciStatus = businessData.lastPciStatus;
        break;
    }

    return (
      <Aux>
        <div className="seal-content-wrapper">
          <h5
            style={{
              textTransform: 'uppercase',
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center'
            }}>
            <img src={images.keyboardReturn} style={{ marginRight: '15px' }} />
            SEAL OPTIONS
          </h5>

          <div class="flex-parent">
            <div class="flex-left flex-margin-right-auto">
              <p>
                <h2 style={{ textTransform: 'uppercase' }}>
                  <b>{this.state.businessData ? this.state.businessData.primaryDomain : ''}</b>
                </h2>
                Below you will find the options to control the position of your Trust Guard trust
                seal. Use the controls to modify the position and copy the code when you are done
                and simply paste it into the desired webpage or in the universal footer to show on
                all pages.
              </p>
              <p>Note: You can use presets to display different seals on different pages.</p>
            </div>
            <div class="flex-right" style={{ width: '35%' }}>
              <img src={images.secureGroup} />
            </div>
          </div>
        </div>

        <div id="dash-malware-table" style={{ marginTop: '35px' }}>
          <div className="col-md-12 col-sm-12 col-xs-12 col-xxs-12 text-left no-pad">
            <div className="tab-content">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xxs-12 tg-seals-well">
                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 col-xxs-12 left">
                  <h5 style={{ marginBottom: '25px', fontSize: '16px' }}>Presets</h5>
                  <div className="menu-list">
                    {trustSealsData !== null ? (
                      <ul id="sealPresetUl" className="list-group">
                        {trustSealsData.hasOwnProperty('sealSettings')
                          ? trustSealsData.sealSettings
                              .map((v, i) => {
                                return (
                                  <li
                                    id={v._id}
                                    key={i}
                                    onClick={() => {
                                      this.showActivePresetContent(v);
                                    }}
                                    className={
                                      v._id === selectedPresetId
                                        ? 'list-group-item active'
                                        : 'list-group-item'
                                    }>
                                    {v.presetName}
                                  </li>
                                );
                              })
                              .reverse()
                          : ''}
                      </ul>
                    ) : (
                      ''
                    )}

                    <button
                      className="btn-tgdark-black-outline btn-md"
                      data-toggle="modal"
                      data-target="#modalCreatePreset"
                      style={{ marginTop: '25px' }}>
                      <b className="fas fa-plus" /> Create New Preset
                    </button>
                  </div>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-xxs-12 right">
                  <div class="flex-parent" style={{ padding: '15px 0' }}>
                    <div class="flex-left flex-margin-right-auto">
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          height: '100%',
                          justifyItems: 'center'
                        }}>
                        <h4 style={{ fontSize: '20px' }}>{presetName}</h4>{' '}
                        <div style={{ marginLeft: '10px' }}>
                          <img
                            src={images.modeEditOutline}
                            alt="mode edit outline"
                            style={{ height: '26px', marginBottom: '-20px' }}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="flex-right" style={{ width: '35%' }}>
                      {/* {presetType === null ? (
                        <button
                          className="btn-danger btn-md"
                          onClick={() => {
                            this.removeSeal();
                          }}
                          style={{}}>
                          <b className="fas fa-trash" />&nbsp; Remove Preset
                        </button>
                      ) : (
                        ''
                      )} */}

                      <button className="btn-tgdark2 btn-md">Save Preset</button>
                    </div>
                  </div>
                  <hr style={{ margin: '0 0 35px 0' }} />
                  <div>
                    <h2 style={{ color: '#437f34' }}>Customize Your Seals</h2>

                    <div className="seal-content">
                      <div style={{ flex: 1 }}>
                        <div className="custom-select">
                          <button className="select-button">
                            <span className="selected-value">Open this select menu</span>
                            <span className="arrow" />
                          </button>
                          <ul className="select-dropdown hidden">
                            <li>GitHub</li>
                            <li>Instagram</li>
                            <li>Facebook</li>
                            <li>LinkedIn</li>
                            <li>Twitter</li>
                            <li>Reddit</li>
                          </ul>
                        </div>
                      </div>

                      <p>
                        <label> Preset Name</label>
                      </p>
                      <div>
                        <input
                          type="text"
                          name="presetName"
                          value={presetName}
                          onChange={this.handleInputChange}
                          onBlur={evt => {
                            if (isTextElementChanged) {
                              this.saveSeal();
                              this.setState({
                                isTextElementChanged: false
                              });
                            }
                          }}
                          style={{
                            width: '500px',
                            padding: '15px',
                            textTransform: 'uppercase',
                            border: '2px solid #333',
                            borderRadius: '8px',
                            color: '#333'
                          }}
                        />
                      </div>
                      <p
                        style={{
                          display: sealType === 'textShield' ? 'none' : 'block'
                        }}>
                        <label> Website Name on Trust Seal</label>
                      </p>
                      <div>
                        <input
                          className="mb-3"
                          type="text"
                          name="label"
                          value={label}
                          onChange={this.handleInputChange}
                          onBlur={evt => {
                            if (isTextElementChanged) {
                              this.saveSeal();
                              this.setState({
                                isTextElementChanged: false
                              });
                            }
                          }}
                          style={{
                            width: '500px',
                            padding: '15px',
                            textTransform: 'uppercase',
                            border: '2px solid #333',
                            borderRadius: '8px',
                            color: '#333'
                          }}
                        />
                      </div>
                      <p
                        style={
                          floatSide === 'standAlone' || sealType === 'textShield'
                            ? { display: 'none' }
                            : { display: 'block' }
                        }>
                        <label> Offset Placement ( px )</label>
                      </p>
                      <input
                        type="text"
                        name="distanceFromEdge"
                        value={distanceFromEdge}
                        onChange={this.handleInputChange}
                        onBlur={() => {
                          if (isTextElementChanged) {
                            this.saveSeal();
                            this.setState({
                              isTextElementChanged: false
                            });

                            document.getElementById('btnDisplayModalSealInfo').click();
                          }
                        }}
                        style={{
                          width: '500px',
                          padding: '15px',
                          textTransform: 'uppercase',
                          border: '2px solid #333',
                          borderRadius: '8px',
                          color: '#333'
                        }}
                      />
                      {userInfoText ? (
                        <div
                          className="well well-lg info"
                          style={{
                            background: 'rgb(255, 255, 255)',
                            fontWeight: 'normal',
                            fontSize: '13px',
                            color: '#2e6da4',
                            borderBottomLeftRadius: '0px',
                            borderTopLeftRadius: '0px',
                            borderLeft: '6px solid #2e6da4',
                            borderColor: '#337ab7',
                            padding: '12px 24px'
                          }}>
                          {userInfoText}
                        </div>
                      ) : (
                        ''
                      )}
                      <hr />
                      <p style={{ fontSize: '16px', fontWeight: 'bold', mriginBottom: '15px' }}>
                        Copy and Pase Your Seal Code
                      </p>
                      <div
                        className="well well-lg"
                        style={{
                          backgroundColor: '#EAECF0'
                        }}>
                        <div
                          style={{
                            width: '100%',
                            height: '165px',
                            border: 'none',
                            backgroundColor: '#EAECF0'
                          }}>
                          {sealCode.textboxCode}
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '25px' }}>
                        <div>
                          {(isGoogleTagManagerEnabled === true && (
                            <button
                              className="btn-tgdark-black-outline btn-md"
                              onClick={() => this.toggleGoogleTagManager(false)}>
                              <b className="fas fa-undo" />&nbsp; Back
                            </button>
                          )) || (
                            <button
                              className="btn-tgdark-black-outline btn-md"
                              data-toggle="modal"
                              data-target="#sealGoogleTagManagerCnfModal">
                              <b className="fas fa-tag" />&nbsp; Google Tag Manager
                            </button>
                          )}
                        </div>
                        <div>
                          <CopyToClipboard
                            text={sealCode.textboxCode}
                            onCopy={() =>
                              this.setState(
                                {
                                  isCopied: true
                                  // notificationType: "success",
                                  // notificationMessage:
                                  //   "Code copied to clipboard.",
                                },
                                () => {
                                  this.setState({
                                    isCopied: false
                                  });
                                  toast.success('Seal code copied successfully.', {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 2500
                                  });
                                }
                              )
                            }>
                            <button className="btn-tgdark-black-outline btn-md">
                              <b className="fas fa-copy" />&nbsp; Copy to Clipboard
                            </button>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <TrustSealsInfo />

        <TrustSealsCreatePreset createSealPreset={this.createSealPreset} />

        <button
          id="btnDisplayModalSealInfo"
          data-toggle="modal"
          data-target="#modalSealInfo"
          style={{ display: 'none' }}>
          Seal Info
        </button>

        <SealGoogleTagManagerCnfModal toggleGoogleTagManager={this.toggleGoogleTagManager} />
      </Aux>
    );
  }
}

SealComponent.contextType = AppContext;

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      goToDashboard: () => push('/')
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SealComponent);
