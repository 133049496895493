import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  callGetInsurance,
  callApiActivateInsurance,
  callActivateDarkWebScan
} from "../../services";
import AppContext from "../../components/context/appContext";

class DeactivateDarkWebModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      businessId: ""
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    const { currentBusinessId } = this.context;
    if (prevState.businessId !== currentBusinessId) {
      this.setState({
        businessId: currentBusinessId
      });
    }
  }

  activateDarkWeb = async () => {
    const insurance = await callActivateDarkWebScan({
      businessId: this.state.businessId,
      purpose: "enable_darkweb_scan"
    });
    toast.success("Dark Web activated successfully", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2500
    });
    if (insurance && insurance.data && insurance.data.length > 0) {
      // await callApiActivateInsurance(
      //   this.state.businessId,
      //   insurance.data[0]._id
      // );
    } else {
      // toast.info("No Dark Web Activated found for this business.", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 2500,
      // });
    }
  };

  render() {
    return (
      <div id="deactivateDarkWebModal" className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title f800">DarkWeb Scan Activation</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closeModal">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-lg-12 col-md-12 text-left p-0">
                <p>
                  <strong>Are you sure to acitivate DarkWeb scan for this business?</strong>
                </p>
              </div>
            </div>
            <div className="modal-footer text-right">
              <button
                type="button"
                className="btn btn-danger btn-140-width btn-md"
                data-dismiss="modal">
                No
              </button>
              <button
                type="button"
                className="btn-success btn-md pull-right btn-140-width"
                data-dismiss="modal"
                onClick={() => {
                  this.activateDarkWeb();
                }}>
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DeactivateDarkWebModal.contextType = AppContext;

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeactivateDarkWebModal);
